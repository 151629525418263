import { InsertDriveFileOutlined, MusicNoteOutlined, PictureAsPdfTwoTone, VideocamTwoTone } from '@material-ui/icons'
import ErrorLine from 'components/ErrorLine'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useState } from 'react'
import { useEffect } from 'react'
import { isImageMimeType } from 'util/fileUtils'
import { displayError } from 'util/screenUtils'

export default function MediaIcon({ icon, ix, imageClicked, updateIcons }) {
    const { name, dataUrl, mimeType } = icon
    const [imageSrc, setImageSrc] = useState()
    const [error, setError] = useState()
    const getIcon = () => {

        if (mimeType === 'audio/mpeg') {
            return (
                <div key={ix}>
                    <MusicNoteOutlined style={{ fontSize: '3em' }} color='secondary' />
                </div>
            )
        } else if (isImageMimeType(mimeType)) {
            return (
                <img
                    key={ix}
                    src={imageSrc ? imageSrc : dataUrl}
                    alt={name}
                    style={{
                        width: '120px',
                        display: 'flex',
                        margin: 'auto',
                    }}
                />
            )
        } else if (mimeType === 'application/pdf') {
            return (
                <div key={ix}>
                    <PictureAsPdfTwoTone fontSize='large' color='secondary' />
                </div>
            )
        }
        else if (mimeType === 'video/mp4') {
            return (
                <div key={ix}>
                    <VideocamTwoTone fontSize='large' color='secondary' />
                </div>
            )
        }
        else {
            return (
                <div key={ix}>
                    <InsertDriveFileOutlined fontSize='large' color='secondary' />
                </div>
            )
        }
    }

    const displayIcon = () => {
        return (
            <div
                key={ix}
                onClick={() => (imageClicked ? imageClicked(ix) : updateIcons(ix)
                )}
                title={'Click to remove'}
                style={{
                    cursor: 'pointer',
                    border: '1px solid blue',
                    borderRadius: '0.5em',
                    padding: '0.25em',
                    margin: '0.5em',
                    width: '150px',
                    textAlign: 'center',
                }}
            >
                <ErrorLine error={error} />
                {getIcon()}
                <div
                    style={{ fontSize: '0.75em', textAlign: 'center', lineHeight: '1em', wordBreak: 'break-all' }}
                >
                    {name}
                </div>
            </div>
        )
    }

    const getIconMedia = async () => {
        console.log('getIconMedia', icon)

        const { source, mimeType } = icon
        if (source) {
            try {
                switch (mimeType) {
                    case 'image/png':
                    case 'image/jpeg':
                        const data = await downloadCoreFile(source, 'image')
                        setImageSrc(URL.createObjectURL(data))
                    default: break
                }
            } catch (error) {
                console.error(error)
                displayError(`Unable to display image ${source}`, setError)
            }

        }
    }

    useEffect(() => {
        getIconMedia()
    }, [])

    return displayIcon()
}
import { handleErrors } from './AssetController'

export const approveAlbum = async (id, accessToken) => {
  return fetch(`${process.env.REACT_APP_CORE_SERVICE}admin/approve/${id}`, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((updatedAlbum) => {
      return updatedAlbum
    })
}

export const approveMessage = async (messageId, approval, approvalReason, accessToken) => {
  return fetch(`${process.env.REACT_APP_SOCIAL_SERVICE}admin/approveMessage/${messageId}/${approval}/${approvalReason}`, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then(handleErrors)
    .then((response) => {
      return response
    })
}

export const getThoughtCosts = async (accessToken) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}admin/thoughts/cost`
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((costs) => {
      return costs
    })
}


export const listPendingApprovals = async (start, count, accessToken) => {
  const url = `${process.env.REACT_APP_SOCIAL_SERVICE}admin/listPendingApprovals/${start}/${count}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((approvals) => {
      return approvals
    })
}

export const updateSettings = async (settings, accessToken) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}admin/settings`,
    {
      method: 'POST',
      headers: {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    }
  )
  return response
}

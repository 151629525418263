import React from 'react'
import Header from 'components/Header/Header.js'
import Slogan from './Slogan'

export default function HarmonizeHeader() {

  return (
    <div>
      <Header
        color={'primary'}
        fixed
      />

    </div>
  )
}

import { ErrorOutline } from '@material-ui/icons'

export default function ErrorLine({ error, small }) {
  if (error) {
    if (small) {
      return (<p
        style={{
          color: 'red',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorOutline color='error' fontSize='small' />
        {error}
      </p>)
    } else {
      return (
        <h4
          style={{
            color: 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ErrorOutline color='error' fontSize='large' />
          {error}
        </h4>
      )
    }

  } else {
    return null
  }
}

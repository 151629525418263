import React from 'react'
import classNames from 'classnames'
import { makeStyles, useMediaQuery } from '@material-ui/core'

import styles from 'assets/jss/material-kit-react/views/landingPage'

import { isVeryNarrow } from 'util/screenUtils'
import Footer from 'components/Footer/Footer'
import CheckoutResult from './CheckoutResult'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'

export default function PaymentResult() {

    const veryNarrow = isVeryNarrow(useMediaQuery)

    const useStyles = makeStyles(styles)
    const classes = useStyles()
    const displayMain = () => {
        return (
            <div>
                <HarmonizeHeader />
                <div
                    className={classNames(classes.main, classes.mainRaised)}
                    style={
                        veryNarrow
                            ? { marginLeft: '1em', marginRight: '1em' }
                            : { marginLeft: '2em', marginRight: '2em' }
                    }
                >
                    <div
                        className={classes.container}
                        style={{ paddingLeft: '1vw', paddingRight: '1vw' }}
                    >
                        <CheckoutResult />
                    </div>
                </div>
                <Footer />
            </div>
        )
    }


    return displayMain()

}
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'

export const TRANSACTION_TYPE = {
    CREDIT: 0,
    FAILED: 99
}

const TRANSACTION_TYPES = [
    { type: TRANSACTION_TYPE.CREDIT, name: 'Credit', purchaseName: 'Credits', price: 0 },
    { type: TRANSACTION_TYPE.FAILED, name: 'Failed', price: 0 }]


/**
 * 
 * @param {*} id        Signed in user
 * @param {*} room      Room to test
 * @returns 
 */
export const isOwner = (id, room) => {
    const { user } = room
    return id === user
}

/** A room can have comments:
 * - From the owner
 * - By any signed-in user if accessType is not PRIVATE
 * @param {*} room 
 * @param {*} userId 
 * @returns 
 */
export const canComment = (room, userId) => {
    if (room && userId) {
        const { user, accessType } = room
        if (user === userId) {
            return true
        } else {
            switch (accessType) {
                default:
                case ACCESS_TYPES.PRIVATE:
                    return false
                case ACCESS_TYPES.PROTECTED:
                case ACCESS_TYPES.PUBLIC:
                    return true
            }
        }
    }
    return false

}

/**
 * 
 * @param {*} id         Signed in user
 * @param {*} userId     User that created entity
 * @param {*} room       Room 
 * @returns 
 */
export const canDeletePost = (id, userId, room) => {
    const canDelete = id === userId || isOwner(id, room)
    return canDelete
}

export const canEditPost = (id, userId, room) => {
    return id === userId || isOwner(id, room)
}

/**
 * Everyone except the user that created the entity can like it
 * @param {*} id         Signed in user
 * @param {*} userId     User that created entity
 * @param {*} anonymous  If true the access to the containing room is Anonymous and likes cannot be performed
 * */
export const canLikePost = (id, userId, anonymous) => {
    return !anonymous && userId && id !== userId
}

/**
 * Everyone except the user that created the entity can record an engagment.
 * Engagements are also allowed in Public Rooms in which case the userId is ignored
 * @param {*} id         Signed in user
 * @param {*} userId     User that created entity
 * @param {*} anonymous If true an anonymous engagement is allowed
 * @returns 
 */
export const canRecordEngagement = (id, userId, anonymous) => {
    return anonymous || (userId && id !== userId)
}

/** A room can be written:
 * - By the owner
 * - By any signed-in user if accessType is not PRIVATE and exclusive content is not on
 * 
 * @param {*} room 
 * @param {*} userId 
 * @returns 
 */
export const canWriteRoom = (room, userId) => {
    if (room && userId) {
        const { user, accessType, exclusive } = room
        if (user === userId) {
            return true
        } else {
            switch (accessType) {
                default:
                case ACCESS_TYPES.PRIVATE:
                    return false
                case ACCESS_TYPES.PROTECTED:
                case ACCESS_TYPES.PUBLIC:
                    return !exclusive
            }
        }
    }
    return false

}

export const getPurchaseName = (txType) => {
    try {
        return TRANSACTION_TYPES.find(tx => tx.type === txType).purchaseName
    } catch { return 'Undefined' }
}

export const getTransactionName = (txType) => {
    try {
        return TRANSACTION_TYPES.find(tx => tx.type === txType).name
    } catch { return 'Undefined' }
}

import React from 'react'
import { useParams } from 'react-router-dom'
import Footer from 'components/Footer/Footer.js'
import 'firebase/compat/auth'

import SignInComponent from 'components/SignInComponent'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import harmony from '../images/harmony.jpg'
import { useEffect } from 'react'

export default function SignIn(props) {

  const { returnPath } = props
  const { returnTo, requestedRoom } = useParams()
  const displayHero = () => {
    return (
      <div >
        <div style={{
          borderRadius: '2em', padding: '0.5em', color: 'white', fontWeight: 'bold',
          backgroundColor: 'white'
        }}>
          <SignInComponent returnTo={returnTo} returnPath={returnPath} requestedRoom={requestedRoom} />

        </div>
      </div>
    )
  }
  useEffect(() => {
    document.title = 'Sign In'
  }, [])
  return (

    <div >
      <HarmonizeHeader />
      <div
        style={{
          backgroundImage: `url(${harmony})`,
          backgroundPosition: "center",
          backgroundRepeat: 'repeat',
          backgroundSize: "cover",
          opacity: 0.7,
          height: '90vh'

        }}>
        {displayHero()}
      </div>

      <Footer />
    </ div >

  )

}

import React from 'react'
import Footer from 'components/Footer/Footer.js'
import { ListRevenue } from 'components/transaction/ListRevenue'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { ListTransactions } from 'components/transaction/ListTransactions'
import { useEffect } from 'react'
import { useState } from 'react'
import ChartSong from 'components/transaction/ChartSong'

const VAULT_STATES = {
  REVENUE: 0,
  TRANSACTIONS: 1,
  SONG_CHART: 2
}

export default function Vault() {
  const [vaultState, setVaultState] = useState(VAULT_STATES.REVENUE)
  const [chartSongId, setChartSongId] = useState()
  const [chartSongTitle, setChartSongTitle] = useState()

  const displaySongChart = () => {
    return (
      <div>
        <h4 style={{ textAlign: 'center' }}>Vault: {chartSongTitle} Credits</h4>
        <div style={{ cursor: 'pointer', textAlign: 'right', fontSize: '0.75em', fontStyle: 'italic' }}
          onClick={() => setVaultState(VAULT_STATES.REVENUE)}>Click here to show revenue</div>
        <ChartSong messageId={chartSongId} />
      </div>
    )
  }

  const displayRevenue = () => {
    return (
      <div>
        <h4 style={{ textAlign: 'center' }}>Vault: Revenue</h4>
        <div style={{ cursor: 'pointer', textAlign: 'right', fontSize: '0.75em', fontStyle: 'italic' }} onClick={() => setVaultState(VAULT_STATES.TRANSACTIONS)}>Click here to show transactions</div>
        <ListRevenue chartSong={(messageId, title) => {
          setVaultState(VAULT_STATES.SONG_CHART)
          setChartSongId(messageId)
          setChartSongTitle(title)
        }} />
      </div>
    )
  }
  const displayTransactions = () => {

    return (
      <div >
        <h4 style={{ textAlign: 'center' }}>Vault: Transactions</h4>
        <div style={{ cursor: 'pointer', textAlign: 'right', fontSize: '0.75em', fontStyle: 'italic' }} onClick={() => setVaultState(VAULT_STATES.REVENUE)}>Click here to show revenue</div>
        <ListTransactions />
      </div>
    )
  }


  const displayComponent = () => {
    switch (vaultState) {

      case VAULT_STATES.REVENUE:
        return displayRevenue()
      case VAULT_STATES.TRANSACTIONS:
        return displayTransactions()
      case VAULT_STATES.SONG_CHART:
        return displaySongChart()
    }
  }
  useEffect(() => {
    document.title = 'Vault'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ margin: '0.5em', paddingBottom: '2em' }}>
        {displayComponent()}
      </div>
      <Footer />
    </div>
  )
}

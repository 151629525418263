import * as REDUX_TYPES from '../types'

export const addChatMessage = (message) => {
  return {
    type: REDUX_TYPES.CHAT.ADD,
    message,
  }
}

export const setCurrentAvailableThoughts = (currentAvailableThoughts) => {
  return {
    type: REDUX_TYPES.CHAT.SET_CURRENT_AVAILABLE_THOUGHTS,
    currentAvailableThoughts,
  }
}

/**
 *
 * @param {*} currentCollaborator _id of User
 * @returns
 */
export const setCurrentCollaborator = (currentCollaborator) => {
  return {
    type: REDUX_TYPES.CHAT.SET_CURRENT_COLLABORATOR,
    currentCollaborator,
  }
}
export const setChatMessages = (chatMessages) => {
  return {
    type: REDUX_TYPES.CHAT.SET,
    chatMessages,
  }
}

export const setChatRoomId = (roomId) => {
  return {
    type: REDUX_TYPES.CHAT.SET_ROOM_ID,
    roomId,
  }
}

export const setChatSessionId = (chatSessionId) => {
  return {
    type: REDUX_TYPES.CHAT.SET_SESSION_ID,
    chatSessionId,
  }
}

export const setWaitingForHost = (waitingForHost) => {
  return {
    type: REDUX_TYPES.CHAT.SET_WAITING_FOR_HOST,
    waitingForHost,
  }
}


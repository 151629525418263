import React from 'react'
import Footer from 'components/Footer/Footer.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { useEffect } from 'react'
import MessageJsx from 'components/harmonize/MessageJsx'
import { Container } from '@material-ui/core'

export default function Pricing() {

  useEffect(() => {
    document.title = 'Pricing'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>

          <MessageJsx messageId='66fb2d32f81fa131e2b25809' />
        </Container>
      </div>
      <Footer />
    </div>
  )
}

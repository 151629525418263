export const getFirebaseConfig = () => {

  return {
    apiKey: "AIzaSyCbDdvUk8X7VMhz8JCcJxaqDQREfGINrg4",
    authDomain: "signin.harmonize.social",
    projectId: "harmonize-social",
    storageBucket: "harmonize-social.appspot.com",
    messagingSenderId: "522061926626",
    appId: "1:522061926626:web:d9a4dc2b5c2e30e9a4cebb",
    measurementId: "G-B18FCB1M8J",
    vapidId: "BKHLRjieNTBexGllVjX1JsQ0xIMtLokcPU1gnQELEXLbDK-SAzNIhfSJ0CoP_5z-Ibg0c_ZzbamahVpQw1JpWIQ"
  }

}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'
import PropagateLoader from 'react-spinners/PropagateLoader'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import styles from 'assets/jss/material-kit-react/views/profilePage.js'

import Card from 'components/Card/Card'
import { getProfileImage } from 'controllers/AccountController'
import { infoColor } from 'assets/jss/material-kit-react'
import LightTooltip from 'components/LightTooltip'
import UserImage from 'components/image/UserImage'
import { getBrandHostPlatform } from 'util/brandUtils'
import { getDisplayType } from 'redux/actions/displayActions'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'

const useStyles = makeStyles(styles)

export default function Artists(props) {
  const [artists, setArtists] = useState([])
  const [loading, setLoading] = useState()
  const history = useHistory()

  /**
   * The description is scanned for newlines. If we replace each one with <br>
   * we get too much space in the Artists listing, so instead we replace
   * pairs of newlines with <br>.
   * @param {*} artiste
   * @returns
   */
  const displayInfo = (artiste) => {
    const { businessName, description, firstName, lastName } = artiste
    const fixedDescription = description
      ? description.replace(/(\r\n|\n\n|\r)/gm, '<br>')
      : ''
    return (
      <LightTooltip
        title={`Click to view ${firstName} ${lastName}`}
        aria-label={`View ${firstName} ${lastName}`}
        placement='top-start'
      >
        <div>
          <h4
            style={{ margin: '0', cursor: 'pointer' }}

          >
            {businessName}
          </h4>
          <h6
            style={{ marginTop: '0', marginBottom: '0.5em', cursor: 'pointer' }}

          >
            {firstName} {lastName}
          </h6>
          <div>{parse(fixedDescription)}</div>
        </div>
      </LightTooltip>
    )
  }
  const displayImageAndInfo = (artiste) => {
    const { businessName, firstName, lastName, img } = artiste
    return (
      <div className='row' style={{ padding: '0.5em' }}>
        <LightTooltip
          title={`Click to view ${firstName} ${lastName}`}
          aria-label={`View ${firstName} ${lastName}`}
          placement='top-start'
        >
          <div
            style={{
              marginLeft: 5,
              verticalAlign: 'top',
              cursor: 'pointer',
            }}

          >
            <UserImage
              image={img}
              title={businessName}
              style={{ width: 100 }}
            />
          </div>
        </LightTooltip>
        <div
          className='column'
          style={{
            marginLeft: 5,
            verticalAlign: 'top',
          }}
        >
          {displayInfo(artiste)}
        </div>
      </div>
    )
  }

  /** To increase the odds that Google will scrape the entire site, we use an embedded anchor tag with the href pointing
   * to the artist page, rather than onClick listeners, which presumably will not be scraped.
   */
  const displayArtist = (artiste) => {
    const { username, handle } = artiste
    return (
      <div key={handle}>
        <a href={`/@${handle}`}>
          <Card key={username}>{displayImageAndInfo(artiste)}</Card></a>
      </div>
    )
  }

  const getImage = async (item) => {
    return await getProfileImage(item)
  }

  /** We don't want to download the img data with the users endpoint, so we get it after getting
   * the PublicUser info.
   */
  const getImages = async (artists) => {
    return Promise.all(
      artists.map(async (a) => ({ ...a, img: await getImage(a.username) }))
    )
  }
  useEffect(() => {
    const fetchArtists = async () => {
      setLoading(true)
      try {
        const url = `${process.env.REACT_APP_CORE_SERVICE
          }users/artists/${getBrandHostPlatform(getDisplayType())}`
        const request = await fetch(url)
        const albumsResponse = await request.json()

        setArtists(albumsResponse)
        setLoading(false)
        const artists = await getImages(albumsResponse)
        console.log('got artists', artists)
        setArtists(
          artists.filter((a) => {
            const { img: sourceImg, businessName } = a
            const { img, providerImgUrl } = sourceImg
            return !businessName.startsWith('Vortex') && (providerImgUrl || img.data)
          })
        )
      } catch (e) {
        setLoading(false)
      }
    }

    fetchArtists()
  }, [])
  const displayArtists = () => {
    if (loading) {
      return (
        <div
          style={{
            height: '10vh',
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <PropagateLoader color={infoColor} />
        </div>
      )
    }
    return <div>{artists.map((artist) => displayArtist(artist))}</div>
  }
  const classes = useStyles()

  return (
    <div>
      <HarmonizeHeader />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer style={{ justifyContent: 'center' }}>
              <GridItem xs={12} sm={12} md={12}>
                <h3 className={classes.title}>
                  {'Artists'}
                </h3>
                {displayArtists()}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { listMyTransactions } from 'controllers/TransactionController'
import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import moment from 'moment'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getPurchaseName } from 'util/transactionUtils'
import { DataGrid } from '@mui/x-data-grid'
import { isVeryNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'

export const ListTransactions = () => {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [loading, setLoading] = useState()
  const [transactions, setTransactions] = useState()
  const [error, setError] = useState('')
  const accessToken = getStorageAccessToken()
  const dataRows = []

  const columns = [
    { field: 'col1', headerName: 'Date', width: veryNarrow ? 150 : 200 },
    { field: 'col2', headerName: 'Amount', width: 75 },
    { field: 'col3', headerName: 'For', width: 150 }
  ]

  const displayLoadingTransactions = () => {
    return (
      <div style={{ width: '100%' }}>
        <h4>Loading your transactions...</h4>

        <TimerProgress />
      </div>
    )
  }

  const displayTransactions = () => {
    if (transactions) {
      if (transactions.length) {
        transactions.forEach(tx => {
          console.log('vault tx', tx)
          const { _id, amount, createdAt, transactionType, asset, quantity } = tx
          let originalTitle = '-not applicable-'
          if (asset) {
            originalTitle = asset.originalTitle
          }

          const row = {
            id: _id, col1: moment(createdAt).format('MM/DD/YYYY, h:mm a'),
            col2: Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(amount / 100), col3: `${quantity} ${getPurchaseName(transactionType)}`
          }
          dataRows.push(row)
        })
        return (
          <div style={{ height: '40vh', width: '100%' }}>
            Transactions
            <DataGrid rows={dataRows}
              sx={{ fontWeight: '600', color: 'black', fontSize: veryNarrow ? '0.75em' : '1em' }}
              columns={columns} />
          </div>
        )
      } else {
        return (
          <h4>You do not have any transactions</h4>
        )
      }
    } else {
      return null
    }
  }
  useEffect(() => {
    const listTransactions = async () => {
      try {
        setLoading(true)
        const myTransactions = await listMyTransactions(accessToken)
        setTransactions(myTransactions)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error(error)
        setError(error.message)
      }
    }
    listTransactions()
  }, [])

  return (
    <div>
      <div>
        <ErrorLine error={error} />
        {loading && displayLoadingTransactions()}

        {displayTransactions()}

      </div>
    </div>
  )
}

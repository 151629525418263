import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import { BarChart, LineChart } from '@mui/x-charts'
import { useEffect } from 'react'
import { useState } from 'react'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getSongAccruedCredits } from 'controllers/HarmonizeController'
import { displayError } from 'util/screenUtils'
import moment from 'moment'

export default function ChartSong({ messageId }) {
    const accessToken = getStorageAccessToken()
    const [loading, setLoading] = useState()
    const [songCredits, setSongCredits] = useState()
    const [error, setError] = useState()


    const displayLoadingSong = () => {
        return (
            <div style={{ width: '100%' }}>
                <h4>Loading song Credits...</h4>
                <TimerProgress />
            </div>
        )
    }

    const displayRoomCredits = (roomCredits, ix) => {
        console.log('roomCredits', roomCredits)
        const { roomName, accruedCredits } = roomCredits
        const formattedCredits = accruedCredits.map(sc => ({ count: sc.count, createdAt: moment.utc(sc.createdAt).format('M/D/YY') }))
        //console.log('formattedCredits', formattedCredits)
        return (
            <div key={ix}>
                {roomName}
                <LineChart
                    dataset={formattedCredits}
                    xAxis={[{ dataKey: 'createdAt', scaleType: 'band', tickPlacement: 'middle' }]}
                    series={[{ dataKey: 'count' }]}
                    height={300}
                />
            </div>
        )

    }

    const displaySongCredits = () => {
        if (songCredits) {
            return songCredits.map((sc, ix) => displayRoomCredits(sc, ix))
        }
    }
    /**
     * Gets array of:
     * {roomId,roomName,accruedCredits}
     * 
     * where accruedCredits is an array
     */
    const getSongCredits = async () => {
        try {
            setError()
            setLoading(true)
            const songAccruedCredits = await getSongAccruedCredits(messageId, accessToken)
            //const formattedCredits = songAccruedCredits.map(sc => ({ count: sc.count, createdAt: moment(sc.createdAt).format('MMM D') }))
            setSongCredits(songAccruedCredits)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            displayError(error, setError)
        }
    }
    useEffect(() => {
        getSongCredits()
    }, [])

    return (
        <div style={{ overflowY: 'scroll' }}>
            <ErrorLine error={error} />
            {loading && displayLoadingSong()}
            {displaySongCredits()}
        </div>

    )
}
import { useMediaQuery } from '@material-ui/core'
import Card from 'components/Card/Card'
import { displayError } from 'util/screenUtils.js'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getMediaThumbnail } from 'util/imageUtils'
import { isVeryNarrow } from 'util/screenUtils'
import ReactGA from 'react-ga4'
import ErrorLine from 'components/ErrorLine'
export default function SongCard({ song, fromPlaylist, selected }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)

    const styles = {
        imageCard: {
            position: 'relative',
            borderRadius: veryNarrow ? '1em' : '2em',
            color: 'white',
            width: veryNarrow ? '40vw' : '30vw',
            height: veryNarrow ? '40vw' : '30vw'
        },
        hovered: {
            margin: 'auto',
            transform: 'scale3d(1.05, 1.05, 1)',
        },
        notHovered: {
            margin: 'auto',
        },
    }
    const [hovered, setHovered] = useState(false)
    const { name, _id: messageId } = song
    const [imageSrc, setImageSrc] = useState()
    const [error, setError] = useState()
    const history = useHistory()

    const displayImageCard = () => {
        return (
            <div onClick={() => {
                if (selected) { selected() } else {
                    history.push(`/song/${messageId}`)
                    ReactGA.event({ category: 'click', action: 'song_card', label: name })
                }
            }}>
                <Card
                    className='plain'
                    onMouseOver={() => setHovered(true)}
                    onMouseOut={() => setHovered(false)}
                    style={{
                        ...styles.imageCard, ...hovered ? styles.hovered : styles.notHovered, ...{
                            backgroundImage: 'url(' + imageSrc + ')',
                            backgroundSize: 'cover',
                            opacity: 1
                        }
                    }}

                >
                    <ErrorLine error={error} />
                    <span
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            fontSize: veryNarrow ? '0.75em' : '1em',
                            lineHeight: 'normal',
                            fontWeight: hovered ? 'bold' : 'normal',
                            color: hovered ? 'gold' : 'white',
                            backgroundColor: 'grey'
                        }}
                    >
                        {name}

                    </span>
                </Card>
            </div>

        )
    }

    const getMedia = async () => {
        const mediaArray = fromPlaylist ? song.entryMedia : song.media
        const imageMedia = getMediaThumbnail(mediaArray)
        //console.log(`SongCard imageMedia`, imageMedia)
        const { source } = imageMedia
        try {
            const data = await downloadCoreFile(source, 'image')
            const src = URL.createObjectURL(data)
            setImageSrc(src)
        } catch (error) {
            displayError(error, setError)
        }

    }

    useEffect(() => {
        getMedia()
    }, [song])
    return (

        displayImageCard()
    )
}
import { Person } from '@material-ui/icons'

export default function UserImage({ image, title, clickTitle, style, click = () => { } }) {

  /**
   * See the following for why we need referrerPolicy:
   * https://stackoverflow.com/questions/40570117/http403-forbidden-error-when-trying-to-load-img-src-with-google-profile-pic
   * @returns
   */
  const displayImage = () => {

    if (image) {
      const { img, providerImgUrl } = image
      let url

      if (img && img.data) {
        const { data, contentType } = img
        if (data) {
          url = `data:${contentType};base64,${data}`
        }
      } else if (providerImgUrl) {
        url = providerImgUrl
      }
      if (url) {
        return (
          <img
            src={url}
            alt={title}
            style={style}
            title={clickTitle ? clickTitle : title}
            referrerPolicy='no-referrer'
            onClick={click}
          />
        )
      } else {
        return (
          <div style={{ ...style, alignItems: 'center', display: 'flex' }} onClick={click} title={clickTitle ? clickTitle : title}>
            <Person style={{ fontSize: '2em' }} />
          </div>)
      }
    }
    return null
  }
  return displayImage()
}

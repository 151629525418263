import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { isVeryNarrow } from 'util/screenUtils'
import { Grid, useMediaQuery } from '@material-ui/core'

import { getSamplerSongs } from 'controllers/HarmonizeController'
import { displayError } from 'util/screenUtils'
import ErrorLine from 'components/ErrorLine'

import HomeCard from 'components/HomeCard'
import SongCardPlayer from './SongCardPlayer'
import artist from '../../images/artist.jpg'
import listener from '../../images/listener.jpg'
import musicStudio from '../../images/studio.jpg'
import demo from '../../images/demo.jpg'
import uploadSong from '../../images/upload.jpg'
import roundBrain from 'images/round-brain.png'
import { setShowAi } from 'redux/actions/harmonizeActions'
import { setShowAiSignIn } from 'redux/actions/helpActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import SongCard from './SongCard'
import { createRef } from 'react'
import { useHistory } from 'react-router-dom'
import { getStorageAccessToken } from 'redux/actions/accountActions'

export default function HomeGrid({ setShowSignIn }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const gridStyles = {
        item: {
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            fontSize: veryNarrow ? '0.75em' : '1em', lineHeight: 'normal'
        },
    }
    const account = getStorageAccount()
    const accessToken = getStorageAccessToken()
    const [error, setError] = useState()
    const [songs, setSongs] = useState()
    const dispatch = useDispatch()
    const history = useHistory()
    const players = []

    const aiTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard title='AI Infused' image={roundBrain}
                    action={() => { account ? dispatch(setShowAi(true)) : dispatch(setShowAiSignIn(true)) }}
                    messageId='64a43b3e238fbb1271279a5f'
                />
            </Grid>
        )
    }

    const artistTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard title='Better Payments' image={artist} link='/About'
                    messageId='66f8544ef81fa131e2b24f3a'

                />
            </Grid>
        )
    }

    const demoTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/@cj/public' image={demo} title='Try Harmonize Free!' messageId='66f97094f81fa131e2b25311' />

            </Grid>
        )
    }
    const listenerTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/Pricing' image={listener} title='Lower Costs' messageId='66f96c5ff81fa131e2b251b6' />
            </Grid>
        )
    }

    const spotifyTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/Pricing' title='Compare To Spotify' messageId='66ffec25f81fa131e2b25ea0' />
            </Grid>
        )
    }
    const studioTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/help/AboutStudios' image={musicStudio} title='Better Social' messageId='66f96922f81fa131e2b25161' />
            </Grid>
        )
    }

    const uploadSongTile = (ix) => {
        return (
            <Grid key={ix} item xs style={gridStyles.item}>
                <HomeCard link='/help/AboutStudios' image={uploadSong} title='Upload Your Song'
                    description={`Upload your own song and take advantage of everything Harmonize offers you. It's free to join and upload! Just
                    be sure you own all the rights to the song.`}
                    action={() => accessToken ? history.push(`/@${account.handle}/private/true`) : setShowSignIn(true)} />
            </Grid>
        )
    }

    const rearrange = () => {
        if (songs) {
            const rearrangedTiles = []

            rearrangedTiles.push(artistTile(10))
            rearrangedTiles.push(spotifyTile(50))
            rearrangedTiles.push(uploadSongTile(100))
            rearrangedTiles.push(studioTile(11))
            rearrangedTiles.push(listenerTile(12))
            rearrangedTiles.push(demoTile(13))
            rearrangedTiles.push(aiTile(14))

            let updatedSongs = getPublicSongCardPlayer(songs, 'Righteous Society', rearrangedTiles, 0)
            updatedSongs = getPublicSongCardPlayer(updatedSongs, 'Hang Your Feet out the Window', rearrangedTiles, 1)

            return (
                <Grid container spacing={veryNarrow ? 2 : 3} style={{ width: '100%', paddingTop: '1em', margin: 0 }}  >
                    {rearrangedTiles.map(m => m)}
                    {/*updatedSongs.map((song, ix) => <Grid key={ix} item xs style={{ display: 'flex', justifyContent: 'center' }}><SongCard song={song} /></Grid>)*/}
                </Grid>
            )
        }
    }

    const stopOtherPlayers = (playerRef) => {
        console.log('stopOtherPlayers', players)
        players.forEach(pl => {
            if (pl && pl !== playerRef && playerRef.current && playerRef.current.audio) {
                pl.current.audio.current.pause()
            }
        })
    }

    /**
     * Get a song by its title from the list of sampler songs, and if that song is
     * in the public Studio, add it to rearrangedTiles.
     * @param {*} allSongs 
     * @param {*} title 
     * @param {*} rearrangedTiles 
     * @param {*} ix 
     * @returns 
     */
    const getPublicSongCardPlayer = (allSongs, title, rearrangedTiles, ix) => {
        const songIx = allSongs.findIndex(s => s.name === title)
        if (songIx !== -1 && allSongs[songIx].roomName === 'public') {
            players.push(createRef())
            const song = allSongs[songIx]
            rearrangedTiles.push(<Grid key={ix} item xs style={{ display: 'flex', justifyContent: 'center' }}>
                <SongCardPlayer song={song} playerRef={players[ix]} onPlay={stopOtherPlayers} />
            </Grid>)
            return allSongs.toSpliced(songIx, 1)
        } else {
            return allSongs
        }
    }

    const displayMain = () => {

        return (
            <div style={{ width: '100%' }}>
                <ErrorLine error={error} />
                {rearrange()}
            </div>
        )

    }

    const randomizeAndSetSongs = (songs) => {
        // eslint-disable-next-line
        const randomSongs = songs.reduce(([a, b]) => (b.push(...a.splice(Math.random() * a.length | 0, 1)), [a, b]), [[...songs], []])[1]
        setSongs(randomSongs)
    }

    useEffect(() => {
        getSamplerSongs(0, 15).then(songs => randomizeAndSetSongs(songs)).catch(error => displayError(error, setError))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return displayMain()
}
import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Footer from 'components/Footer/Footer.js'
import styles from 'styles/pricingPage'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { getStorageAccount } from 'redux/actions/accountActions'
import { Container } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'

const useStyles = makeStyles(styles)

export default function FAQ() {
  const account = getStorageAccount()
  const classes = useStyles()
  const narrow = useMediaQuery('(max-width:400px)')
  const containerClasses = narrow
    ? classNames(classes.main)
    : classNames(classes.main, classes.mainRaised)
  useEffect(() => {
    document.title = 'FAQ'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>
          <h4 style={{ color: 'black', textAlign: 'center', borderBottom: '1px solid black' }}>FAQ</h4>
          <h4>How is this different from Spotify and Apple Music</h4>
          Harmonize is artist-centric whereas those sites are more
          like libraries of music. Think about Facebook and Instagram but with a music
          player built-in and that is closer to what Harmonize is.
          But Harmonize also has a completely different business model than any of those platforms,
          one in which the focus is getting revenue directly from listeners to artists.
          <h4>How do I earn money</h4>
          Artist earn money at the rate of ${process.env.REACT_APP_ARTIST_INTERVAL_RATE}&nbsp;
          for every {process.env.REACT_APP_ACCRUE_CREDIT_TIME} seconds one of your songs is played.*
          This is about ${process.env.REACT_APP_ARTIST_RATE_4_MINUTE_SONG} for a four minute song.
          You can view your current earned credits at any time on your&nbsp;
          <Link to='/Vault'>Vault page</Link>.
          <h4>How do I get paid</h4>
          To get paid you must have a valid PayPal account with the same a email address you used to
          register with Harmonize.
          On the last business day of the month all artists with earnings greater than ${process.env.REACT_APP_MIN_PAYOUT} receive a payment at
          their PayPal account. Fractional credits that are less than one cent or total credits that are less
          than ${process.env.REACT_APP_MIN_PAYOUT} roll over to the next month.
          <h4>Why is Harmonize better for listeners</h4>
          At Harmonize you only pay for what you actually listen to. There are no ads or subscription fees.
          Harmonize also includes all the kinds of social media interactions you've come to know and love including
          likes, comments and shares. Harmonize goes beyond any current music or social media
          platform by integrating live chat with the playing experience.
          <h4>What is the streaming quality</h4>
          Harmonize audio is streamed at 320kbps with a frequence of 48khz and a loudness of -14lufs.
          <h4>What is the Harmonize handle</h4>
          Your handle uniquely identifies you in Harmonize.
          Anyone anywhere on the Internet can get to your studio by referencing Harmonize.social/@[your handle].
          {account ?
            <p>
              This is what that looks like for your current account:
              <br />
              <a href={`https://harmonize.social/@${account.handle}`} target='_blank' referrerPolicy='no-referrer'>
                https://harmonize.social/@{account.handle}</a>
            </p>
            : null}
          <p>
            Your original handle is based on the email address you used to join Harmonize.
            You can customize it by going to the <Link to='/MyProfile'>My Profile page</Link>.
          </p>
          <h4>How do I post a song to Harmonize</h4>
          <p>To post a song click this icon wherever you see it (except here :) :</p>
          <AddCircle
            color='primary'
            style={{
              cursor: 'pointer',
              backgroundColor: 'rgba(255,255,255,0.5)',
              borderRadius: '4em',
              fontSize: '4em',
            }} />

          <p>You will be prompted to sign in or create account if you have not already done so.
            A private studio will be created for your songs. Anything you post in the private studio
            is only visible to you
            until your song is published and approved.
          </p>
          <p>Then upload a WAV or MP3 file to get started. The submission process is simple and very much
            like a social media post.</p>
          <p>
            Once you have published it it goes through a short approval process to
            make a quick analysis of copyright or content issues. Once approved it is immediately
            live in your home studio.
            Songs in your home studio are playable by any Harmonize listener.</p>
          <hr />
          <p>* - A partial listening interval is credited to the artist whose song is playing when
            the interval is recorded.
          </p>
        </Container>
      </div>
      <Footer />
    </div >
  )
}

import React from 'react'

import { useParams } from 'react-router-dom'
import Footer from 'components/Footer/Footer.js'
import ContactForm from 'components/ContactForm'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import harmony from '../images/harmony.jpg'
import { useEffect } from 'react'

export default function Contact() {
  const { initialMessage } = useParams()

  const displayHero = () => {
    return (
      <div style={{ paddingTop: '1em' }}>
        <div style={{
          marginLeft: '2em', marginRight: '2em', borderRadius: '2em', padding: '0.5em', color: 'white', fontWeight: 'bold',
          backgroundColor: 'rgba(61, 64, 212,0.8)'
        }}>

          <ContactForm
            initialMessage={initialMessage}
          />

        </div>
      </div>
    )
  }
  useEffect(() => {
    document.title = 'Contact'
  }, [])
  return (

    <div >
      <HarmonizeHeader />
      <div
        style={{
          backgroundImage: `url(${harmony})`,
          backgroundPosition: "center",
          backgroundRepeat: 'repeat',
          backgroundSize: "cover",
          opacity: 0.7,
          height: '90vh',

        }}>
        {displayHero()}
      </div>

      <Footer />
    </ div>

  )
}

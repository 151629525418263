import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  currentArtist: undefined, //If defined this is the PublicUser for the current site subdomain
}

const artistReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.ARTIST.CURRENT_ARTIST:
      return {
        ...state,
        currentArtist: action.currentArtist,
      }

    default:
      return state
  }
}
export default artistReducer

import * as REDUX_TYPES from '../types'

export const addComment = (comment) => {
    return {
        type: REDUX_TYPES.MESSAGES.ADD_COMMENT,
        comment,
    }
}

export const addMessage = (message) => {
    return {
        type: REDUX_TYPES.MESSAGES.ADD,
        message,
    }
}

export const deleteComment = (comment) => {
    return {
        type: REDUX_TYPES.MESSAGES.DELETE_COMMENT,
        comment,
    }
}
export const deleteMessage = (message) => {
    return {
        type: REDUX_TYPES.MESSAGES.DELETE,
        message,
    }
}
export const setMessages = (messages) => {
    return {
        type: REDUX_TYPES.MESSAGES.SET,
        messages,
    }
}

export const setCurrentChannel = (currentChannel) => {
    return {
        type: REDUX_TYPES.MESSAGES.SET_CHANNEL,
        currentChannel,
    }
}


export const setCurrentRoom = (currentRoom) => {
    return {
        type: REDUX_TYPES.MESSAGES.SET_ROOM,
        currentRoom,
    }
}

export const setCurrentSocket = (currentSocket) => {
    return {
        type: REDUX_TYPES.MESSAGES.SET_SOCKET,
        currentSocket,
    }
}

export const updateMessage = (message) => {
    return {
        type: REDUX_TYPES.MESSAGES.UPDATE,
        message,
    }
}
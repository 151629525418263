import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classNames from 'classnames'

import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'

import Menu from '@material-ui/icons/Menu'

import styles from 'assets/jss/material-kit-react/components/headerStyle.js'
import { useHistory } from 'react-router-dom'
import HeaderLinks from './HeaderLinks'
import { displayHomeIcon } from 'util/brandUtils'
import { getStorageAccount } from 'redux/actions/accountActions'
import { Home } from '@material-ui/icons'
import { useEffect } from 'react'
import roundBrain from 'images/round-brain.png'
import { aiChatStyles } from 'vortex/AIChat'
import { setShowAi } from 'redux/actions/harmonizeActions'
import { setShowAiSignIn } from 'redux/actions/helpActions'

const useStyles = makeStyles(styles)

export default function Header(props) {
  const { showHeader } = useSelector((state) => state.hostType)
  const { showAi } = useSelector(state => state.harmonize)
  const account = getStorageAccount()
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const { color, fixed, stickToTop, absolute } = props
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.stickToTop]: stickToTop
  })

  const displayHomeAndAi = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          title=
          'Click to use AI'
          style={{ ...aiChatStyles.button, ...{ paddingRight: 0 } }}
          onClick={(evt) => {
            evt.stopPropagation()
            if (account) {
              dispatch(setShowAi(!showAi))
            } else {
              dispatch(setShowAiSignIn(true))
            }
          }}
        >
          <img
            src={roundBrain}
            alt=' '
            style={{
              height: '1.25em',
              paddingRight: '0.2em'
            }}
          />

        </div>
        {account ? <Home style={{ fontSize: '1.5em', cursor: 'pointer' }} onClick={() => history.push(`/@${account.handle}`)
        } /> : null}
      </div>
    )
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props
    const windowsScrollTop = window.pageYOffset
    if (showHeader) {
      const hdr = document.body.getElementsByTagName('header')[0]
      if (hdr) {
        if (windowsScrollTop > changeColorOnScroll.height) {
          hdr.classList.remove(classes[color])
          hdr.classList.add(classes[changeColorOnScroll.color])
        } else {
          hdr.classList.add(classes[color])
          hdr.classList.remove(classes[changeColorOnScroll.color])
        }
      }
    }
  }

  useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange)
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange)
      }
    }
  })
  if (showHeader) {
    return (
      <AppBar className={appBarClasses} >
        <Toolbar className={classes.container} >

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {displayHomeIcon(history)}
            <span style={{ textAlign: 'center', paddingLeft: '0.25em', fontSize: '0.75em', fontVariant: 'small-caps', top: '3px', position: 'relative' }}>All musicians must</span>
            <h4 style={{ paddingLeft: '0.25em', fontWeight: 'bolder' }}>Harmonize</h4>&nbsp;

          </div>
          {!showAi ?
            <div>
              <Hidden smDown implementation='css' >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {displayHomeAndAi()}<HeaderLinks />
                </div>
              </Hidden>
              <Hidden mdUp>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {displayHomeAndAi()}

                  <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={handleDrawerToggle}
                  >
                    <Menu />
                  </IconButton>
                </div>
              </Hidden>
            </div>
            : null}
        </Toolbar>

        <Hidden mdUp implementation='js'>
          <Drawer
            variant='temporary'
            anchor={'right'}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}

            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive} >

              <HeaderLinks />
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    )
  } else {
    return null
  }
}

Header.defaultProp = {
  color: 'white',
}

Header.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
  ]),

  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'transparent',
      'white',
      'rose',
      'dark',
    ]).isRequired,
  }),
}

import React from 'react'
import { TextField } from '@material-ui/core'

/**
 * This is a numeric input field without any "save" action and with range checking.
 * If an updater is provided, it is called when the item loses focus.
 * @param {*} props
 * @returns
 */
export default function NumericInputField(props) {
  const {
    fullWidth = true,
    min,
    max,
    label,
    getter,
    setter,
    helper,
    updater,
    step = 0.01,
    integerOnly,
    ...rest
  } = props

  const actualMin = integerOnly ? parseInt(min) : parseFloat(min)
  const actualMax = integerOnly ? parseInt(max) : parseFloat(max)
  const checkRange = (value) => {
    //console.log(`checkRange ${value} min ${min} max ${max}`)
    if (min !== undefined || max) {
      if (min !== undefined && (!value || value < actualMin)) {
        //console.log(`too small ${value} min ${min}`)
        setter(actualMin)
      } else if (value > actualMax) {
        //console.log(`too large ${value} max ${max}`)
        setter(actualMax)
      } else {
        const updatedValue = integerOnly ? parseInt(value) : parseFloat(value)
        setter(updatedValue)
      }
    }
  }
  return (
    <TextField
      {...rest}
      value={getter()}
      inputProps={integerOnly ? {
        inputMode: 'numeric', pattern: '[0-9]*'
        , step: 1
      } : { step: step }}
      type={'number'}
      onBlur={() => {
        if (updater) {
          updater()
        }
      }}
      onChange={(event) => checkRange(event.target.value)}
      fullWidth={fullWidth}
      helperText={helper}
      label={label}
    />
  )
}

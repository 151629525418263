import * as REDUX_TYPES from '../types'

export const INITIAL_STATE = {
  currentAlbum: undefined, //Album containing currentAsset
  currentAsset: undefined, //If defined this is the Asset object most recently selected by the current user
  currentAssets: undefined, //List of assets, usually (not necessarily) contained with currentAlbum
  currentIssue: 0, //Current share issue for the token, if any
}

const assetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.ASSET.CURRENT_ALBUM:
      return {
        ...state,
        currentAlbum: action.currentAlbum,
      }
    case REDUX_TYPES.ASSET.CURRENT_ASSET:
      return {
        ...state,
        currentAsset: action.currentAsset,
      }
    case REDUX_TYPES.ASSET.CURRENT_ASSETS:
      return {
        ...state,
        currentAssets: action.currentAssets,
      }
    case REDUX_TYPES.ASSET.CURRENT_ISSUE:
      return {
        ...state,
        currentIssue: action.currentIssue,
      }
    case REDUX_TYPES.ASSET.SET:
      return { ...action.asset }
    default:
      return state
  }
}
export default assetReducer

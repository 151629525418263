//Generated at https://www.devglan.com/online-tools/rsa-key-generator
import publicKeyFile from '../assets/secure/public.pem'
// FOR TESTING ONLY
//import privateKeyFile from '../assets/secure/private.key'


/**
 * 
 * @param {*} mediaSource 
 * @param {*} sourceName 
 * @param {*} pub 
 * @param {*} legacy 
 * @returns 
 */
export const encryptAudioUrl = async (mediaSource, sourceName, pub, legacy) => {
    const urlParameters = !legacy ? `${encodeURIComponent(mediaSource)}/${sourceName}/${process.env.REACT_APP_API_KEY}`
        : `${mediaSource}/${process.env.REACT_APP_API_KEY}`
    const encryptedParams = await encryptString(urlParameters, pub)
    return !legacy ? `${process.env.REACT_APP_CORE_SERVICE}media/audio/${encryptedParams}`
        : `${process.env.REACT_APP_CORE_SERVICE}media/audio/legacy/${encryptedParams}`
}

/*
Convert an ArrayBuffer into a string
from https://developer.chrome.com/blog/how-to-convert-arraybuffer-to-and-from-string/
*/
const ab2str = (buf) => {
    return String.fromCharCode.apply(null, new Uint8Array(buf))
}

/**
 * @see https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
 * @param {*} str 
 * @returns 
 */
const str2ab = (str) => {
    const buf = new ArrayBuffer(str.length)
    const bufView = new Uint8Array(buf)
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i)
    }
    return buf
}

/**
 * @see https://stackoverflow.com/questions/11449577/why-is-base64-encode-adding-a-slash-in-the-result
 * @param {*} input 
 * @returns 
 */
function base64url_encode(input) {
    return btoa(input.replaceAll('/', '_'))
}

/** We need forward slash to delimit original URL parameters
 * @see https://stackoverflow.com/questions/11449577/why-is-base64-encode-adding-a-slash-in-the-result
 * @param {*} buffer 
 * @returns 
 */
function encodeArrayBufferToBase64(buffer) {
    const view = new Uint8Array(buffer)
    const chars = Array.from(view).map(b => String.fromCharCode(b))
    return btoa(chars.join('')).replaceAll('/', '_')
}

function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes.buffer
}

const getMessageEncoding = (value) => {
    let enc = new TextEncoder()
    return enc.encode(value)
}

export const encryptString = async (value, pub) => {
    const publicKey = await importRsaKey(pub)
    let encoded = getMessageEncoding(value)
    const encrypted = await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP",
        },
        publicKey,
        encoded,
    )
    return encodeArrayBufferToBase64(encrypted)
}


/**
 * Imports an RSA public encryption key from a PEM-encoded SubjectPublicKeyInfo object.
 * @param {*} pem 
 * @returns 
 */
const importRsaKey = async () => {
    // fetch the part of the PEM string between header and footer
    const pemHeader = "-----BEGIN PUBLIC KEY-----"
    const pemFooter = "-----END PUBLIC KEY-----"
    const pemText = await (await fetch(publicKeyFile)).text()

    const pemContents = pemText.substring(
        pemHeader.length,
        pemText.length - pemFooter.length - 1,
    )
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pemContents)
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString)
    const rsaKey = window.crypto.subtle.importKey(
        "spki",
        binaryDer,
        {
            name: "RSA-OAEP",
            hash: "SHA-256",
        },
        true,
        ["encrypt"],
    )
    return rsaKey
}

/**
 *  Export the given key. Format is pkcs8 for private, spki for public
 * @param {*} key 
 * @param {*} isPrivate 
 * @returns 
 */
async function exportCryptoKey(key, isPrivate) {
    const exported = await window.crypto.subtle.exportKey(isPrivate ? "pkcs8" : 'spki', key)
    const exportedAsString = ab2str(exported)
    const exportedAsBase64 = window.btoa(exportedAsString)
    return isPrivate ? `-----BEGIN PRIVATE KEY-----\n${exportedAsBase64}\n-----END PRIVATE KEY-----`
        : `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`
}

/**
 * Gets only the public key and is safe for production
 * @returns 
 */
export const createRsaPublicKey = async () => {
    const publicKey = await importRsaKey()
    const pub = await exportCryptoKey(publicKey)

    return { pub }

}

/* TESTING ONLY


export const decryptString = async (value, pvt) => {
    let privateKey = await importPrivateKey(pvt)
    const ciphertext = base64ToArrayBuffer(value)//str2ab(window.btoa(value))
    const decrypted = await window.crypto.subtle.decrypt(
        {
            name: "RSA-OAEP"
        },
        privateKey,
        ciphertext
    )

    let dec = new TextDecoder()
    const decoded = dec.decode(decrypted)
    return decoded
}

Import a PEM encoded RSA private key, to use for RSA-PSS signing.
Takes a string containing the PEM encoded key, and returns a Promise
that will resolve to a CryptoKey representing the private key.

const importPrivateKey = async () => {
    // fetch the part of the PEM string between header and footer
    const pemHeader = "-----BEGIN RSA PRIVATE KEY-----"
    const pemFooter = "-----END RSA PRIVATE KEY-----"
    const pemText = await (await fetch(privateKeyFile)).text()
    //console.log('importPrivateKey', pemText)
    const pemContents = pemText.substring(
        pemHeader.length,
        pemText.length - pemFooter.length - 1,
    )
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pemContents)
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString)

    return window.crypto.subtle.importKey(
        "pkcs8",
        binaryDer,
        {
            name: "RSA-OAEP",
            hash: "SHA-256",
        },
        true,
        ["decrypt"],
    )
}

export const createRsaKeyPair = async () => {
    //
    const keyPair = await window.crypto.subtle.generateKey(
        {
            name: "RSA-OAEP",
            // Consider using a 4096-bit key for systems that require long-term security
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"]
    )
    //
    const privateKey = await importPrivateKey()
    const publicKey = await importRsaKey()
    const pvt = await exportCryptoKey(privateKey, true)
    const pub = await exportCryptoKey(publicKey)
    return { pvt, pub }
}

*/
import * as REDUX_TYPES from '../types'
export const PLAYER_MODES = { POST: 0, COMPACT: 1, GRID: 2 }

const INITIAL_STATE = {
  accruedCredits: [],
  accruedCreditsRoom: undefined, //Track the room in which credits accrue so that, if the room changes, the pending credits are written to the original
  continuousPlay: true,
  collections: [],
  creditPlayTime: 1,
  currentCollection: undefined,
  engaged: false,
  fullscreen: false,
  hideOverlay: false,
  lastDuration: 0,
  lastPlayListIndex: undefined,
  playList: [],
  playListIndex: undefined,
  playerMode: PLAYER_MODES.COMPACT,
  playTime: 0,
  reactorPlay: false,
  showAi: false,
  showAttachments: false,
  showChat: false,
  showCollections: false
}

const harmonizeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS:
      return {
        ...state,
        accruedCredits: action.accruedCredits,
      }
    case REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS_ROOM:
      return {
        ...state,
        accruedCreditsRoom: action.accruedCreditsRoom,
      }
    case REDUX_TYPES.HARMONIZE.SET_CONTINUOUS_PLAY:
      return {
        ...state,
        continuousPlay: action.continuousPlay,
      }
    case REDUX_TYPES.HARMONIZE.SET_COLLECTIONS:
      return {
        ...state,
        collections: action.collections,
      }
    case REDUX_TYPES.HARMONIZE.SET_CREDIT_PLAY_TIME:
      return {
        ...state,
        creditPlayTime: action.creditPlayTime,
      }
    case REDUX_TYPES.HARMONIZE.SET_CURRENT_COLLECTION:
      return {
        ...state,
        currentCollection: action.currentCollection,
      }
    case REDUX_TYPES.HARMONIZE.SET_ENGAGED:
      return {
        ...state,
        engaged: action.engaged,
      }
    case REDUX_TYPES.HARMONIZE.SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.fullscreen,
      }
    case REDUX_TYPES.HARMONIZE.SET_HIDE_OVERLAY:
      return {
        ...state,
        hideOverlay: action.hideOverlay,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAYLIST:
      return {
        ...state,
        playList: action.playList,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAYLIST_INDEX:
      return {
        ...state,
        playListIndex: action.playListIndex,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAYER_MODE:
      return {
        ...state,
        playerMode: action.playerMode,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAY_TIME:
      return {
        ...state,
        playTime: action.playTime,
      }

    case REDUX_TYPES.HARMONIZE.SET_REACTOR_PLAY:
      return {
        ...state,
        reactorPlay: action.reactorPlay,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_AI:
      return {
        ...state,
        showAi: action.showAi,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_ATTACHMENTS:
      return {
        ...state,
        showAttachments: action.showAttachments,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_CHAT:
      return {
        ...state,
        showChat: action.showChat,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_COLLECTIONS:
      return {
        ...state,
        showCollections: action.showCollections,
      }
    default:
      return state
  }
}
export default harmonizeReducer

import ErrorLine from 'components/ErrorLine'
import { getAvailableCredits } from 'controllers/AccountController'
import React from 'react'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getStorageAccount } from 'redux/actions/accountActions'
import { setAccount } from 'redux/actions/accountActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { displayError } from 'util/screenUtils'
import { TRANSACTION_TYPE } from 'util/transactionUtils'

export default function CheckoutResult() {
    const accessToken = getStorageAccessToken()
    const account = getStorageAccount()
    const { currentRoom } = useSelector(state => state.messages)
    const { transactionType } = useSelector(state => state.purchase)
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()
    const paymentSucceeded = async () => {
        console.log(`payment succeeded for tx type ${transactionType}`)
        setMessage("Payment succeeded!")
        try {
            const availableCredits = await getAvailableCredits(accessToken)
            dispatch(setAccount({ ...account, availableCredits }))
            switch (transactionType) {
                case TRANSACTION_TYPE.CREDIT:
                    if (currentRoom) {
                        const { name, roomOwner } = currentRoom
                        const { handle } = roomOwner
                        history.replace(`/@${handle}/${name}`)
                    } else {
                        history.replace(`/`)
                    }
                    break

                default:
                    setError(`Invalid transaction type ${transactionType}`)
                    break
            }
        } catch (error) {
            displayError(error, setError)
        }
    }

    const checkStatus = async () => {
        const status = new URLSearchParams(window.location.search).get(
            "redirect_status"
        )
        if (status) {
            switch (status) {
                case "succeeded":
                    paymentSucceeded()
                    break
                case "processing":
                    setMessage("Your payment is processing.")
                    break
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.")
                    break
                default:
                    setMessage("Something went wrong.")
                    break
            }
        } else {
            setMessage('Unknown processing error')
        }
        //The stripe instance is null so fouck it for now
        /*
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        )

        if (!clientSecret) {
            setMessage('Invalid payment result URL')
        } else {

            const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)
            console.log(`checkStatus received`, paymentIntent)
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!")
                    break
                case "processing":
                    setMessage("Your payment is processing.")
                    break
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.")
                    break
                default:
                    setMessage("Something went wrong.")
                    break
            }
        }
        */
    }
    useEffect(() => {
        checkStatus()
    }, [])
    return (
        <div>
            <ErrorLine error={error} />
            <div>
                {message}
            </div>
        </div>
    )
}

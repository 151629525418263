
import { handleErrors } from './AssetController'


export const listMyRevenue = async (accessToken) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}revenue/list/0/50`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        }
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((revenue) => {
            return revenue
        })
}

export const listMyTransactions = async (accessToken) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}transactions/list/0/50`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        }
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((transactions) => {
            return transactions
        })
}
import React, { useState, useEffect } from "react"
import '../assets/css/Post.css'
import Moment from 'react-moment'
import ModalDialog from 'components/ModalDialog'

import {
    AddCircle, BrokenImage, DeleteForeverTwoTone, Description, Edit, Favorite,
    LibraryAdd, OndemandVideoOutlined, QueueMusicTwoTone, Share
} from '@material-ui/icons'
import ErrorLine from 'components/ErrorLine'
import { extractColors } from 'extract-colors'
import { downloadCoreFile } from 'controllers/BackblazeController'
import parse from 'html-react-parser'
import { canDeletePost } from 'util/transactionUtils'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getEmbedIcon } from 'util/embedUtils'
import 'react-h5-audio-player/lib/styles.css'
import Button from '@material-ui/core/Button'
import { Snackbar, TextField, useMediaQuery } from '@material-ui/core'
import { addEmbed } from 'controllers/VortexController'
import { createValidEmbed } from 'util/embedUtils'
import { canEditPost } from 'util/transactionUtils'
import { getAttachmentLabel } from 'util/documentUtils'
import { isVeryNarrow } from 'util/screenUtils'
import { addComment, deleteComment } from 'util/socialServiceUtils'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { useDispatch, useSelector } from 'react-redux'
import { PLAYER_MODES } from 'redux/reducers/harmonizeReducer'
import { isGooglebot } from 'util/screenUtils'
import moment from 'moment'
import { setShowAttachments } from 'redux/actions/harmonizeActions'
import { isPortrait } from 'util/screenUtils'
import { getListens } from 'util/screenUtils'
import Collections from 'components/harmonize/Collections'
import BlurDialog from 'components/utility/BlurDialog'
import ShareContent from 'components/harmonize/ShareContent'
import { canComment } from 'util/transactionUtils'
import { setHideOverlay } from 'redux/actions/harmonizeActions'
import { getMediaDisplay } from 'util/imageUtils'
import { getMediaThumbnail } from 'util/imageUtils'
import { setCurrentRoom } from 'redux/actions/messagesActions'
import { replaceNewLine } from 'util/postUtils'


const displayStyles = {
    attachmentIcon: {
        height: '100%',
        verticalAlign: 'middle',
        fontSize: '2em',
        cursor: 'pointer'
    },
    normalImage: {
        width: '100%',
        pointerEvents: 'none'
    },
    fullScreenImage: {
        height: '100vh',
        display: 'flex',
        margin: 'auto',
        pointerEvents: 'none'
    },
    fullScreenImagePortrait: {

        width: '100%'
    }
}
/**
 * 
 * @param {*} content       The Message that will be displayed
 * @param {*} showPost      If provided this is a callback function the presence of which displays a Visibility eye so this post can
 *                          be displayed alone in a Modal
 * @param {*} messageSocket Must be provided for Message actions e.g. addComment
 * @returns 
 */
export default function DisplayHarmonizePost({ content, showPost, editPost, fromAi, fromAdmin, fromSupport,
    messageSocket, room, displayTitle, selected = () => { } }) {
    //console.log('DisplayHarmonizePost', content)
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const portrait = isPortrait(useMediaQuery)
    const imageWidth = veryNarrow ? '75%' : '50%'
    const maxSummary = veryNarrow ? 110 : 300
    const [error, setError] = useState()
    const { fullscreen, hideOverlay, playerMode, playList, playListIndex, showAttachments } = useSelector(state => state.harmonize)
    const [confirmDeleteComment, setConfirmDeleteComment] = useState(false)
    const [commentId, setCommentId] = useState()
    const { category, createdAt, publishedAt, updatedAt, message, summary, name, userId, username, imageUrl, _id: messageId, comments, numLikes,
        numListens,
        media, embeds, status, attachments, firstName = 'CJ', lastName = 'Villa' } = content
    const [embedContent, setEmbedContent] = useState('')
    const [embedIndex, setEmbedIndex] = useState(0)
    const [showAddEmbed, setShowAddEmbed] = useState(false)
    const [showCollections, setShowCollections] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const [copiedUrl, setCopiedUrl] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [comment, setComment] = useState("")
    const [dominantColor, setDominantColor] = useState()
    const { accessToken, account } = useSelector(state => state.account)
    const { id, handle } = account ? account : { id: 0, handle: 'anonymous' }

    const dispatch = useDispatch()

    const displayError = (error) => {
        const { message } = error
        if (message) {
            setError(message)
        } else {
            setError(JSON.stringify(error))
        }
    }

    /**
     * There is no PLAYER_MODE for fullscreen so that we return to the 
     * previously-selected mode when exiting fullscreen.
     * @returns 
     */
    const isFullListing = () => {
        return fullscreen || playerMode === PLAYER_MODES.POST
    }

    const displayComment = (c) => {
        if (!(fromAdmin || fromSupport)) {
            const { _id, handle, userId, comment, createdAt } = c
            return (
                <p key={createdAt}>{canDeletePost(id, userId, room) ? <DeleteForeverTwoTone
                    color='error'
                    fontSize='small'
                    style={{ cursor: 'pointer' }}
                    onClick={(evt) => {
                        evt.stopPropagation()
                        setCommentId(_id)
                        setConfirmDeleteComment(!confirmDeleteComment)
                    }}
                /> : null}

                    (<Moment fromNow>{createdAt}</Moment>) {handle}: {comment}</p>
            )
        }
    }

    const deleteThisComment = () => {
        try {
            deleteComment(commentId, id, accessToken, messageSocket)
            setCommentId()
        } catch (error) {
            console.error(`error deleting comment ${commentId}`, error)
            displayError(error)
        }
    }

    const postComment = () => {
        try {
            addComment(messageId, comment, id, handle, messageSocket)
            setComment("")
        } catch (error) {
            console.error('error adding comment', error)
            displayError(error)
        }
    }


    const displayAndCreateCollections = () => {
        if (showCollections && !isGooglebot(window.navigator.userAgent)) {
            return (
                <Collections userId={userId} messageId={messageId} close={() => setShowCollections(false)} />
            )
        }
    }

    const displayCopied = () => {
        if (copiedUrl) {
            return (
                <Snackbar
                    open={copiedUrl}
                    autoHideDuration={2000}
                    onClose={() => setCopiedUrl(false)}
                    message='Copied'
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                >

                </Snackbar>
            )
        }
    }

    const displayShare = () => {
        if (showShare && !isGooglebot(window.navigator.userAgent)) {
            return (
                <div onClick={(evt) => {
                    evt.stopPropagation()
                    setShowShare(false)
                }}>
                    <BlurDialog content={() => <ShareContent messageId={messageId} imageUrl={imageUrl}
                        name={name}
                        setCopied={setCopiedUrl} />} hideHelp title={`Share ${name}`} closeIcon />
                </div>
            )
        }
    }

    const getDominantColor = (colors) => {
        const maxArea = Math.max(...colors.map(c => c.area))
        const color = colors.find(c => c.area === maxArea)
        setDominantColor(color.hex)
    }
    /*
    const loadMedia = async (md) => {
        const { source, mediaSource, mimeType } = md
        try {
            switch (mimeType) {
                case 'image/png':
                case 'image/jpeg':
                    const imageMedia = fullscreen ? getMediaDisplay(md) : getMediaThumbnail(md)
                    const { source } = imageMedia
                    const data = await downloadCoreFile(mediaSource ? mediaSource : source, 'image')
                    const src = URL.createObjectURL(data)
                    setImageSrc(src)
                    if (fullscreen) {
                        try {
                            const result = await extractColors(src)
                            getDominantColor(result)
                        } catch (error) {
                            displayError(error, setError)
                        }
                    }
                    break
                default:
                    break

            }
        } catch (error) {
            console.error(error)
            setError('Invalid image access')
        }
    }
    */

    const getMedia = async (mediaArray) => {
        setError()
        const imageMedia = fullscreen ? getMediaDisplay(mediaArray) : getMediaThumbnail(mediaArray)
        //console.log(`DisplayHarmonizePost fullscreen ${fullscreen} imageMedia`, imageMedia)
        if (imageMedia) {
            const { source } = imageMedia
            try {
                const data = await downloadCoreFile(source, 'image')
                const src = URL.createObjectURL(data)
                setImageSrc(src)
                if (fullscreen) {
                    try {
                        const result = await extractColors(src)
                        getDominantColor(result)
                    } catch (error) {
                        displayError(error, setError)
                    }
                }
            } catch (error) {
                displayError(error, setError)
            }
        }

    }

    const displayFullScreen = (username) => {
        return (
            <div style={{
                position: 'relative',
                width: '100vw',
                backgroundColor: dominantColor
            }}
                onClick={(evt) => {
                    evt.stopPropagation()
                    dispatch(setHideOverlay(!hideOverlay))
                }}
            >
                {imageSrc || imageUrl ?
                    <img
                        src={imageSrc ? imageSrc : imageUrl}
                        alt={username}
                        style={portrait ? displayStyles.fullScreenImagePortrait : displayStyles.fullScreenImage} />
                    : <BrokenImage style={{ fontSize: '2em' }} />}

            </div >)
    }

    const displayImageSrcOrUrl = (imageSrc, imageUrl, username) => {
        return (
            <div style={{
                position: 'relative',
                width: isFullListing() ? imageWidth : '100%'
            }}
                onMouseDown={() => dispatch(setHideOverlay(false))}
            >
                {imageSrc || imageUrl ?
                    <img
                        src={imageSrc ? imageSrc : imageUrl}
                        alt={username}
                        style={displayStyles.normalImage} />
                    : <BrokenImage style={{ fontSize: '2em' }} />}

            </div >)
    }

    const addNewEmbed = async () => {
        setShowAddEmbed(false)
        try {
            const embed = createValidEmbed(embedContent, embeds)
            if (embed) {
                try {
                    await addEmbed(messageId, embed, accessToken)
                } catch (error) {
                    displayError(error)
                }

            } else {
                displayError({ message: 'Invalid embed' })
            }
        } catch (error) {
            displayError(error)
        }
    }
    const displayAddEmbed = () => {
        if (showAddEmbed) {
            return (
                <div style={{ padding: '1em', border: '1px solid black' }}>
                    <TextField
                        value={embedContent}
                        onChange={(event) => setEmbedContent(event.target.value)}
                        fullWidth
                        multiline
                        label={`Embed (maximum ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH} characters)`}
                        inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
                    />
                    <Button disabled={embedContent.length === 0}
                        color="primary"
                        variant='contained'
                        autoFocus={true}
                        onClick={addNewEmbed}>
                        Send
                    </Button>

                </div>
            )
        }
    }

    const displayShowAttachment = () => {
        if (attachments && attachments.length) {
            const label = getAttachmentLabel(attachments[0].attachmentType)
            return (

                <div title={`Click to ${showAttachments ? 'hide' : 'show'} ${label}`}>
                    {category === 'Music' ? <QueueMusicTwoTone style={displayStyles.attachmentIcon}
                        onClick={() => dispatch(setShowAttachments(!showAttachments))}
                        color='primary'
                    />
                        :
                        <Description size='large' style={displayStyles.attachmentIcon}
                            onClick={() => dispatch(setShowAttachments(!showAttachments))}
                            color='primary' />}
                </div>
            )
        } else {
            return null
        }
    }

    /**
     * Currently just one attachment
     * @returns 
     */
    const displayAttachments = () => {
        let jsx, attachmentType
        if (attachments && attachments.length) {
            const attachment = attachments[0]
            attachmentType = attachment.attachmentType
            const { attachmentContent } = attachment
            jsx = parse(replaceNewLine(attachmentContent))
        }
        return (
            <div style={{ paddingLeft: '1.5em', paddingRight: '1em', marginTop: '1em', borderTop: '1px solid gray', borderBottom: '1px solid gray' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {jsx ? <div style={{ display: 'flex', alignItems: 'center' }}><b>{getAttachmentLabel(attachmentType)}</b> {displayShowAttachment()}</div> : null}
                    {!fromAdmin ? <div title='Click to share' onClick={(evt) => {
                        evt.stopPropagation()
                        setShowShare(!showShare)
                    }}>
                        <Share style={displayStyles.attachmentIcon} color='primary' />
                    </div> : null}
                    {accessToken && !(fromAdmin || fromSupport) ?
                        <div title='Click to add to a playlist' onClick={(evt) => {
                            evt.stopPropagation()
                            setShowCollections(!showCollections)
                        }}>
                            <LibraryAdd style={displayStyles.attachmentIcon} color='primary' /></div> : null}
                    {!fromAdmin && canEditPost(id, userId, room) ? <div title='Click to edit post' >
                        {fromSupport ? messageId : null}
                        <Edit style={displayStyles.attachmentIcon} color='primary'

                            onClick={(evt) => {
                                evt.stopPropagation()
                                editPost()
                            }} />
                    </div> : null}
                </div>
                {showAttachments ? <p style={{ fontStyle: 'italic' }}>{jsx}</p> : null}
                {/*getCopyright(attachmentType)*/}
            </div>
        )

    }

    const displayCommentInputArea = () => {
        if (!(fromAi || fromAdmin || fromSupport)) {
            const { accessType, name } = room

            if (!(accessType === ACCESS_TYPES.PRIVATE || showAddEmbed)) {
                return (
                    <div>
                        {canComment(room, id) ? <div style={{ display: 'flex', marginLeft: '1em', marginRight: '1em' }}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <textarea
                                    style={{ width: '100%', overflowWrap: 'break-word' }}
                                    type="text"
                                    maxLength={process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH}
                                    placeholder="Add a comment..."
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                                <button
                                    className="comment__button text__button"
                                    disabled={!comment}
                                    onClick={async () => postComment()}
                                >
                                    Send
                                </button>
                            </div>

                        </div> : <div>&nbsp;To comment please <Link to={`/SignIn/${name}`}>sign in</Link></div>}
                    </div>)
            } else {
                return null
            }
        }
    }
    const displayComments = () => {
        if (!fromAi && comments) {
            return (<div style={{ marginLeft: '2em' }}>
                {comments.map((comment) => (
                    displayComment(comment)
                ))}
            </div>)
        } else {
            return null
        }
    }
    /**
     * See https://fontawesome.com/v5/docs/web/use-with/react
     * @returns 
     */
    const displayEmbeds = () => {
        if (embeds && embeds.length) {
            const { embedContent } = embeds[embedIndex < embeds.length ? embedIndex : 0]
            const jsx = parse(embedContent)
            return (
                <div >
                    <div >{jsx}</div>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', flexWrap: 'wrap' }}>

                        {embeds.length > 1 ? embeds.map((e, ix) => <FontAwesomeIcon key={ix} icon={getEmbedIcon(e)}
                            style={{ padding: '0.25em', fontSize: '2em' }} onClick={() => setEmbedIndex(ix)} />) : null}

                        {id === userId && embeds.length < parseInt(process.env.REACT_APP_MAX_NUM_EMBEDS) ?
                            (<div title='Click to add another embed' style={{ display: 'flex', verticalAlign: 'middle' }}>
                                <AddCircle
                                    style={{ fontSize: '2rem' }}
                                    onClick={() => {
                                        setError('')
                                        setShowAddEmbed(!showAddEmbed)
                                    }} /> </div>)
                            : null}
                    </div>

                </div>
            )

        }
        return null
    }

    const getDisplayDate = () => {
        const dateToUse = category === 'Support' ? updatedAt : publishedAt
        const interval = Date.now() - new Date(dateToUse)
        const numHours = interval / (1000 * 60 * 60)
        if (numHours < 23) {
            return (
                <span style={{ fontStyle: 'italic', fontSize: '0.75em' }}><Moment fromNow>{dateToUse}</Moment></span>
            )
        } else {
            return (
                <span style={{ fontStyle: 'italic', fontSize: '0.75em' }}><Moment format="MMM D, YY">{dateToUse}</Moment></span>
            )
        }

    }

    const displayDateAndName = () => {
        if (displayTitle || !isFullListing()) {
            if (name) {
                return (<div className={showPost ? "post__text" : ''}>
                    <p><span style={{ fontWeight: 'bold' }}>{name} {getListens(numListens)}</span> {getDisplayDate()} </p>
                </div>)
            } else {
                return (
                    <div className={showPost ? "post__text" : ''}>
                        {getDisplayDate()}
                    </div>
                )
            }
        }
    }

    const displayImage = () => {

        if (imageSrc || imageUrl) {
            if (fullscreen) {
                return displayFullScreen()
            } else {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        {displayImageSrcOrUrl(imageSrc, imageUrl, username)}
                    </div>
                )
            }
        } else {
            return null
        }

    }

    /**
     * @todo The Message in Social Service only has a userId and username, not firstName or lastName
     * @returns Either Support or CJ Villa
     */
    const getCopyrightName = () => {
        return category === 'Support' ? process.env.REACT_APP_COMPANY_NAME : `${firstName} ${lastName}`
    }
    const displayMessage = () => {
        return (
            message ?
                <div style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
                    <p >{parse(replaceNewLine(message))}</p>
                    <p style={{ fontStyle: 'italic', fontSize: '0.75em' }}>Copyright {moment(createdAt).format('YYYY')} {getCopyrightName()}</p>
                </div>
                : null
        )
    }

    const displayLikes = () => {
        if (numLikes) {
            return (
                <div style={{ display: 'flex', paddingLeft: '10px', paddingRight: '10px', alignItems: 'center' }}>
                    <Favorite />
                    {numLikes > 0 ? `${numLikes}` : null}
                </div>
            )
        }
    }

    const displayCompactImage = () => {

        if (imageSrc || imageUrl) {
            return (
                displayImageSrcOrUrl(imageSrc, imageUrl, username)
            )
        } else {
            return null
        }
    }

    const displayVideoLink = () => {
        const videoMedia = media.find(md => md && md.mimeType === 'video/mp4')
        const thumbnailMedia = media.find(md => md && md.mimeType === 'image/jpeg')
        if (videoMedia) {
            const { name: roomName } = room
            const { sourceName } = videoMedia
            const mediaSource = thumbnailMedia ? thumbnailMedia.source : undefined
            const url = `/video/${username}/${messageId}/${encodeURIComponent(name)}/${roomName}/${sourceName}/${mediaSource}`
            return (
                <a title={`Click to play ${name} video`} href={url} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <OndemandVideoOutlined style={displayStyles.attachmentIcon} />
                </a>
            )
        }
    }
    /**
     * Trim the displayed summary or message if this is veryNarrow and not Googlebot.
     * @returns 
     */
    const displayCompact = () => {
        //playListIndex is always 0...
        if (playList.length) {
            const entry = playList[playListIndex]
            const selected = entry.messageId === messageId

            const audioEntry = media.find(md => md && md.mimeType === 'audio/mpeg')
            let duration
            if (audioEntry && audioEntry.duration) {
                //We have to found down because that's what the AudioPlayer does
                duration = moment.duration(Math.floor(audioEntry.duration) * 1000)
            }
            const actualSummary = summary ? summary : message
            const trimmedSummary = isGooglebot(window.navigator.userAgent) ? actualSummary :
                actualSummary ? actualSummary.length < maxSummary ? actualSummary : `${actualSummary.substring(0, maxSummary)}...` : undefined
            return (
                <div className={status === 0 ? 'postCompactDraft' : selected ? 'postCompactPlaying' : 'postCompact'} style={{ flexDirection: 'row', flex: 1 }}>
                    <div style={{ flex: 0.2 }}>{displayCompactImage()}</div>
                    <div style={{ flex: 0.8, marginLeft: '0.5em', }}>
                        <div style={{ display: 'flex', flex: 1 }}>

                            <div style={{ flex: 0.9 }}>{displayVideoLink()}<b>{name}</b> {getListens(numListens)}
                                <span style={{ fontSize: '0.8em', fontStyle: 'italic' }}> {duration ? `(${duration.format('hh:m:ss')})` : null}</span></div>
                            <div style={{ flex: 0.1, display: 'flex', alignItems: 'center' }}
                                onClick={(evt) => evt.stopPropagation()}
                            >
                                {displayLikes()}
                                {!fromAdmin && canEditPost(id, userId, room) ? <div title='Click to edit post' >
                                    <Edit style={displayStyles.attachmentIcon} color='primary'

                                        onClick={(evt) => {
                                            evt.stopPropagation()
                                            editPost()
                                        }} />
                                </div> : null}
                            </div>
                        </div>
                        <div style={{ flex: 0.4, fontStyle: 'italic', fontSize: '.8em', lineHeight: '1.1em' }}>{trimmedSummary}</div>
                    </div>

                </div >)
        }

    }


    /**
     * Normal is:
     * [date] [name]
     * [image/embeds]50% [message] 50%
     */
    const displayNormal = () => {
        if (fullscreen) {
            return displayImage()
        } else {
            const outerStyle = embeds && embeds.length && message.length ? { minHeight: '30vh', overflowY: 'auto' } : { minHeight: '20vh', overflowY: 'auto' }
            if (status === 0) {
                outerStyle.backgroundColor = '#F0F8FF'
            }
            return (
                <div style={outerStyle}>
                    <div style={{ paddingBottom: '0.5em', paddingLeft: '0.5em' }}>{displayDateAndName()}</div>
                    {displayImage()}
                    <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>

                        {displayAttachments()}
                        {displayMessage()}
                        {displayComments()}
                    </div>
                    {displayCommentInputArea()}
                    {/*
                    <div style={{ padding: '1em' }}>
                        {displayEmbeds()}

                    </div>
            */}
                    {displayAddEmbed()}
                </div>)
        }
    }

    const displaySummary = () => {
        const audioEntry = media.find(md => md && md.mimeType === 'audio/mpeg')
        let duration
        if (audioEntry && audioEntry.duration) {
            //We have to found down because that's what the AudioPlayer does
            duration = moment.duration(Math.floor(audioEntry.duration) * 1000)
        }
        const actualSummary = summary ? summary : message
        const trimmedSummary = actualSummary.length < maxSummary ? actualSummary : `${actualSummary.substring(0, maxSummary)}...`
        return (
            <div className={'postCompact'} style={{ flexDirection: 'row', flex: 1 }}>
                <div style={{ flex: 0.2 }}>{displayCompactImage()}</div>
                <div style={{ flex: 0.8, marginLeft: '0.5em', }}>
                    <div style={{ display: 'flex', flex: 1 }}>

                        <div style={{ flex: 0.9 }}>{displayVideoLink()}<b>{name}</b> {getListens(numListens)}
                            <span style={{ fontSize: '0.8em', fontStyle: 'italic' }}> {duration ? `(${duration.format('hh:m:ss')})` : null}</span></div>
                    </div>
                    <div style={{ flex: 0.4, fontStyle: 'italic', fontSize: '.8em', lineHeight: '1.1em' }}>{trimmedSummary}</div>
                </div>

            </div >)

    }

    const displayFromAiOrStudio = () => {
        if (fromAi) {
            return displaySummary()
        } else {
            return (
                <>
                    <ErrorLine error={error} />
                    {
                        fromAdmin || fromSupport || isGooglebot(window.navigator.userAgent) ? displayNormal() :
                            isFullListing() ? displayNormal() : displayCompact()}
                    {displayAndCreateCollections()}
                    {displayShare()}
                    {displayCopied()}

                    <ModalDialog
                        title='Confirm Delete Comment'
                        message={`Are you sure you want to permanently delete this comment?`}
                        close={() => setConfirmDeleteComment(false)}
                        open={confirmDeleteComment}
                        noLabel='No'
                        no={() => setConfirmDeleteComment(false)}
                        yesLabel='Yes'
                        yes={async () => {
                            setConfirmDeleteComment(false)
                            deleteThisComment()
                        }}
                    />
                </>
            )
        }
    }
    const displayMain = () => {
        return (
            <div className={fullscreen ? 'postFullscreen' : playerMode === PLAYER_MODES.COMPACT ? 'postCompactContainer' : 'post'}
                style={{ position: 'relative', backgroundColor: fullscreen ? dominantColor : 'none' }}
                onClick={() => selected()}>
                {displayFromAiOrStudio()}
            </div >

        )
    }

    useEffect(() => {
        if (fullscreen) {
            console.log(`DisplayHarmonizePost fullscreen ${fullscreen} playListIndex ${playListIndex}`,
                playList[playListIndex])
            getMedia(playList[playListIndex].entryMedia)
        } else
            if (media && media.length) {
                getMedia(media)
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playListIndex])
    // If you disable right click no one can copy text from the posts which isn't good
    //if (process.env.REACT_APP_MODE === 'development') {
    return displayMain()

}

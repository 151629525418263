import { faAmazon, faApple, faDeezer, faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { logWithTime } from './screenUtils'
export const EMBED_TYPE = {
    SPOTIFY: 0, APPLE_MUSIC: 1, AMAZON_MUSIC: 2, DEEZER: 3, TIKTOK: 50, YOUTUBE: 51, GOOGLE_DOC: 80,
    GOOGLE_PRESENTATION: 81, GOOGLE_MAP: 82
}
export const VALID_HOSTS = [
    { embedType: EMBED_TYPE.SPOTIFY, url: 'https://open.spotify.com/embed/', icon: faSpotify },
    { embedType: EMBED_TYPE.APPLE_MUSIC, url: 'https://embed.music.apple.com/', icon: faApple },
    { embedType: EMBED_TYPE.AMAZON_MUSIC, url: 'https://music.amazon.com/embed/', icon: faAmazon },
    { embedType: EMBED_TYPE.DEEZER, url: 'https://widget.deezer.com/widget/', icon: faDeezer },
    { embedType: EMBED_TYPE.YOUTUBE, url: 'https://www.youtube.com/embed/', icon: faYoutube },
    {
        embedType: EMBED_TYPE.GOOGLE_DOC, url: 'https://docs.google.com/document/'
    },
    {
        embedType: EMBED_TYPE.GOOGLE_PRESENTATION, url: 'https://docs.google.com/presentation/'
    },
    { embedType: EMBED_TYPE.GOOGLE_MAP, url: 'https://www.google.com/maps/embed' }
]

const TIKTOK_TAG = 'data-video-id="'
const TIKTOK_IFRAME = '<iframe name="__tt_embed__v60485874426648700" sandbox="allow-popups allow-popups-to-escape-sandbox' +
    ' allow-scripts allow-top-navigation allow-same-origin" '
    + ' src=https://www.tiktok.com/embed/v2/[videoId]?lang=en-US" style="width: 100%; height: 739px; display: block; visibility: unset; max-height: 739px;"></iframe>'

export const getEmbedIcon = (embed) => {
    const embedHost = VALID_HOSTS.find(h => h.embedType === embed.embedType)
    if (embedHost) {
        return embedHost.icon
    }
}
/**
* As of 5/25/2023, the first line of a TikTok embed is e.g.:
* <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@cjvilla.songs/video/7231943344237088043" data-video-id="7231943344237088043" style="max-width: 605px;min-width: 325px;" > 
* At the end there is a script tag for TikTok for a script that presumable scans the blockquite for the video id.
* 
* We check for the class, the cite, the data-video-id,  the closing </blockquote> and the script tag to confirm it is a valid embed.
* 
* As of 6/8/2023 we will also search for the tiktok video URL and convert it to an embed we thing
* @param {*} embedContent 
* @returns 
*/
export const validateTikTokEmbed = (embedContent) => {
    if (embedContent.indexOf('<blockquote') !== -1) {

        if (embedContent.indexOf('class="tiktok-embed"') == -1) {
            console.error('TikTok missing class')
        } else if (embedContent.indexOf('cite="https://www.tiktok.com/') == -1) {
            console.error('TikTok missing cite')
        } else if (embedContent.indexOf('data-video-id') == -1) {
            console.error('TikTok missing data-video-id')
        } else if (embedContent.indexOf('</blockquote>') == -1) {
            console.error('TikTok missing closing blockquote')
        } else if (embedContent.indexOf('<script async src="https://www.tiktok.com/embed.js"></script>') == -1) {
            console.error('TikTok missing script')
        } else {

            const ix = embedContent.indexOf(TIKTOK_TAG)
            const ix2 = embedContent.indexOf('"', ix + TIKTOK_TAG.length)
            const videoId = embedContent.substring(ix + TIKTOK_TAG.length, ix2)
            const tikTokEmbed = TIKTOK_IFRAME.replace('[videoId]', videoId)
            console.log(`tikTokEmbed ${videoId}`, tikTokEmbed)
            return { embedType: EMBED_TYPE.TIKTOK, embedContent: tikTokEmbed }
        }
    }
    return null
}


/**
 * Determine the embed type from embedContent. If valid return
 * {embedType,embedContent} which can then be sent as part of a message.
 * @param {*} embedText 
 * @returns 
 * @throws Error if there is already an embed of this type
 */
export const createValidEmbed = (embedText, existingEmbeds) => {
    let embed = validateEmbed(embedText)
    if (!embed) {
        embed = validateTikTokEmbed(embedText)
    }
    logWithTime('createValidEmbed', embed)
    if (existingEmbeds) {
        logWithTime('existingEmbeds', existingEmbeds)
        const existingEmbed = existingEmbeds.findIndex(em => em.embedType === embed.embedType) !== -1
        if (existingEmbed) {
            throw new Error('You already have an embed of this type')
        }
    }
    return embed
}

/**
 * 
 * A TikTok embed is contained in a blockquote. Otherwise,
 * An Embed must start with '<iframe', end with '<iframe>', and have a src tag that contains one of
 * the VALID_HOSTS. Everything is case-sensitive.
 * @param {*} embedText 
 * @returns {embedType,embedContent}
 */
const validateEmbed = (embedText) => {
    const embedString = embedText.toLowerCase()
    if (embedString.length) {
        const ix = embedString.indexOf('<iframe')
        if (ix !== -1) {
            const ix2 = embedString.indexOf('</iframe>', ix + 1)
            if (ix2 !== -1) {
                const parts = embedString.split('src')
                if (parts.length > 1) {
                    const src = parts[1]
                    const embedHost = VALID_HOSTS.find(host => src.indexOf(host.url) !== -1)
                    if (embedHost) {
                        return { embedType: embedHost.embedType, embedContent: embedText }
                    }
                }
            }
        }

    }
    return null
}
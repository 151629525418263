const RESERVED_ROOMS = [
    'cj',
    'cjvilla',
    'charlesvilla',
    'charlesjvilla',
    'chazchateau',
    'home',
    '^vortex',
    '^harmonize',
    'private',
    'press',
    'family',
    'friends'
]

/**
 * Returns true if roomName matches a reserved room name
 * @param {*} roomName 
 * @returns 
 */
export const checkReservedRoom = (roomName) => {
    const lRoomName = roomName.toLowerCase()
    for (const res of RESERVED_ROOMS) {
        const re = new RegExp(res)
        if (re.test(lRoomName.replace(/[^a-z0-9]/gi, ''))) {
            return true
        }
    }
    return false
}

const ALL_VORTEX_CATEGORIES = [
    'Art',
    'Auto',
    'Business',
    'Crime',
    'Film',
    'Finance',
    'General',
    'Global',
    'Legal',
    'Music',
    'National',
    'Photography',
    'Podcast',
    'Social',
    'Sports',
    'Style',
    'Support',
    'Technology',
    'Travel',
    'TV ',
    'Video'
]

const VORTEX_CATEGORIES = [
    'Music',
    'Travel',
    'Sports'
]
export const getVortexCategories = () => {
    let categories = []
    VORTEX_CATEGORIES.forEach((cat, ix) => {
        categories.push(`${ix + 1} - ${cat}`)
    })
    return categories
}

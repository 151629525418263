import { useMediaQuery } from '@material-ui/core'
import Card from 'components/Card/Card'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isVeryNarrow } from 'util/screenUtils'
import ReactGA from 'react-ga4'
import AudioPlayer from 'react-h5-audio-player'
import { createRsaPublicKey } from 'util/encryptUtils'
import { encryptAudioUrl } from 'util/encryptUtils'
import moment from 'moment'
import { extractColors } from 'extract-colors'
import { displayError } from 'util/screenUtils'
import { checkCreditUsage } from 'util/creditUtils'
import { useDispatch, useSelector } from 'react-redux'
import { setCreditPlayTime } from 'redux/actions/harmonizeActions'
import { setAccruedCredits } from 'redux/actions/harmonizeActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import ErrorLine from 'components/ErrorLine'
import { getRoomById } from 'controllers/VortexController'
import { getMediaDisplay } from 'util/imageUtils'
import { isNewFormat } from 'util/postUtils'

export default function SongCardPlayer({ song, playerRef, onPlay, fromPlaylist, selected, hostRoomName = 'public' }) {
    const { _id: messageId, createdAt, updatedAt, summary, roomId, name, title } = song
    const veryNarrow = isVeryNarrow(useMediaQuery)

    const styles = {
        imageCard: {
            position: 'relative',
            borderRadius: veryNarrow ? '1em' : '2em',
            color: 'white',
            width: veryNarrow ? '90vw' : '45vw',
            height: veryNarrow ? '160vw' : '80vw'
        },
        hovered: {
            margin: 'auto',
            transform: 'scale3d(1.05, 1.05, 1)',
        },
        notHovered: {
            margin: 'auto',
        },
    }

    const account = getStorageAccount()
    const accessToken = getStorageAccessToken()
    const { accruedCredits, accruedCreditsRoom, creditPlayTime } = useSelector(state => state.harmonize)
    const [imageSrc, setImageSrc] = useState()
    const [url, setUrl] = useState()
    const [error, setError] = useState()
    const [dominantColor, setDominantColor] = useState()
    const [songRoom, setSongRoom] = useState()
    const history = useHistory()
    const dispatch = useDispatch()

    const checkCredits = async () => {
        try {
            const songForCredit = { ...song, messageId }
            const currentCredits = await checkCreditUsage(accruedCredits, accruedCreditsRoom, creditPlayTime, songRoom, songForCredit,
                account, accessToken, dispatch)
            if (currentCredits < 1) {
                displayError(error, 'Unable to play: out of credits')
            }
        } catch (error) {
            displayError(error, setError, 5)
            //If applyCredit FAILS we still have to reset the accruedCredits array or we blow up the server
            //with a huge array. There should only be max 6 entries (with a 1 minute server update interval and a 10 second accruedCredit interval)
            dispatch(setCreditPlayTime(1))
            dispatch(setAccruedCredits([]))
        }
    }

    const getAudioMedia = () => {
        const media = song.media
        const mediaEntry = media.find(a => a.mimeType === 'audio/mpeg')
        const { source } = mediaEntry
        const sourceName = mediaEntry.sourceName ? mediaEntry.sourceName : mediaEntry.name
        return { source, sourceName }
    }

    const getUrl = async () => {
        const { source, sourceName } = getAudioMedia()
        const rsaKeyPair = await createRsaPublicKey()
        const songUrl = await encryptAudioUrl(source, sourceName, rsaKeyPair, !isNewFormat(song))
        setUrl(songUrl)
    }

    const displayAudioHeader = () => {
        return (
            <div>
                {summary}
            </div>
        )
    }

    const displayImageCard = () => {
        if (url && songRoom) {
            const { name: roomName } = songRoom
            if (roomName === hostRoomName) {
                return (
                    <Card
                        className='plain'
                        style={{
                            ...styles.imageCard,
                            backgroundColor: dominantColor
                        }}

                    >
                        <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>{name || title}</h4>
                        <ErrorLine error={error} />
                        <img src={imageSrc} style={{ cursor: 'pointer' }} title='Click to view this song' onClick={() => {
                            if (selected) { selected() } else {
                                history.push(`/song/${messageId}`)
                                ReactGA.event({ category: 'click', action: 'song_card', label: name })
                            }
                        }} />
                        <AudioPlayer
                            ref={playerRef}
                            src={url}
                            header={displayAudioHeader()}
                            showFilledVolume
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                            onError={(error) => {
                                console.error('Error playing audio', error)
                                setError(`Unable to play `)
                            }}
                            onListen={() => {
                                if (onPlay) {
                                    onPlay(playerRef)
                                }
                                checkCredits()
                            }}
                            style={{
                                backgroundColor: dominantColor,
                                position: 'absolute',
                                bottom: 0
                            }}
                        />
                    </Card>
                )
            }
        } else {
            return null
        }
    }

    const getDominantColor = (colors) => {
        const maxArea = Math.max(...colors.map(c => c.area))
        const color = colors.find(c => c.area === maxArea)
        setDominantColor(color.hex)
    }

    const getMedia = async () => {
        await getRoom()
        const mediaArray = fromPlaylist ? song.entryMedia : song.media
        const thumbnailMedia = getMediaDisplay(mediaArray)
        const { source } = thumbnailMedia
        const data = await downloadCoreFile(source, 'image')
        const src = URL.createObjectURL(data)
        setImageSrc(src)
        try {
            const result = await extractColors(src)
            getDominantColor(result)
        } catch (error) {
            displayError(error, setError)
        }
    }

    const getRoom = async () => {
        try {
            const room = await getRoomById(roomId)
            setSongRoom(room)
        } catch (error) {
            displayError(error, setError)
        }
    }

    useEffect(() => {
        getMedia()
        getUrl()

    }, [song])

    return (
        displayImageCard()
    )

}
import { useMediaQuery } from '@material-ui/core'
import Card from 'components/Card/Card'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { getPublicUserByHandle } from 'controllers/AccountController'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { logWithTime } from 'util/screenUtils'
import Studio from 'vortex/Studio'
import Footer from 'components/Footer/Footer'
import { ACCESS_TYPES } from './CreateVortexRoom'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import MyProfile from 'pages/MyProfile'
import { getStorageAccount } from 'redux/actions/accountActions'
import UserProfile from 'pages/UserProfile'

const useStyles = makeStyles(styles)

export default function VortexSwitch() {
    const { currentArtist } = useSelector((state) => state.artist)
    const accessToken = getStorageAccessToken()
    const account = getStorageAccount()
    const { handle, useDefaultRoom, selectedRoom, startWithPost, requestedSong } = currentArtist
    console.log(`\n*** VortexSwitch currentArtist`, currentArtist)
    const [user, setUser] = useState(null)
    const [hasPublicRoomName, setHasPublicRoomName] = useState(false)
    const [invalidUser, setInvalidUser] = useState(false)
    const narrow = useMediaQuery('(max-width:400px)')
    const classes = useStyles()

    const noSuchUser = () => {
        return (<div >
            <div style={{
                height: narrow ? '200vh' : '100vw'
            }}>
                <HarmonizeHeader />
                <div className={classes.container}>
                    <GridContainer style={{ justifyContent: 'center' }}>
                        <GridItem xs={12} sm={12} md={8}>
                            <Card >
                                There is no user {handle}
                            </Card>

                        </GridItem>
                    </GridContainer>
                </div>
                <Footer position={'fixed'} />
            </div>

        </div>)
    }
    useEffect(() => {
        console.log('VortexSwitch useEffect')
        const getArtist = async () => {
            try {
                const publicUser = await getPublicUserByHandle(handle)
                console.log('publicUser', publicUser)
                if (publicUser.status !== 200) {
                    setUser(null)
                    setInvalidUser(true)
                } else {
                    const { rooms, defaultRoomName } = publicUser
                    if (rooms && defaultRoomName) {
                        const gotoRoom = rooms.find(r => r.name === defaultRoomName)
                        console.log(`...VortexSwitch defaultRoomName ${defaultRoomName}`, gotoRoom)
                        if (gotoRoom && gotoRoom.accessType === ACCESS_TYPES.PUBLIC) {
                            setHasPublicRoomName(gotoRoom.name)
                        }
                    }
                    setUser(publicUser)
                }
            } catch (error) {
                console.error(`Unable to get public user ${handle}`, error)
            }
        }

        if (!user) {
            getArtist()
        }
    }, [])

    if (invalidUser) {
        return noSuchUser()
    } else if (user) {
        if ((useDefaultRoom && user.defaultRoomName) || selectedRoom) {
            logWithTime(`VortexSwitch use Studio hasPublicRoomName ${hasPublicRoomName} selectedRoom ${selectedRoom}`, accessToken)
            return <Studio
                startWithPost={startWithPost}
                defaultRoom={selectedRoom ? selectedRoom : user.defaultRoomName}
                roomHandle={user.handle}
                roomUsername={user.username}
                hasPublicRoomName={hasPublicRoomName}
                requestedSong={requestedSong}
            />
        } else if (account && user.handle !== account.handle) {
            return <UserProfile profileUsername={user.username} />
        } else {
            console.log('VortexSwitch use MyProfile ')
            return <MyProfile handle={handle} />
        }
    } else return null
}
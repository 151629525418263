import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField'

/**
 * Pass setter when using state. Pass updater when updating the signup redux store.
 * @param {*} props
 * @returns
 */
export default function RequiredTextField(props) {
  const {
    name,
    label,
    defaultValue,
    helper,
    setter,
    updater,
    validator,
    required = true,
    fullWidth = true,
    ...rest
  } = props
  const [text, setText] = useState(defaultValue)
  const [error, setError] = useState()
  const [currentHelper, setCurrentHelper] = useState(helper)
  //const signuper = useSelector((state) => state.signup)
  //const { userSignup } = signuper
  const dispatch = useDispatch()

  return (
    <TextField
      {...rest}
      name={name}
      defaultValue={defaultValue}
      variant='standard'
      onChange={(event) => setText(event.target.value)}
      onBlur={() => {
        if (validator && text) {
          const invalid = validator(text)
          if (invalid) {
            setCurrentHelper(invalid)
            setError(true)
          } else {
            setError(false)
            if (setter) {
              setter(text)
            } else {
              dispatch(updater(text)) //, userSignup))
            }
          }
        } else {
          if (text) {
            if (setter) {
              setter(text)
            } else {
              dispatch(updater(text)) //, userSignup))
            }
            setError(false)
          } else {
            if (setter) {
              setter(null)
            }
            setError(true)
          }
        }
      }}
      required={required}
      fullWidth={fullWidth}
      id={name}
      label={label}
      error={required && error}
      helperText={error ? currentHelper : ''}
    />
  )
}

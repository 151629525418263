import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { useEffect } from 'react'

const useStyles = makeStyles(styles)

export default function Privacy() {
  const classes = useStyles()
  useEffect(() => {
    document.title = 'Terms of Service'
  }, [])
  return (
    <div>
      <HarmonizeHeader />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GridContainer style={{ justifyContent: 'center' }}>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.description}>
                  <p>
                    The following Terms of Service Agreement (the "TOS") is a
                    legally binding agreement that shall govern the relationship
                    with our users and others who may interact or interface with
                    {' '}{process.env.REACT_APP_COMPANY_NAME}, a New Mexico sole proprietorship ("Company"), and
                    our subsidiaries and affiliates, in association with the use
                    of the Company websites, which includes{' '}
                    <a
                      href='https://harmonize.social'
                      rel='noreferrer'
                      target='_blank'
                    >
                      https://harmonize.social
                    </a>{' '}

                    and other Web sites operated by Company ("Harmonize"); and all
                    other company Services, which shall be defined below.
                  </p>

                  <p>
                    Any and all visitors to our Services, whether they are
                    registered or not, shall be deemed as "users" of the herein
                    contained Services provided for the purpose of this TOS.
                  </p>
                  <p>
                    Once an individual registers for our Services, through the
                    process of creating an account, the user shall then be
                    considered a Member.
                  </p>
                  <p>
                    Once a Member uploads any content to Harmonize, the Member shall then be connsidered
                    an Artist.
                  </p>
                  <p>
                    The user and/or Member acknowledges and agrees that the
                    Services provided and made available through Harmonize, which may include others made available on various
                    networking sites and numerous other platforms
                    and downloadable programs, are the sole property of Company.
                    At its discretion, Company may offer additional Services
                    and/or products, or update, modify or revise any current
                    content and Services, and this Agreement shall apply to any
                    and all additional Services and/or products and any and all
                    updated, modified or revised Services unless otherwise
                    stipulated. Company does hereby reserve the right to cancel
                    and cease offering any of the aforementioned Services and/or
                    products. You, as the end user and/or Member, acknowledge,
                    accept and agree that Company shall not be held liable for
                    any such updates, modifications, revisions, suspensions or
                    discontinuance of any of our Services and/or products. Your
                    continued use of the Services provided, after such posting
                    of any updates, changes, and/or modifications shall
                    constitute your acceptance of such updates, changes and/or
                    modifications, and as such, frequent review of this
                    Agreement and any and all applicable terms and policies
                    should be made by you to ensure you are aware of all terms
                    and policies currently in effect. Should you not agree to
                    the updated, modified, revised or modified terms, you must
                    stop using the provided Services.
                  </p>

                  <p>
                    Furthermore, the user and/or Member understands,
                    acknowledges and agrees that the Services offered shall be
                    provided "AS IS" and as such Company shall not assume any
                    responsibility or obligation for the timeliness, missed
                    delivery, deletion and/or any failure to store user content,
                    communication or personalization settings.
                    <h4>REGISTRATION</h4>
                    <p>
                      To register and become a Member of the Services, you must
                      be 18 years of age to enter into and form a legally
                      binding contract. In addition, you must be in good
                      standing and not an individual that has been previously
                      barred from receiving Company's Services under the laws
                      and statutes of the United States or other applicable
                      jurisdiction.
                    </p>
                    <p>
                      Furthermore, the registering party hereby acknowledges,
                      understands and agrees to:
                      <br />
                      a) furnish factual, correct, current and complete
                      information with regards to yourself as may be requested
                      by the data registration process, and
                      <br />
                      b) maintain and promptly update your registration and
                      profile information in an effort to maintain accuracy and
                      completeness at all times.
                    </p>
                    <p>
                      If anyone knowingly provides any information of a false,
                      untrue, inaccurate or incomplete nature, Company will have
                      sufficient grounds and rights to suspend or terminate the
                      member in violation of this aspect of the Agreement, and
                      as such refuse any and all current or future use of
                      Company Services, or any portion thereof.
                    </p>
                    <h4>MUSIC CONTENT AND PAYMENT</h4>
                    <p>
                      Company operates Harmonize services that facilitate the streaming of Artist content to Members.
                      Company collects fees from Members who listen to, view, or in any way
                      consume such content. Company pays a portion of these fees directly to Artists.
                      As such, Artist grants
                      to Company a non-exclusive, royalty-free, worldwide grant to stream Artist content.
                    </p>
                    <p>
                      Company pays Artist accrued fees on the last business day of the month.
                      In order to receive payment, an Artist must have a valid PayPal account registered
                      using the same email address that was used to register with Harmonize.
                    </p>
                    <p>
                      An Artist must own 100% of all rights of any content uploaded to Harmonize, including but not limited to audio files
                      and image files. Company is not responsible for registration with or participation in any
                      other organization on behalf of Artist, including but not limited to the Mechanical Licensing Collective,
                      Sound Exchange, any performing rights organization, or any film or video synch licensing organization.
                    </p>
                    <p>
                      An Artist may unpublish content at any time. Unpublished content will only be available for
                      Artist streaming. Further, an Artist may delete content at any time, and deleted content will
                      no longer be available for streaming to any Artist or Member.
                    </p>
                    <p>
                      Artist's use of Harmonize for streaming content does not prohibit or preclude the Artist's use of such content
                      on any other content platform.
                    </p>

                    <h4>PRIVACY POLICY</h4>
                    <p>
                      Every Member's registration data and various other
                      personal information are strictly protected by the Company
                      Online Privacy Policy (see the full Privacy Policy{' '}
                      <a href='/Privacy' rel='noreferrer' target='_blank'>
                        here
                      </a>
                      ). As a Member, you herein consent to the collection and
                      use of the information provided, including the transfer of
                      information within the United States and/or other
                      countries for storage, processing or use by Company and/or
                      our subsidiaries and affiliates.
                    </p>
                    <h4>MEMBER ACCOUNT, USERNAME, PASSWORD AND SECURITY</h4>
                    <p>
                      You shall be responsible for maintaining the secrecy and
                      confidentiality of your password and for all activities
                      that transpire on or within your account. It shall be your
                      responsibility to notify Company immediately if you notice
                      any unauthorized access or use of your account or password
                      or any other breach of security. Company shall not be held
                      liable for any loss and/or damage arising from any failure
                      to comply with this term and/or condition of the TOS.
                    </p>
                    <h4>CONDUCT</h4>
                    <p>
                      As a member of the Services, you herein acknowledge,
                      understand and agree that all information, text, software,
                      data, photographs, music, video, messages, tags or any
                      other content, whether it is publicly or privately posted
                      and/or transmitted, is the expressed sole responsibility
                      of the individual from whom the content originated. In
                      short, this means that you are solely responsible for any
                      and all content posted, uploaded, emailed, transmitted or
                      otherwise made available by way of Company Services, and
                      as such, we do not guarantee the accuracy, integrity or
                      quality of such content. It is expressly understood that
                      by use of our Services, you may be exposed to content
                      including, but not limited to, any errors or omissions in
                      any content posted, and/or any loss or damage of any kind
                      incurred as a result of the use of any content posted,
                      emailed, transmitted or otherwise made available by
                      Company.
                    </p>
                    <p>
                      Furthermore, you herein agree not to make use of Company's
                      Services for the purpose of:
                      <br />
                      a) uploading, posting, emailing, transmitting, or
                      otherwise making available any content that shall be
                      deemed unlawful;
                      <br />
                      b) causing harm to minors in any manner whatsoever;
                      <br />
                      c) uploading, posting, emailing, transmitting or otherwise
                      offering any such content that may infringe upon any
                      patent, copyright, trademark, or any other proprietary or
                      intellectual rights of any other party;
                      <br />
                      d) uploading, posting, emailing, transmitting or otherwise
                      offering any content that you do not personally have any
                      right to offer pursuant to any law or in accordance with
                      any contractual or fiduciary relationship;
                      <br />

                      e) uploading, posting, emailing, transmitting, or
                      otherwise offering any source that may contain a software
                      virus or other computer code, any files and/or programs
                      which have been designed to interfere, destroy and/or
                      limit the operation of any computer software, hardware, or
                      telecommunication equipment;
                      <br />
                      f) disrupting the normal flow of communication, or
                      otherwise acting in any manner that would negatively
                      affect other users' ability to participate in any real
                      time interactions;
                      <br />
                      g) interfering with or disrupting any Company Services,
                      servers and/or networks that may be connected or related
                      to our website, including, but not limited to, the use of
                      any device software and/or routine to bypass the robot
                      exclusion headers;
                      <br />
                      h) intentionally or unintentionally violating any local,
                      state, federal, national or international law, including,
                      but not limited to, rules, guidelines, and/or regulations
                      decreed by the U.S. Securities and Exchange Commission, in
                      addition to any rules of any nation or other securities
                      exchange, that would include without limitation, the New
                      York Stock Exchange, the American Stock Exchange, or the
                      NASDAQ, and any regulations having the force of law;
                      <br />
                      i) providing informational support or resources,
                      concealing and/or disguising the character, location, and
                      or source to any organization delegated by the United
                      States government as a "foreign terrorist organization" in
                      accordance to Section 219 of the Immigration Nationality
                      Act;

                      <br />
                      j) collecting or storing of any personal data relating to
                      any other Member or user in connection with the prohibited
                      conduct and/or activities which have been set forth in the
                      aforementioned paragraphs.
                    </p>
                    <p>
                      Company herein reserves the right to to remove
                      and/or delete any such content that would violate the TOS.
                    </p>
                    <p>
                      Company herein reserves the right to access, preserve
                      and/or disclose Member account information and/or content
                      if it is requested to do so by law or in good faith belief
                      that any such action is deemed reasonably necessary for:
                      <br />
                      a) compliance with any legal process;
                      <br />
                      b) enforcement of the TOS;
                      <br />
                      c) responding to any claim that therein contained content
                      is in violation of the rights of any third party;
                      <br />
                      d) responding to requests for customer service; or
                      <br />
                      e) protecting the rights, property or the personal safety
                      of Company, its visitors, users and members, including the
                      general public.
                    </p>
                    <p>
                      Company herein reserves the right to include the use of
                      security components that may permit digital information or
                      material to be protected, and that such use of information
                      and/or material is subject to usage guidelines and
                      regulations established by Company or any other content
                      providers supplying content services to Company. You are
                      hereby prohibited from making any attempt to override or
                      circumvent any of the embedded usage rules in our
                      Services. Furthermore, unauthorized reproduction,
                      publication, distribution, or exhibition of any
                      information or materials supplied by our Services, despite
                      whether done so in whole or in part, is expressly
                      prohibited.
                    </p>
                    <h4>INDEMNITY</h4>
                    <p>
                      All users and/or Members herein agree to insure and hold
                      Company, our subsidiaries, affiliates, agents, employees,
                      officers, partners and/or licensors blameless or not
                      liable for any claim or demand, which may include, but is
                      not limited to, reasonable attorney fees made by any third
                      party, which may arise from any content a Member or user
                      of our site may submit, post, modify, transmit or
                      otherwise make available through our Services, the use of
                      Company Services or your connection with these Services,
                      your violations of the Terms of Service and/or your
                      violation of any such rights of another person.
                    </p>
                    <h4>COMMERCIAL REUSE OF SERVICES</h4>
                    <p>
                      The Member or user herein agrees not to replicate,
                      duplicate, copy, trade, sell, resell nor exploit for any
                      commercial reason any part, use of, or access to Company's
                      Services.
                    </p>
                    <h4>USE AND STORAGE GENERAL PRACTICES</h4>
                    <p>
                      You herein acknowledge that Company may set up any such
                      practices and/or limits regarding the use of our Services,
                      without limitation of the maximum number of days that any
                      email, message posting or any other uploaded content shall
                      be retained by Company, nor the maximum number of email
                      messages that may be sent and/or received by any member,
                      the maximum volume or size of any email message that may
                      be sent from or may be received by an account on our
                      Service, the maximum disk space allowable that shall be
                      allocated on Company's servers on the member's behalf,
                      and/or the maximum number of times and/or duration that
                      any member may access our Services in a given period of
                      time. In addition, you also agree that Company has
                      absolutely no responsibility or liability for the removal
                      or failure to maintain storage of any messages and/or
                      other communications or content maintained or transmitted
                      by our Services. You also herein acknowledge that we
                      reserve the right to delete or remove any account that is
                      no longer active for an extended period of time.
                      Furthermore, Company shall reserve the right to modify,
                      alter and/or update these general practices and limits at
                      our discretion.
                    </p>
                    <h4>MODIFICATIONS</h4>
                    <p>
                      Company shall reserve the right at any time it may deem
                      fit, to modify, alter and or discontinue, whether
                      temporarily or permanently, our service, or any part
                      thereof, with or without prior notice. In addition, we
                      shall not be held liable to you or to any third party for
                      any such alteration, modification, suspension and/or
                      discontinuance of our Services, or any part thereof.
                    </p>
                    <h4>TERMINATION</h4>
                    <p>
                      As a member of the Services, you may cancel or terminate
                      your account, associated email address and/or access to
                      our Services by submitting a cancellation or termination
                      request to info@harmonize.social.
                    </p>
                    <p>
                      As a member, you agree that Company may, without any prior
                      written notice, immediately suspend, terminate,
                      discontinue and/or limit your account, any email
                      associated with your account, and access to any of our
                      Services. The cause for such termination, discontinuance,
                      suspension and/or limitation of access shall include, but
                      is not limited to:
                      <br />
                      a) any breach or violation of our TOS or any other
                      incorporated agreement, regulation and/or guideline;
                      <br />
                      b) by way of requests from law enforcement or any other
                      governmental agencies;
                      <br />
                      c) the discontinuance, alteration and/or material
                      modification to our Services, or any part thereof;
                      <br />
                      d) unexpected technical or security issues and/or
                      problems;
                      <br />
                      e) any extended periods of inactivity;
                      <br />
                      f) any engagement by you in any fraudulent or illegal
                      activities; and/or
                      <br />
                      g) the nonpayment of any associated fees that may be owed
                      by you in connection with your Harmonize account Services.
                    </p>
                    <p>
                      Furthermore, you herein agree that any and all
                      terminations, suspensions, discontinuances, and/or
                      limitations of access for cause shall be made at our sole
                      discretion and that we shall not be liable to you or any
                      other third party with regards to the termination of your
                      account, associated email address and/or access to any of
                      our Services.
                    </p>
                    <p>
                      The termination of your account with the Services shall
                      include any and/or all of the following:
                      <br />
                      a) the removal of any access to all or part of the
                      Services offered;
                      <br />
                      b) the deletion of your password and any and all related
                      information, files, and any such content that may be
                      associated with or inside your account, or any part
                      thereof; and
                      <br />
                      c) the barring of any further use of all or part of our
                      Services.
                    </p>
                    <h4>ADVERTISERS</h4>
                    <p>
                      Any correspondence or business dealings with, or the
                      participation in any promotions of, advertisers located on
                      or through our Services, which may include the payment
                      and/or delivery of such related goods and/or Services, and
                      any such other term, condition, warranty and/or
                      representation associated with such dealings, are and
                      shall be solely between you and any such advertiser.
                      Moreover, you herein agree that Company shall not be held
                      responsible or liable for any loss or damage of any nature
                      or manner incurred as a direct result of any such dealings
                      or as a result of the presence of such advertisers on our
                      website.
                    </p>
                    <h4>LINKS</h4>
                    <p>
                      Either Company or any third parties may provide links to
                      other websites and/or resources. Thus, you acknowledge and
                      agree that we are not responsible for the availability of
                      any such external sites or resources, and as such, we do
                      not endorse nor are we responsible or liable for any
                      content, products, advertising or any other materials, on
                      or available from such third party sites or resources.
                      Furthermore, you acknowledge and agree that Company shall
                      not be responsible or liable, directly or indirectly, for
                      any such damage or loss which may be a result of, caused
                      or allegedly to be caused by or in connection with the use
                      of or the reliance on any such content, goods or Services
                      made available on or through any such site or resource.
                    </p>
                    <h4>PROPRIETARY RIGHTS</h4>
                    <p>
                      You do hereby acknowledge and agree that Company's
                      Services and any essential software that may be used in
                      connection with our Services ("Software") shall contain
                      proprietary and confidential material that is protected by
                      applicable intellectual property rights and other laws.
                      Furthermore, you herein acknowledge and agree that any
                      Content which may be contained in any advertisements or
                      information presented by and through our Services or by
                      advertisers is protected by copyrights, trademarks,
                      patents or other proprietary rights and laws. Therefore,
                      except for that which is expressly permitted by applicable
                      law or as authorized by Company or such applicable
                      licensor, you agree not to alter, modify, lease, rent,
                      loan, sell, distribute, transmit, broadcast, publicly
                      perform and/or created any plagiaristic works which are
                      based on Company Services (e.g. Content or Software), in
                      whole or part.
                    </p>
                    <p>
                      Company herein has granted you personal, non-transferable
                      and non-exclusive rights and/or license to make use of the
                      object code or our Software on a single computer or mobile
                      device, as long as you do not, and shall not, allow any
                      third party to duplicate, alter, modify, create or
                      plagiarize work from, reverse engineer, reverse assemble
                      or otherwise make an attempt to locate or discern any
                      source code, sell, assign, sublicense, grant a security
                      interest in and/or otherwise transfer any such right in
                      the Software. Furthermore, you do herein agree not to
                      alter or change the Software in any manner, nature or
                      form, and as such, not to use any modified versions of the
                      Software, including and without limitation, for the
                      purpose of obtaining unauthorized access to our Services.
                      Lastly, you also agree not to access or attempt to access
                      our Services through any means other than through the
                      interface which is provided by Company for use in
                      accessing our Services.
                    </p>
                    <h4>WARRANTY DISCLAIMERS</h4>
                    <p>
                      YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:
                      <br />
                      a) THE USE OF COMPANY SERVICES AND SOFTWARE ARE
                      AT THE SOLE RISK BY YOU. OUR SERVICES AND SOFTWARE SHALL
                      BE PROVIDED ON AN "AS IS" AND/OR "AS AVAILABLE" BASIS.
                      COMPANY AND OUR SUBSIDIARIES, AFFILIATES,
                      OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
                      EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND
                      WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED
                      TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                      <br />
                      b) COMPANY AND OUR SUBSIDIARIES, OFFICERS,
                      EMPLOYEES, AGENTS, PARTNERS AND LICESNSORS MAKE NO SUCH
                      WARRANTIES THAT (i) COMPANY SERVICES OR
                      SOFTWARE WILL MEET YOUR REQUIREMENTS; (ii) SHIMMER MEDIA,
                      LLC SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED, TIMELY,
                      SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS WHICH MAY BE
                      OBTAINED FROM THE USE OF THE COMPANY SERVICES
                      OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF
                      ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL
                      WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR
                      SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v)
                      THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE
                      CORRECTED.
                      <br />
                      c) ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE
                      OBTAIN BY WAY OF COMPANY SERVICES OR SOFTWARE
                      SHALL BE ACCESSED BY YOUR SOLE DISCRETION AND SOLE RISK,
                      AND AS SUCH YOU SHALL BE SOLELY RESPONSIBLE FOR AND HEREBY
                      WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT
                      TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS,
                      DOWNLOADING AND/OR DISPLAYING, OR FOR ANY LOSS OF DATA
                      THAT COULD RESULT FROM THE DOWNLOAD OF ANY SUCH
                      INFORMATION OR MATERIAL.
                      <br />
                      d) NO ADVICE AND/OR INFORMATION, DESPITE WETHER WRITTEN OR
                      ORAL, THAT MAY BE OBTAINED BY YOU FROM COMPANY
                      OR BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE
                      ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS.
                      <br />
                      e) A SMALL PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME
                      DEGREE OF EPILEPTIC SEIZURE WHEN EXPOSED TO CERTAIN LIGHT
                      PATTERNS OR BACKGROUNDS THAT MAY BE CONTAINED ON A
                      COMPUTER SCREEN OR WHILE USING OUR SERVICES. CERTAIN
                      CONDITIONS MAY INDUCE A PREVIOUSLY UNKNOWN CONDITION OR
                      UNDETECTED ELILEPTIC SYMPTOM IN USERS WHO HAVE SHOWN NO
                      HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY. SHOULD YOU,
                      ANYONE YOU KNOW OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC
                      CONDITION, PLEASE CONSULT A PHYSICAN IF YOU EXPERIENCE ANY
                      OF THE FOLLOWING SYMPTOMS WHILE USING OUR SERVICES:
                      DIZZINESS, ALTERED VISON, EYE OR MUSCLE TWITCHES, LOSS OF
                      AWARNESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR
                      CONVULSIONS.
                    </p>
                    <h4>LIMITATION OF LIABILITY</h4>
                    <p>
                      YOU EXPLICITY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT
                      COMPANY AND OUR SUBISDIARIES, AFFILIATES,
                      OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL
                      NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT,
                      INCIDENTIAL, SPECIAL, CONSEQUENIAL OR EXEMPLARY DAMAGES,
                      INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH MAY BE
                      RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA
                      AND/OR OTHER INTANGILBE LOSSES, EVEN THOUGH WE MAY HAVE
                      BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY
                      OCCUR, AND RESULT FROM:
                      <br />
                      a) THE USE OR INABILITY TO USE OUR SERVICE;
                      <br />
                      b) THE COST OF PRECURING SUBSTITUTE GOODS AND SERVICES;
                      <br />
                      c) UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR
                      TRANSMISSIONS AND/OR DATA;
                      <br />
                      d) STATEMENTS OR CONDUCT OF ANY SUCH THRID PARTY ON OUR
                      SERVICE;
                      <br />
                      e) AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR
                      SERVICE.
                    </p>
                    <h4>EXCLUSION AND LIMITATIONS</h4>
                    <p>
                      THERE ARE SOME JURISDICTIONS WHICH DO NOT ALLOW THE
                      EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OF
                      EXCLUSION OF LAIBILITY FOR INCIDENTAL OR CONSEQUENTIAL
                      DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS OF
                      SECTIONS WARRANTY DISCLAIMERS AND LIMITATION OF LIABILITY
                      MAY NOT APPLY TO YOU.
                    </p>
                    <h4>THIRD PARTY BENEFICIARIES</h4>
                    <p>
                      You herein acknowledge, understand and agree, unless
                      otherwise expressly provided in this TOS, that there shall
                      be third-party beneficiaries to this agreement.
                    </p>
                    <h4>NOTICE</h4>
                    <p>
                      Company may furnish you with notices, including those with
                      regards to any changes to the TOS, including but not
                      limited to email, regular mail, MMS or SMS, text
                      messaging, postings on our Services, or other reasonable
                      means currently known or any which may be herein after
                      developed. Any such notices may not be received if you
                      violate any aspects of the TOS by accessing our Services
                      in an unauthorized manner. Your acceptance of this TOS
                      constitutes your agreement that you are deemed to have
                      received any and all notices that would have been
                      delivered had you accessed our Services in an authorized
                      manner.
                    </p>
                    <h4>TRADEMARK INFORMATION</h4>
                    <p>
                      You herein acknowledge, understand and agree that all of
                      the Company trademarks, copyright, trade name, service
                      marks, and other Company logos and any brand features,
                      and/or product and service names are trademarks and as
                      such, are and shall remain the property of Company. You
                      herein agree not to display and/or use in any manner the
                      Company logo or marks.
                    </p>
                    <h4>
                      COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
                      NOTICE AND PROCEDURES
                    </h4>
                    <p>
                      Company will always respect the intellectual property of
                      others, and we ask that all of our users to the same. With
                      regards to appropriate circumstances and at its sole
                      discretion, Company may disable and/or terminate the
                      accounts of any Member who repeatedly violates our TOS
                      and/or infringes the rights of others. If you feel that
                      your work has been duplicated in such a way that would
                      constitute copyright infringement, or if you believe your
                      intellectual property rights have been otherwise violated,
                      you should provide to us the following information:
                      <br />
                      a) The electronic or the physical signature of the
                      individual that is authorized on behalf of the owner of
                      the copyright or other intellectual property interest;
                      <br />
                      b) A description of the copyrighted work or other
                      intellectual property that you believe has been infringed
                      upon;
                      <br />
                      c) A description of the location of the site which you
                      allege has been infringing upon your work;
                      <br />
                      d) The physical address, telephone number, and email
                      address;
                      <br />
                      e) A statement, in which you state that the alleged and
                      disputed use of your work is not authorized by the
                      copyright owner, its agents or the law;
                      <br />
                      f) And finally, a statement, made under penalty of
                      perjury, that the aforementioned information in your
                      notice is the truth and accurate, and that you are the
                      copyright or intellectual property owner, representative
                      or agent authorized to act on the copyright or
                      intellectual property owner's behalf.
                    </p>
                    <p>
                      The Company Agent for notice of claims of copyright or
                      other intellectual property infringement can be contacted
                      using the contact form at https://harmonize.social/Contact.
                    </p>
                    <h4>GENERAL INFORMATION</h4>
                    <p>
                      ENTIRE AGREEMENT
                      <br />
                      This TOS constitutes the entire agreement between you and
                      Company and shall govern the use of our Services,
                      superseding any prior version of this TOS between you and
                      us with respect to Company Services. You may also be
                      subject to additional terms and conditions that may apply
                      when you use or purchase certain other Company Services,
                      affiliate Services, third-party content or third-party
                      software.
                      <br />
                      <br />
                      CHOICE OF LAW AND FORUM
                      <br />
                      It is at the mutual agreement of both you and Company with
                      regard to the TOS that the relationship between the
                      parties shall be governed by the laws of the state of New
                      Mexico without regard to its conflict of law provisions
                      and that any and all claims, causes of action and/or
                      disputes, arising out of or relating to the TOS, or the
                      relationship between you and Company, shall be filed
                      within the courts having jurisdiction within the County of
                      Otero, New Mexico or the U.S. District Court located
                      in said state. You and Company agree to submit to the
                      jurisdiction of the courts as previously mentioned, and
                      agree to waive any and all objections to the exercise of
                      jurisdiction over the parties by such courts and to venue
                      in such courts.
                      <br />
                      <br />
                      WAIVER AND SEVERABILITY OF TERMS
                      <br />
                      At any time, should Company fail to exercise or enforce
                      any right or provision of the TOS, such failure shall not
                      constitute a waiver of such right or provision. If any
                      provision of this TOS is found by a court of competent
                      jurisdiction to be invalid, the parties nevertheless agree
                      that the court should endeavor to give effect to the
                      parties' intentions as reflected in the provision, and the
                      other provisions of the TOS remain in full force and
                      effect.
                      <br />
                      <br />
                      NO RIGHT OF SURVIVORSHIP NON-TRANSFERABILITY
                      <br />
                      You acknowledge, understand and agree that your account is
                      non-transferable and any rights to your ID and/or contents
                      within your account shall terminate upon your death. Upon
                      receipt of a copy of a death certificate, your account may
                      be terminated and all contents therein permanently
                      deleted.
                      <br />
                      <br />
                      STATUTE OF LIMITATIONS
                      <br />
                      You acknowledge, understand and agree that regardless of
                      any statute or law to the contrary, any claim or action
                      arising out of or related to the use of our Services or
                      the TOS must be filed within 2 year(s) after said claim or
                      cause of action arose or shall be forever barred.
                    </p>
                    <h4>VIOLATIONS</h4>
                    <p>
                      Please report any and all violations of this TOS to
                      Company using the contact form at https://harmonize.social/Contact.

                    </p>
                  </p>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer position='relative' />
    </div>
  )
}

import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  fullscreenPlayer: false,
  showHeader: true,
  truemusic: false,
  trueplace: false,
  truetrail: false,
  transactionInProgress: false,
}

const hostTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.DISPLAY.FULLSCREEN_PLAYER:
      return {
        ...state,
        fullscreenPlayer: action.fullscreenPlayer,
      }
    case REDUX_TYPES.DISPLAY.SHOW_HEADER:
      return {
        ...state,
        showHeader: action.showHeader,
      }
    case REDUX_TYPES.DISPLAY.TRUEMUSIC:
      return {
        ...state,
        truemusic: action.truemusic,
      }
    case REDUX_TYPES.DISPLAY.TRUEPLACE:
      return {
        ...state,
        trueplace: action.trueplace,
      }
    case REDUX_TYPES.DISPLAY.TRUETRAIL:
      return {
        ...state,
        truetrail: action.truetrail,
      }

    case REDUX_TYPES.DISPLAY.TRANSACTION_IN_PROGRESS:
      return {
        ...state,
        transactionInProgress: action.transactionInProgress,
      }
    default:
      return state
  }
}
export default hostTypeReducer

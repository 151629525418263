import moment from 'moment'

//See https://developers.google.com/search/docs/crawling-indexing/overview-google-crawlers
const GOOGLE_BOT_AGENTS = ['googlebot', 'google-inspectiontool', 'storebot-google']

/**
 * 
 * @param {*} ua  window.navigator.userAgent
 * @returns 
 */
export const isGooglebot = (ua) => {
  const userAgent = ua.toLowerCase()
  return GOOGLE_BOT_AGENTS.findIndex(g => userAgent.includes(g)) !== -1
}

export const narrowMedia = (mediaQuery) => {
  return mediaQuery('(max-width:800px)')
}

export const isPortrait = (mediaQuery) => {
  return mediaQuery('(orientation:portrait)')
}
/**
 *
 * @param {*} mediaQuery A reference to the useMediaQuery hook declared in a component
 * @returns
 */
export const isNarrow = (mediaQuery) => {
  return mediaQuery('(max-width:900px)')
}

export const isVeryNarrow = (mediaQuery) => {
  return mediaQuery('(max-width:450px)')
}

/**
 * If error contains a message, use it; otherwise stringify the error.
 * If code is provided, append it. Then call setError and display
 * the message to console.error.
 * 
 * @param {*} error {message}
 * @param {*} setError 
 * @param {*} code     Optional
 */
export const displayError = (error, setError, code) => {
  const { message } = error
  let errMessage = message ? message : JSON.stringify(error)
  if (code) {
    errMessage += ` (${code})`
  }
  setError(errMessage)
  console.error(errMessage)
}

export const getListens = (listens) => {
  if (listens) {
    return (
      <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}>[{listens}]</span>
    )
  } else {
    return null
  }
}

export const logWithTime = (message, data) => {
  if (data) {
    console.log(`${moment(Date.now()).format('MM/DD/YYYY, h:mm a')} ${message}`, data)
  } else {
    console.log(`${moment(Date.now()).format('MM/DD/YYYY, h:mm a')} ${message}`)
  }
}

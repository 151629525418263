import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Footer from 'components/Footer/Footer.js'
import styles from 'styles/pricingPage'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { getStorageAccount } from 'redux/actions/accountActions'
import { Container } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import MessageJsx from 'components/harmonize/MessageJsx'

const useStyles = makeStyles(styles)

export default function AboutStudios() {
  const account = getStorageAccount()
  const classes = useStyles()
  const narrow = useMediaQuery('(max-width:400px)')
  const containerClasses = narrow
    ? classNames(classes.main)
    : classNames(classes.main, classes.mainRaised)
  useEffect(() => {
    document.title = 'About Studios'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>
          <MessageJsx messageId='66fb2bacf81fa131e2b257bb' />
        </Container>
      </div>
      <Footer />
    </div >
  )
}

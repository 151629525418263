import { Grid, useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setShowCollections } from 'redux/actions/harmonizeActions'
import { isVeryNarrow } from 'util/screenUtils'
import PlaylistCard from './PlaylistCard'

export default function Playlists({ setCollectionPlaylist }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const { collections } = useSelector(state => state.harmonize)
    const dispatch = useDispatch()
    const listCollections = () => {
        return (
            <Grid container spacing={veryNarrow ? 2 : 3} style={{ width: '100%', paddingTop: '1em', margin: 0 }}  >
                {collections.map(c =>
                    <Grid key={c.name} item xs style={{ display: 'flex', justifyContent: 'center' }} onClick={async () => {
                        setCollectionPlaylist(c)
                        dispatch(setShowCollections(false))
                    }}><PlaylistCard collection={c} /></Grid>
                )}
            </Grid>
        )
    }

    return listCollections()
}
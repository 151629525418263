import React, { useState } from 'react'
import parse from 'html-react-parser'

import { useMediaQuery } from '@material-ui/core'
import { isVeryNarrow } from 'util/screenUtils'
import { useEffect } from 'react'
import { getMessage } from 'controllers/VortexController'
import { replaceNewLine } from 'util/postUtils'
import { displayError } from 'util/screenUtils'
import ErrorLine from '../ErrorLine'


export default function MessageJsx({ messageId, image }) {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [error, setError] = useState()
  const [requestedMessage, setRequestedMessage] = useState()


  /** If the outer div has display:flex, then content will be split into columns and it does not wrap around
   * the image.
   */
  const displayWithImage = (cardDescription, cardSummary, cardTitle) => {
    return (
      <div style={{ lineHeight: '1.5em', alignItems: 'center', textAlign: 'left' }}>
        <img src={image} alt={cardTitle} style={{ width: veryNarrow ? '25%' : '15%', float: 'left', marginRight: '2em' }} />
        {cardDescription}{cardSummary}

      </div>

    )
  }

  const displayTextOnly = (cardDescription, cardSummary) => {
    return (
      <div style={{ textAlign: 'left' }}>
        {cardDescription} {cardSummary}
      </div>
    )
  }
  const displayRequestedMessage = () => {
    if (requestedMessage) {
      const { name, message, summary } = requestedMessage
      const jsx = parse(replaceNewLine(message))
      const jsxSummary = parse(replaceNewLine(summary))
      return (
        <div>
          <ErrorLine error={error} />
          <h3 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: veryNarrow ? '1em' : '1.5em', borderBottom: '1px solid gray' }}>{name}</h3>
          {image ? displayWithImage(jsx, jsxSummary, name)
            : displayTextOnly(jsx, jsxSummary)}
        </div>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    const getRequestedMessage = async (mid) => {
      try {
        const msg = await getMessage(mid)
        setRequestedMessage(msg)
      } catch (error) {
        displayError(error, setError)
      }
    }
    if (messageId) {
      getRequestedMessage(messageId)
    }
  }, [])

  return displayRequestedMessage()


}

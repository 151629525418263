import { handleErrors } from './AssetController'


/**
 * 
 * @param {*} export const getShareableContent = async (contentUrl,accessToken) => {
 
 * @param {*} accessToken 
 * @returns 
 */
export const getShareableContent = async (contentUrl, accessToken) => {
    console.log(`getShareableContent from ${contentUrl}`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}vortex/getShareableContent`
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': accessToken,
        },
        body: JSON.stringify({ contentUrl })
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((shareable) => {
            return shareable
        })
}
import vortexIcon from 'assets/img/vortex-icon.png'

export const getBrandName = () => {
  return 'Harmonize'

}

export const getBrandIcon = () => {
  return vortexIcon
}

/**
 * Display the home icon with zIndex of 3000 so it is always clickable over everything
 * @param {*} history useHistory reference for going to /
 * @returns 
 */
export const displayHomeIcon = (history) => {

  return (
    <div style={{ cursor: 'pointer', zIndex: 3000 }} title={`Go to ${getBrandName()} home page`} onClick={() => history.replace('/')}>

      <img
        alt={getBrandName()}
        src={getBrandIcon()}
        style={{
          height: '5vh'
        }}
      />
    </div>
  )
}

/**
 * Get the server-side hostPlatform corresponding to the current hostType
 */
export const getBrandHostPlatform = () => {

  return 0

}


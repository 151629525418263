import React, { useState } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../styles/editableTextField'
import { Edit, Save } from '@material-ui/icons'
import { useEffect } from 'react'

const useStyles = makeStyles(styles)
/**
 * If required is false, or there is a getter, then a Save button will be displayed.
 * When it is clicked, the updater method is called and editing ends.
 *
 * If noBlur is false, the TextField onBlur method acts like the Save button was clicked.
 * @param {*} props
 * @returns
 */
export default function EditableTextField(props) {
  const {
    fullWidth = true,
    label,
    multiline,
    getter,
    setter,
    updater,
    isEditing = false,
    required,
    noBlur,
    noLabel,
    ...rest
  } = props

  const [text, setText] = useState('')
  const [editing, setEditing] = useState(isEditing)

  const classes = useStyles()
  useEffect(() => {
    setText(getter())
  }, [getter()])
  const getSaveButton = () => {
    if (!required || getter()) {
      return (
        <Save
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (updater) {
              updater()
            }
            setEditing(false)
          }}
        />
      )
    } else {
      //Pass this empty div so you don't get the InputAdornment
      //has no children error
      return <div></div>
    }
  }
  return (
    <TextField
      {...rest}
      value={text}
      variant={editing ? 'outlined' : 'standard'}
      disabled={!editing}
      onChange={(event) => setter(event.target.value)}
      onBlur={() => {
        if (!noBlur) {
          if (updater) {
            updater()
          }
          setEditing(false)
        }
      }}
      InputProps={{
        disableunderline: 'true',
        classes: {
          root: classes.edited,
          disabled: classes.displayed,
        },
        startAdornment: (
          <InputAdornment position='start'>
            {editing ? (
              getSaveButton()
            ) : (
              <Edit
                fontSize='small'
                onClick={() => setEditing(true)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </InputAdornment>
        ),
      }}
      fullWidth={fullWidth}
      multiline={multiline}
      placeholder={!getter() ? label : null}
      label={editing && getter() ? label : null}
    />
  )
}

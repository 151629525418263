import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Footer from 'components/Footer/Footer.js'
import harmony from '../images/harmony-small.jpg'

import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

export default function ConfirmEmailAddress(props) {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    document.title = 'Check Your Email'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div className={classes.profile}>
        <div
          style={{
            cursor: 'pointer', height: '10em',
            overflow: 'hidden'
          }}
          onClick={() => history.replace('/SignIn')}
        >
          <img src={harmony} alt='Harmonize' />
        </div>
        <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          <h4>Please Check Your Email</h4>
          <p>
            We have sent a confirmation email to the address you
            provided. The subject line is "Verify your email for Harmonize". Please use the link in that email to confirm
            your account before continuing. If you don't see the email,
            check your Spam folder. Thank you 🎶
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

import { textColor } from '../assets/jss/material-kit-react.js'

const editableTextFieldStyle = {
  displayed: {
    color: textColor,
    opacity: 1,
    fontWeight: 400,
    fontFamily: 'Raleway',
  },
  edited: {
    color: textColor,
    opacity: 1,
    fontWeight: 400,
    fontFamily: 'Courier',
  },
}

export default editableTextFieldStyle

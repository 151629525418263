import { handleErrors } from './AssetController'
export const sendContactEmail = async (firstName, lastName, email, message) => {
  return fetch(`${process.env.REACT_APP_CORE_SERVICE}contact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ firstName, lastName, email, message }),
  })
    .then(handleErrors)
    .then((res) => {
      return res
    })
}

/**
 * Send an email to the user identified by to. Authentication is required.
 * @param {*} firstName
 * @param {*} lastName
 * @param {*} email
 * @param {*} message
 * @param {*} to
 * @param {*} accessToken
 * @returns
 */
export const sendEmailToUser = async (
  firstName,
  lastName,
  email,
  message,
  to,
  accessToken
) => {
  const data = { firstName, lastName, email, message, to }
  return fetch(`${process.env.REACT_APP_CORE_SERVICE}contact/user`, {
    method: 'POST',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(handleErrors)
    .then((res) => {
      return res
    })
}

export const sendShareEmailToUser = async (
  message,
  sendTo,
  accessToken
) => {
  const data = { message, sendTo }
  return fetch(`${process.env.REACT_APP_CORE_SERVICE}contact/shareRequest`, {
    method: 'POST',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(handleErrors)
    .then((res) => {
      return res
    })
}

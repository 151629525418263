import React, { useEffect, useState } from 'react'
import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { DataGrid } from '@mui/x-data-grid'
import { getAccruedCredits } from 'controllers/HarmonizeController'
import { getMessage } from 'controllers/VortexController'
import { isVeryNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import { displayError } from 'util/screenUtils'

export const ListRevenue = ({ chartSong }) => {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [loading, setLoading] = useState()
  const [accruedCredits, setAccruedCredits] = useState()
  const [error, setError] = useState('')
  const accessToken = getStorageAccessToken()
  const dataRows = []

  const columns = [
    { field: 'col1', headerName: 'Song', width: 250 },
    { field: 'col2', headerName: 'Credits', width: 150 }
  ]

  const displayLoadingCredits = () => {
    return (
      <div style={{ width: '100%' }}>
        <h4>Loading your accrued Credits...</h4>
        <TimerProgress />
      </div>
    )
  }

  const displayAccruedCredits = () => {

    if (accruedCredits) {
      if (accruedCredits.length) {
        try {
          const artistRate = parseFloat(process.env.REACT_APP_ARTIST_CREDIT_RATE)
          let total = 0
          accruedCredits.forEach(rev => {
            const { count, messageId, message } = rev
            const fractionalCredit = count / 5
            const { name } = message
            const row = { id: messageId, col1: name, col2: Intl.NumberFormat('en-US', { minimumIntegerDigits: 3, minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(fractionalCredit) }
            dataRows.push(row)
            total += parseFloat(fractionalCredit)
          })
          return (
            <div style={{ height: '50vh', width: '100%' }}>
              Total Credits: {Intl.NumberFormat('en-US', { minimumFractionDigits: 1 }).format(total)}
              <br />
              Total Revenue: {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(total * artistRate)}
              <p style={{ fontSize: '0.75em', fontStyle: 'italic' }}>Click a song title to view daily song credits</p>
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'col2', sort: 'desc' }],
                  },
                }}
                onRowClick={(event) => chartSong(event.id, event.row.col1)}
                rows={dataRows} columns={columns}
                sx={{ cursor: 'pointer', fontWeight: '600', color: 'black', fontSize: veryNarrow ? '0.75em' : '1em' }} />

            </div>
          )
        } catch (error) {
          displayError(error, setError)
        }
      } else {
        return (
          <h4>You do not have any accrued credits</h4>
        )
      }
    } else {
      return null
    }


  }

  const getRelatedMessage = async (credit) => {
    const { count, messageId } = credit
    let name = 'Missing song'
    try {
      const message = await getMessage(messageId)
      name = message.name
    } catch (error) {
      console.error(`Missing song for ${messageId}`)
    }
    return { count, messageId, message: { name } }

  }
  /**
   * Get the Messages to which the Credits refer.
   * @todo Use a lighter endpoint that just gets the name
   * @param {*} artistCredits 
   * @returns 
   */
  const getRelatedMessages = async (artistCredits) => {
    console.log('getRelatedMessages', artistCredits)
    //const creditsMap = Object.keys(artistCredits).map((key) => [{ messageId: key, credits: artistCredits[key] }])
    return Promise.all(
      artistCredits.map(async (ac) => getRelatedMessage(ac))
    )
  }

  useEffect(() => {
    const listRevenue = async () => {
      try {
        setError()
        setLoading(true)
        const artistCredits = await getAccruedCredits(accessToken)
        const actualCredits = await getRelatedMessages(artistCredits)
        setLoading(false)
        setAccruedCredits(actualCredits)
      } catch (error) {
        setLoading(false)
        console.error(error)
        displayError(error.message, setError)
      }
    }
    listRevenue()
  }, [accessToken])

  return (

    <div>
      <ErrorLine error={error} />

      {loading && displayLoadingCredits()}
      {displayAccruedCredits()}

    </div>

  )
}

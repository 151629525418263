import { container, title, textColor } from 'assets/jss/material-kit-react.js'

import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.js'

const profilePageStyle = {
  container: {
    zIndex: '12',
    //color: '#FFFFFF',
    ...container,
  },
  displayed: {
    color: textColor,
    opacity: 1,
    fontWeight: 400,
    fontFamily: 'Raleway',
  },
  edited: {
    color: textColor,
    opacity: 1,
    fontWeight: 400,
    fontFamily: 'Courier',
  },
  profileImageContainer: {
    borderRadius: '50%',
    height: '10em',
    width: '10em',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  profileImageContainerNarrow: {
    borderRadius: '50%',
    height: '5em',
    width: '5em',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  profileImage: {
    width: 'auto',
    height: '100%',
    margin: '0 auto',
  },
  profile: {
    textAlign: 'center',

    '& img': {
      height: '100%',
      width: 'auto',
      margin: '0 auto',
      //transform: 'translate3d(0, -50%, 0)',
    },
  },
  description: {
    margin: '1.071rem auto 0',
    maxWidth: '600px',
  },
  name: {
    marginTop: '-80px',
  },
  ...imagesStyle,
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
    paddingBottom: '15px',
  },
  mainRaised: {
    margin: '-00px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  headerTitle: {
    ...title,
    display: 'inline-block',
    position: 'relative',

    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  socials: {
    marginTop: '0',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    lineHeight: '41px',
    fontSize: '20px',
    color: '#999',
  },
  navWrapper: {
    margin: '20px auto 50px auto',
    textAlign: 'center',
  },
  uploadText: {
    fontSize: '1em',
  },
}

export default profilePageStyle

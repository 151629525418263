import * as REDUX_TYPES from '../types'

export const setSongCreatedAt = (createdAt) => {
  return {
    type: REDUX_TYPES.SONG.CREATED_AT,
    createdAt,
  }
}
export const setSongDescription = (description) => {
  return {
    type: REDUX_TYPES.SONG.DESCRIPTION,
    description,
  }
}

export const setSongGenre = (genre) => {
  return {
    type: REDUX_TYPES.SONG.GENRE,
    genre,
  }
}

export const setSongImage = (image) => {
  return {
    type: REDUX_TYPES.SONG.IMAGE,
    image,
  }
}

export const setSongLyrics = (lyrics) => {
  return {
    type: REDUX_TYPES.SONG.LYRICS,
    lyrics,
  }
}

export const setSongMedia = (media) => {
  return {
    type: REDUX_TYPES.SONG.MEDIA,
    media,
  }
}

export const setSongMediaIcons = (mediaIcons) => {
  return {
    type: REDUX_TYPES.SONG.MEDIA_ICONS,
    mediaIcons,
  }
}

export const setSongMp3 = (mp3) => {
  return {
    type: REDUX_TYPES.SONG.MP3,
    mp3,
  }
}

export const setSongSummary = (summary) => {
  return {
    type: REDUX_TYPES.SONG.SUMMARY,
    summary,
  }
}

export const setSongTitle = (title) => {
  return {
    type: REDUX_TYPES.SONG.TITLE,
    title,
  }
}
export const setSongUpdatedAt = (updatedAt) => {
  return {
    type: REDUX_TYPES.SONG.UPDATED_AT,
    updatedAt,
  }
}
export const resetSong = () => {
  return {
    type: REDUX_TYPES.SONG.RESET
  }
}

import { INITIAL_STATE } from 'redux/reducers/settingsReducer'
import * as REDUX_TYPES from '../types'

export const getStorageMainMap = () => {
  const mainMap = localStorage.getItem(REDUX_TYPES.SETTINGS.SET_MAINMAP)
  if (mainMap) {
    //console.log('getStorageMainMap', mainMap)
    return JSON.parse(mainMap)
  }
  return INITIAL_STATE.mainMap
}
export const storeMainMap = (mainMap) => {
  //console.log('storeMainMap', mainMap)
  localStorage.setItem(
    REDUX_TYPES.SETTINGS.SET_MAINMAP,
    JSON.stringify(mainMap)
  )
}

export const getStorageSettings = () => {
  const settings = localStorage.getItem(REDUX_TYPES.SETTINGS.SET)
  if (settings) {
    return JSON.parse(settings)
  }
  return INITIAL_STATE
}

/**
 * Get the localStorage settings and return it as a {type,settings} dispatch action
 * @returns
 */
export const reloadReduxSettings = () => {
  const settings = getStorageSettings()
  return setSettings(settings)
}

export const storeSettings = (settings) => {
  localStorage.setItem(REDUX_TYPES.SETTINGS.SET, JSON.stringify(settings))
}

export const setSettingsCurrency = (currency) => {
  storeSettings({ ...getStorageSettings(), currency })
  return {
    type: REDUX_TYPES.SETTINGS.CURRENCY,
    currency,
  }
}
export const setMapCenter = (mapCenter) => {
  return {
    type: REDUX_TYPES.SETTINGS.MAP_CENTER,
    mapCenter,
  }
}

export const setMapTypeId = (mapTypeId) => {
  return {
    type: REDUX_TYPES.SETTINGS.MAP_TYPE_ID,
    mapTypeId,
  }
}
export const setMapZoom = (mapZoom) => {
  return {
    type: REDUX_TYPES.SETTINGS.MAP_ZOOM,
    mapZoom,
  }
}
export const setMainMap = (mainMap) => {
  storeMainMap(mainMap)
  return {
    type: REDUX_TYPES.SETTINGS.SET_MAINMAP,
    mainMap,
  }
}

export const setMainMapCenter = (mapCenter) => {
  //console.log('setMainMapCenter', mapCenter)
  storeMainMap({ ...getStorageMainMap(), center: mapCenter })

  return {
    type: REDUX_TYPES.SETTINGS.MAINMAP.CENTER,
    mapCenter,
  }
}

export const setMainMapTypeId = (mapTypeId) => {
  storeMainMap({ ...getStorageMainMap(), typeId: mapTypeId })
  return {
    type: REDUX_TYPES.SETTINGS.MAINMAP.TYPE_ID,
    mapTypeId,
  }
}
export const setMainMapZoom = (mapZoom) => {
  //console.log(`setMainMapZoom ${mapZoom}`)
  storeMainMap({ ...getStorageMainMap(), zoom: mapZoom })
  return {
    type: REDUX_TYPES.SETTINGS.MAINMAP.ZOOM,
    mapZoom,
  }
}


export const setSettings = (settings) => {
  return {
    type: REDUX_TYPES.SETTINGS.SET,
    settings,
  }
}

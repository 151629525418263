import { getStorageAccount } from 'redux/actions/accountActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'

export const isAdminUser = () => {
    const accessToken = getStorageAccessToken()
    const account = getStorageAccount()
    if (accessToken && account) {
        const { isAdmin } = account
        return isAdmin
    } else {
        return false
    }
}
import * as REDUX_TYPES from '../types'

export const getStorageAccount = () => {
  const account = localStorage.getItem(REDUX_TYPES.ACCOUNT.SET)
  if (account) {
    //console.log('getStorageAccount', account)
    return JSON.parse(account)
  }
  return null
}
/**
 * Get the localStorage account and return it as a {type,account} dispatch action
 * @returns
 */
export const reloadReduxAccount = () => {
  const account = getStorageAccount()
  return setAccount(account)
}

export const storeAccount = (account) => {
  if (account) {
    localStorage.setItem(REDUX_TYPES.ACCOUNT.SET, JSON.stringify(account))
  } else {
    localStorage.removeItem(REDUX_TYPES.ACCOUNT.SET)
  }
}
export const getStorageAccessToken = () => {
  return localStorage.getItem(REDUX_TYPES.ACCOUNT.ACCESS_TOKEN)
}
export const storeAccessToken = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(REDUX_TYPES.ACCOUNT.ACCESS_TOKEN, accessToken)
  } else {
    localStorage.removeItem(REDUX_TYPES.ACCOUNT.ACCESS_TOKEN)
  }
}

export const setAccessToken = (accessToken) => {
  //console.log('setAccessToken', accessToken)
  storeAccessToken(accessToken)
  return {
    type: REDUX_TYPES.ACCOUNT.ACCESS_TOKEN,
    accessToken,
  }
}
export const setAccount = (account) => {
  console.log('setAccount', account)
  storeAccount(account)
  return {
    type: REDUX_TYPES.ACCOUNT.SET,
    account,
  }
}

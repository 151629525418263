import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import factory from 'redux/store'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

import './assets/scss/material-kit-react.scss?v=1.9.0'

import { PersistGate } from 'redux-persist/integration/react'
if (process.env.REACT_APP_MODE !== 'development') {
  console.log = () => { }
  console.error = () => { }
  //This does not disable the Chrome console
  disableReactDevTools()
}
const { store, persistor } = factory()
const container = document.getElementById('root')
const root = createRoot(container)


root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App store={store} />
    </PersistGate>
  </Provider>
)

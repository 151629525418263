import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import stripe from 'images/stripe.png'
import {
    Elements,
} from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import harmony from '../../images/harmony.jpg'
import { getPaymentIntent } from 'controllers/StripeController'

import { getStorageAccessToken } from 'redux/actions/accountActions'
import CheckoutForm from './CheckoutForm'
import Footer from 'components/Footer/Footer'
import { getStorageAccount } from 'redux/actions/accountActions'
import ErrorLine from 'components/ErrorLine'
import { TRANSACTION_TYPE } from 'util/transactionUtils'
import { useDispatch } from 'react-redux'
import { setTransactionType } from 'redux/actions/purchaseActions'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { getCreditPricing } from 'controllers/HarmonizeController'
import { displayError } from 'util/screenUtils'
import { Link } from 'react-router-dom'
import { CheckBoxOutlineBlank } from '@material-ui/icons'

/* 
   NOTE: Be sure to use the correct "Publishable key" from developer "API Keys" for test versus production
   
   Make sure to call `loadStripe` outside of a component’s render to avoid
   recreating the `Stripe` object on every render.
*/
const stripePromise = loadStripe(process.env.REACT_APP_TEST_PAYMENTS === 'true' ? process.env.REACT_APP_TEST_STRIPE_KEY : process.env.REACT_APP_STRIPE_KEY)

export default function BuyCredits() {
    const pricingStyles = {
        pricingLine: { display: 'flex', flex: 1, width: '50%', marginLeft: 'auto', marginRight: 'auto', alignItems: 'center' }
    }
    const [clientSecret, setClientSecret] = useState()

    const [error, setError] = useState()
    const [pricing, setPricing] = useState()
    const [numCreditsPurchased, setNumCreditsPurchased] = useState(0)

    const accessToken = getStorageAccessToken()
    const account = getStorageAccount()
    const { id: userId } = account
    const dispatch = useDispatch()
    const getDisplayedPrice = (price) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(price / 100)
    }
    const displayOrderForm = () => {
        if (clientSecret) {
            const currentPricing = pricing.find(p => p.numCredits === numCreditsPurchased)
            const { numCredits, price } = currentPricing
            return (
                <div>
                    Buy {numCredits} credits for {getDisplayedPrice(price)}
                    <Elements stripe={stripePromise} options={{ clientSecret }} >
                        <CheckoutForm />
                    </Elements>
                    <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', width: '80%', justifyContent: 'center' }}>
                        <img alt='Stripe' src={stripe} style={{ height: '5vh' }} />
                    </div>
                </div>
            )
        }
    }

    const displayPricingHeader = () => {
        return (
            <div style={pricingStyles.pricingLine}>
                <span style={{ flex: 0.5 }}>Price (USD)</span>
                <span style={{ flex: 0.5 }}>Credits</span>
            </div>
        )
    }
    const displayPricingLine = (p, ix) => {
        const { price, numCredits } = p
        return (
            <div key={ix} style={pricingStyles.pricingLine} onClick={() => setNumCreditsPurchased(numCredits)}>
                <div title={`Click to purchase ${numCredits} credits`} style={{ display: 'flex', flex: 0.5, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}><CheckBoxOutlineBlank />{getDisplayedPrice(price)}</div>
                <span style={{ flex: 0.5 }}>{numCredits}</span>
            </div>
        )
    }
    const displayPricing = () => {
        if (pricing && !clientSecret) {
            return (
                <>
                    <h3>Buy Harmonize Credits</h3>
                    Click to select your package of credits. Each credit is good for 1 minute of streaming audio.< p > Find out more about credits < Link to='/Pricing' > here</Link >.</p >
                    <div style={{ border: '1px solid blue' }}>
                        {displayPricingHeader()}
                        {pricing.map((p, ix) => displayPricingLine(p, ix))}
                    </div>
                </>
            )
        }
    }
    const displayTestPayments = () => {
        return (
            <div>
                <h3>TEST PAYMENTS</h3>
                {clientSecret ? <div>
                    When you purchase credits on this page, please use
                    this credit card number:<br />
                    4242 4242 4242 4242
                    <p>
                        and an "Expiration" of:<br />
                        12/34
                    </p>
                    <p>When this transaction succeeds, your account will have additional streaming credits</p>
                    <p>Note: <i>when Harmonize starts using live payments, these credits will not be valid</i>.</p>
                </div> : <p>Harmonize is currently running in test payments mode.
                    This means all streaming credits are free! But you still need to use this page to "buy" them.</p>}
            </div>
        )
    }
    const displayMain = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                {process.env.REACT_APP_TEST_PAYMENTS === 'true' ? displayTestPayments()
                    : null}

                {displayPricing()}
                <br />
                {displayOrderForm()}
            </div>
        )
    }

    useEffect(() => {

        document.title = 'Buy Credits'
        getCreditPricing().then((result) => setPricing(result.pricing)).catch(error => displayError(error, setError))
    }, [])
    useEffect(() => {
        if (numCreditsPurchased) {
            console.log(`BuyCredits useEffect ${userId}`)
            getPaymentIntent({ transactionType: TRANSACTION_TYPE.CREDIT, userId, numCreditsPurchased }, accessToken).then((secret) => {
                setClientSecret(secret.clientSecret)
                dispatch(setTransactionType(TRANSACTION_TYPE.CREDIT))
            }).catch(error => displayError(error, setError))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numCreditsPurchased])

    return (
        <div>
            <HarmonizeHeader />

            <div

                style={{ paddingLeft: '1vw', paddingRight: '1vw', backgroundColor: 'white', width: '100%' }}
            >
                <ErrorLine error={error} />
                {displayMain()}
                <img alt='Harmonize' src={harmony} style={{ width: '100%' }} />

            </div>
            <Footer />
        </div >
    )

}

import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import styles from 'assets/jss/material-kit-react/views/profilePage.js'

import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { useEffect } from 'react'

const useStyles = makeStyles(styles)

export default function Privacy() {

  const classes = useStyles()
  useEffect(() => {
    document.title = 'Privacy'
  }, [])
  return (
    <div>
      <HarmonizeHeader />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GridContainer style={{ justifyContent: 'center' }}>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.description}>
                  <p>
                    This is the Privacy Policy of {process.env.REACT_APP_COMPANY_NAME} (Company). It is
                    Company’s  policy to respect your privacy regarding any
                    information we may collect while operating our websites.
                  </p>
                  <h4>Website Visitors</h4>
                  <p>
                    Like most website operators, Company collects
                    non-personally-identifying information of the sort that web
                    browsers and servers typically make available, such as the
                    browser type, language preference, referring site, and the
                    date and time of each visitor request. Company’s purpose in
                    collecting non-personally identifying information is to
                    better understand how Company’s visitors use its website.
                    From time to time, Company may release
                    non-personally-identifying information in the aggregate,
                    e.g., by publishing a report on trends in the usage of its
                    website.
                  </p>
                  <p>
                    Company also collects potentially personally-identifying
                    information like Internet Protocol (IP) addresses for logged
                    in users. Company only discloses logged in user IP addresses
                    under the same circumstances that it uses and discloses
                    personally-identifying information as described below.
                  </p>
                  <h4>Gathering of Personally-Identifying Information</h4>

                  <p>
                    Certain visitors to Company’s websites choose to interact
                    with Company in ways that require Company to gather
                    personally-identifying information. The amount and type of
                    information that Company gathers depends on the nature of
                    the interaction. For example, we ask visitors who sign up to
                    provide a username and email address. Those who engage in
                    transactions with Company – by purchasing prints, for
                    example – are asked to provide additional information,
                    including as necessary the personal and financial
                    information required to process those transactions. In each
                    case, Company collects such information only insofar as is
                    necessary or appropriate to fulfill the purpose of the
                    visitor’s interaction with Company. Company does not
                    disclose personally-identifying information other than as
                    described below. And visitors can always refuse to supply
                    personally-identifying information, with the caveat that it
                    may prevent them from engaging in certain website-related
                    activities.
                  </p>

                  <h4>Aggregated Statistics</h4>
                  <p>
                    Company may collect statistics about the behavior of
                    visitors to its websites. Company may display this
                    information publicly or provide it to others. However,
                    Company does not disclose personally-identifying information
                    other than as described below.
                  </p>

                  <h4>
                    Protection of Certain Personally-Identifying Information
                  </h4>
                  <p>
                    Company discloses potentially personally-identifying and
                    personally-identifying information only to those of its
                    employees, contractors and affiliated organizations that (i)
                    need to know that information in order to process it on
                    Company’s behalf or to provide services available at
                    Company’s websites, and (ii) that have agreed not to
                    disclose it to others. Some of those employees, contractors
                    and affiliated organizations may be located outside of your
                    home country; by using Company’s websites, you consent to
                    the transfer of such information to them. Company will not
                    rent or sell potentially personally-identifying and
                    personally-identifying information to anyone. Other than to
                    its employees, contractors and affiliated organizations, as
                    described above, Company discloses potentially
                    personally-identifying and personally-identifying
                    information only in response to a subpoena, court order or
                    other governmental request, or when Company believes in good
                    faith that disclosure is reasonably necessary to protect the
                    property or rights of Company, third parties or the public
                    at large. If you are a registered user of a Company website
                    and have supplied your email address, Company may
                    occasionally send you an email to tell you about new
                    features, solicit your feedback, or just keep you up to date
                    with what’s going on with Company and our products. We
                    primarily use our various product blogs to communicate this
                    type of information, so we expect to keep this type of email
                    to a minimum. If you send us a request (for example via a
                    support email or via one of our feedback mechanisms), we
                    reserve the right to publish it in order to help us clarify
                    or respond to your request or to help us support other
                    users. Company takes all measures reasonably necessary to
                    protect against the unauthorized access, use, alteration or
                    destruction of potentially personally-identifying and
                    personally-identifying information.
                  </p>
                  <h4>Cookies</h4>
                  <p>
                    A cookie is a string of information that a website stores on
                    a visitor’s computer, and that the visitor’s browser
                    provides to the website each time the visitor returns.
                    Company uses cookies to help Company identify and track
                    visitors, their usage of Company website, and their website
                    access preferences. Company visitors who do not wish to have
                    cookies placed on their computers should set their browsers
                    to refuse cookies before using Company’s websites, with the
                    drawback that most features of Company’s websites may not
                    function properly without the aid of cookies.
                  </p>

                  <h4>Business Transfers</h4>
                  <p>
                    If Company, or substantially all of its assets, were
                    acquired, or in the unlikely event that Company goes out of
                    business or enters bankruptcy, user information would be one
                    of the assets that is transferred or acquired by a third
                    party. You acknowledge that such transfers may occur, and
                    that any acquirer of Company may continue to use your
                    personal information as set forth in this policy.
                  </p>
                  <h4>Ads</h4>
                  <p>
                    Ads appearing on any of our websites may be delivered to
                    users by advertising partners, who may set cookies. These
                    cookies allow the ad server to recognize your computer each
                    time they send you an online advertisement to compile
                    information about you or others who use your computer. This
                    information allows ad networks to, among other things,
                    deliver targeted advertisements that they believe will be of
                    most interest to you. This Privacy Policy covers the use of
                    cookies by Company and does not cover the use of cookies by
                    any advertisers.
                  </p>
                  <h4>Privacy Policy Changes</h4>
                  <p>
                    Although most changes are likely to be minor, Company may
                    change its Privacy Policy from time to time, and in
                    Company’s sole discretion. Company encourages visitors to
                    frequently check this page for any changes to its Privacy
                    Policy. Your continued use of this site after any change in
                    this Privacy Policy will constitute your acceptance of such
                    change.
                  </p>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer position='relative' />
    </div>
  )
}

import React from 'react'

import AIChat from 'vortex/AIChat'
import { useParams } from 'react-router-dom'

export default function EmbedAI() {
  const { roomId } = useParams()
  return (

    <div style={{ backgroundColor: 'white', padding: '1em', height: '100%' }}>
      <AIChat hosted roomId={roomId} ownerPaysThoughts />
    </div>

  )
}

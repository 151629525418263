
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { getDisplayType } from 'redux/actions/displayActions'
import { getBrandHostPlatform } from 'util/brandUtils'
import { logWithTime } from 'util/screenUtils'

/**
 * If response is not ok, read the error response JSON, and then throw that JSON.
 * Otherwise, just return the response.
 * @param {*} response
 * @returns
 */
export const handleErrors = async (response) => {
  if (!response.ok) {
    const thing = await response.json()
    console.log('error', thing)
    throw thing
  }
  return response
}

/**
 * If response is not ok, read the error response JSON, and then throw that JSON
 * with the status code.
 * Otherwise, just return the response.
 * @param {*} response
 * @returns {errorRespose,status}
 */
export const handleErrorsWithCode = async (response) => {
  if (!response.ok) {
    const errorResponse = await response.json()
    console.log(`errorResponse status ${response.status}`, errorResponse)
    throw { errorResponse, status: response.status }
  }
  return response
}

/**
 * Add the Asset to the signed in user's favorites
 * @param {*} assetId
 * @param {*} accessToken
 * @returns
 */
export const addFavorite = async (assetId, accessToken) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}assets/favorite/${assetId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((updatedUser) => {
      return updatedUser
    })
}

/**
 * Remove the Asset from the signed in user's favorites
 * @param {*} assetId
 * @param {*} accessToken
 * @returns
 */
export const deleteFavorite = async (assetId, accessToken) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}assets/deleteFavorite/${assetId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((updatedUser) => {
      return updatedUser
    })
}
/**
 *
 * @param {*} assetId
 * @param {} username
 * @param {*} accessToken
 * @returns
 */
export const deleteAsset = async (assetId, username, accessToken) => {
  console.log(`deleteAsset ${assetId}`)
  return fetch(
    `${process.env.REACT_APP_CORE_SERVICE}assets/${assetId}/${username}/delete`,
    {
      method: 'GET',
      headers: {
        'x-access-token': accessToken,
      },
    }
  )
    .then(handleErrors)
    .then((response) => {
      return response
    })
}

export const deleteVortexAsset = async (assetId, accessToken) => {
  console.log(`deleteVortexAsset ${assetId}`)
  return fetch(
    `${process.env.REACT_APP_CORE_SERVICE}assets/${assetId}/deleteVortex`,
    {
      method: 'GET',
      headers: {
        'x-access-token': accessToken,
      },
    }
  )
    .then(handleErrors)
    .then((response) => {
      return response
    })
}

/**
 * Deletes the User's Asset with the specifed title unless it is already a token
 * @param {*} title 
 * @param {*} accessToken 
 * @returns 
 */
export const deleteAssetByTitle = async (title, hostPlatform, accessToken) => {
  console.log(`deleteAssetByTitle ${title}`)
  return fetch(
    `${process.env.REACT_APP_CORE_SERVICE}assets/deleteByTitle/${title}/${hostPlatform}`,
    {
      method: 'GET',
      headers: {
        'x-access-token': accessToken,
      },
    }
  )
    .then(handleErrors)
    .then((response) => {
      return response
    })
}
/**
 * Call this whenever a mint fails after the metadata (aka provenance) has been generaated.
 * @param {*} assetId
 * @param {*} accessToken
 * @returns
 */
export const deleteAssetMetadata = async (assetId, accessToken) => {
  console.log(`deleteAssetMetadata ${assetId}`)
  return fetch(
    `${process.env.REACT_APP_CORE_SERVICE}assets/deleteMetadata/${assetId}`,
    {
      method: 'GET',
      headers: {
        'x-access-token': accessToken,
      },
    }
  )
    .then(handleErrors)
    .then((response) => {
      return response
    })
}

/**
 * Delete the privilegedMedia associated with the Asset.
 * @param {*} assetId
 * @param {*} username
 * @param {*} accessToken
 * @returns
 */
export const deleteAssetPrivileged = async (assetId, username, accessToken) => {
  console.log(`deleteAssetPrivileged ${assetId}`)
  return fetch(
    `${process.env.REACT_APP_CORE_SERVICE}assets/${assetId}/${username}/deletePrivileged`,
    {
      method: 'GET',
      headers: {
        'x-access-token': accessToken,
      },
    }
  )
    .then(handleErrors)
    .then((response) => {
      return response
    })
}

/**
 * Gets the signed in user's work, which may be Albums or Assets.
 * @param {*} accessToken 
 * @param {*} hostPlatform 
 * @returns 
 */
export const getMyWork = async (hostPlatform, accessToken) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}users/myWork/${hostPlatform}`
  console.log(`getMyWork from ${url}`)
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((albums) => {
      return albums
    })
}

export const fetchRooms = async (ownerUsername, isOwner) => {
  logWithTime(`fetchRooms for ${ownerUsername} isOwner ${isOwner}`)
  const availableRooms = await getUserRooms(
    ownerUsername
  )
  console.log('rooms', availableRooms)
  const allowedRooms = isOwner ? availableRooms : availableRooms.filter(r => r.room.accessType !== ACCESS_TYPES.PRIVATE)
  allowedRooms.sort(function (a, b) {
    const textA = a.originalTitle.toUpperCase()
    const textB = b.originalTitle.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
  return allowedRooms
}

/**
 * Requires Api Key
 * This gets up to 50 user rooms.
 * @param {*} username 
 * @returns 
 */
const getUserRooms = async (username) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}rooms/${username}/0/50`
  console.log(`getUserRooms from ${url}`)
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((rooms) => {
      return rooms
    })
}

/**
 * This will get the Asset and the associated Room.
 *  ApiKey required
 * @param {*} assetParams {assetId} {title,user}
 * @returns 
 */
export const getAsset = async (assetParams) => {
  console.log(`getAsset`, assetParams)
  const url = `${process.env.REACT_APP_CORE_SERVICE}assets/asset`
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    body: JSON.stringify(assetParams)
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((asset) => {
      return asset
    })
}

/**
 *
 * @param {*} tokenId In uint256 form
 * @returns
 */
export const getAssetByTokenId = async (tokenId) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}assets/${tokenId}/token`
  console.log(`getAssetByTokenId from ${url}`)
  return fetch(url)
    .then(handleErrors)
    .then((res) => res.json())
    .then((asset) => {
      return asset
    })
}


const downloadPrivilegedFile = async (tokenId, accessor, accessToken, isAlbum) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}${isAlbum ? 'albums' : 'assets'}/${tokenId}/${accessor}/getPrivileged`
  console.log(`downloadPrivilegedFile from ${url}`)
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  })
    .then(handleErrors)
    .then((res) => res.blob())
    .then((privilegedFile) => {
      return privilegedFile
    })
}

/**
 * 
 * @param {*} tokenId           
 * @param {*} accessor          Current Ethereum account addess must be the same as that of the Asset Owner
 * @param {*} privilegedMedia 
 * @param {*} accessToken 
 * @param {*} setError 
 * @param {*} isAlbum           If true get the privilegedMedia for the Album, otherwise the Asset
 */
export const getPrivilegedFile = async (
  tokenId,
  accessor,
  privilegedMedia,
  accessToken,
  setError,
  isAlbum
) => {
  if (privilegedMedia) {
    const { mimeType, sourceName } = privilegedMedia
    const filename = sourceName && sourceName.indexOf('_') !== -1 ? sourceName.substring(sourceName.indexOf('_') + 1) : `privileged.${mimeType}`
    try {
      const data = await downloadPrivilegedFile(tokenId, accessor, accessToken, isAlbum)
      const src = URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = src
      link.setAttribute(
        "download",
        `${filename}`
      )
      document.body.appendChild(link)
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  } else {
    setError('Broken media')
  }
}
/**
 * This method will get a url from:
 * - image or thumbnail from token metadata if entity has a top-level "image" field
 * - publicMedia if entity.tokenId is not null,
 * - otherwise it will get privateMedia.
 *
 * Both token metadata and publicMedia contain IPFS CIDs, and so image URLs formed from
 * those values are set as img src. For privateMedia, the data is downloaded, and a data URL is
 * set as img src .
 *
 * In the case of privateMedia, the server must ensure that
 * only the entity creator can read it.
 * @param {*} entity        An Asset with publicMedia and privateMedia , or token metadata with image
 * @param {*} useDisplay    True to use the display size image, false for thumbnail
 * @param {*} accessToken
 * @param {*} setImageSrc   Callback that gets img src
 * @param {*} setError      Callback if there is an error
 */
export const getImage = (
  entity,
  useDisplay,
  accessToken,
  setImageSrc,
  setError
) => {
  /*
  const { image, publicMedia, privateMedia, tokenId } = entity
  if (image) {
    const { image, properties } = entity
    const { thumbnail } = properties
    getIpfsImage(useDisplay || !thumbnail ? image : thumbnail, setImageSrc)
  } else if (!tokenId && accessToken) {
    getPrivateImage(
      privateMedia,
      useDisplay,
      accessToken,
      setImageSrc,
      setError
    )
  } else {
    getPublicImage(publicMedia, useDisplay, setImageSrc)
  }
  */
}

export const getStream = async (thumbnailCID, accessToken) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}stream/${thumbnailCID}`
  console.log(`getStream from ${url}`)
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  })
    .then(handleErrors)
    .then((stream) => {
      return stream
    })
}

/**
 * Update the asset on the server, using the _id field in the passed asset.
 * @param {*} asset
 * @param {*} accessToken
 * @returns Either {metadata,assetId} or {assetId}
 */
export const updateAsset = async (asset, accessToken) => {
  return fetch(`${process.env.REACT_APP_CORE_SERVICE}assets/update`, {
    method: 'POST',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(asset),
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((updated) => {
      return updated
    })
}

/**
 * Replace all of the assets in the passed array
 * @param {*} assets      Array of fully-populated assets
 * @param {*} accessToken
 * @returns
 */
export const bulkUpdate = async (assets, accessToken) => {
  return fetch(`${process.env.REACT_APP_CORE_SERVICE}assets/bulkupdate`, {
    method: 'POST',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(assets),
  })
    .then(handleErrors)
    .then((response) => {
      return response
    })
}

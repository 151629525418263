import { DeleteForeverTwoTone, Send } from '@material-ui/icons'
import { useState } from 'react'
import { useEffect } from 'react'
import { logWithTime } from 'util/screenUtils'
import { useRef } from 'react'
import { displayError } from 'util/screenUtils'
import { getStorageAccount } from 'redux/actions/accountActions'
import { isVeryNarrow } from 'util/screenUtils'
import { TextField, useMediaQuery } from '@material-ui/core'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
import UserImage from 'components/image/UserImage'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { useDispatch, useSelector } from 'react-redux'
import { setPlayListIndex } from 'redux/actions/harmonizeActions'
import { setReactorPlay } from 'redux/actions/harmonizeActions'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { setShowChat } from 'redux/actions/harmonizeActions'
import { getChatLog } from 'controllers/HarmonizeController'
import SmallProfileImage from 'components/auction/SmallProfileImage'
import ModalDialog from 'components/ModalDialog'
const chatStyles = {
    clearChat: {
        fontSize: '2em', color: 'red'
    },
    memberLine: {
        display: 'flex', fontSize: '0.75em',
        alignItems: 'center', overflowX: 'auto', overflowY: 'clip', margin: '0.1em', padding: '0.1em',
        border: '1px dotted blue'
    },
    queryInputLine: {
        width: '100%', backgroundColor: 'white',
        border: '0.5px solid gray', display: 'flex'
    },
    queryInputLineNarrow: {

        width: '100%', backgroundColor: 'white',
        border: '0.5px solid gray', display: 'flex'
    },
    sendButton: { width: '100%' },
    sendButtonNarrow: { width: '100%' },
    chatLine: {
        fontSize: '0.8em',
        borderBottom: '0.5px dashed gray'
    }
}
/**
* This must never be rendered without an accessToken
 */
export default function Chat({ roomOwner, setError, socket }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const { firstName, lastName, handle, id: userId, img, providerImgUrl } = getStorageAccount()
    const { playList } = useSelector(state => state.harmonize)
    const [inputMessage, setInputMessage] = useState('')
    const [playing, setPlaying] = useState()
    const { currentRoom } = useSelector(state => state.messages)
    const [displayInput, setDisplayInput] = useState(false)
    const [chatAuthorized, setChatAuthorized] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const dispatch = useDispatch()

    const chatRef = useRef()
    const accessToken = getStorageAccessToken()

    const [chatMembers, _setChatMembers] = useState([])
    const chatMembersRef = useRef(chatMembers)
    const setChatMembers = (data) => {
        chatMembersRef.current = data
        _setChatMembers(data)
    }

    const [chatMessages, _setChatMessages] = useState([])
    const chatMsgsRef = useRef(chatMessages)
    const setChatMessages = (data) => {
        chatMsgsRef.current = data
        _setChatMessages(data)
    }

    /* The following methods are used to track the visibility state of the browser. */
    const [visible, _setVisible] = useState('visible')
    const visibleRef = useRef(visible)
    const setVisible = (data) => {
        visibleRef.current = data
        //logWithTime(`setVisible to ${visibleRef.current}`)
        _setVisible(data)
    }

    const playPlaylistEntry = (messageId) => {
        const ix = playList.findIndex(pl => pl.messageId == messageId)
        if (ix !== -1) {
            dispatch(setPlayListIndex(ix))
            dispatch(setReactorPlay(true))
        }
    }

    const displayChatLine = (msg, ix) => {
        const { handle, img, providerImgUrl, message, joined, left, messageId, name, userId } = msg
        if (messageId) {
            return (
                <div key={ix} style={{ ...chatStyles.chatLine, textAlign: 'right', fontStyle: 'italic', backgroundColor: 'gold', cursor: 'pointer' }}
                    onClick={() => playPlaylistEntry(messageId)} title='Click to play'
                >
                    {`${handle} now playing ${name}`}
                </div>
            )
        } else if (joined || left) {
            return (
                <div key={ix} style={{ ...chatStyles.chatLine, textAlign: 'right', fontStyle: 'italic', backgroundColor: 'white' }} >
                    {handle} {joined ? 'joined' : 'left'}
                </div>
            )
        } else if (userId) {
            return (
                <div key={ix} style={chatStyles.chatLine} >
                    <SmallProfileImage
                        userId={userId}
                    /> {handle}: {message}
                </div>
            )
        } else {
            return (
                <div key={ix} style={chatStyles.chatLine} >
                    <UserImage
                        title={handle}
                        image={{ img, providerImgUrl }}
                        style={imagesStyles.bidProfileImageContainer}
                    /> {handle}: {message}
                </div>
            )
        }

    }

    /**
     * The displayed line has to have a fixed height so that if the X scroll bars show up there is space for them.
     * @returns 
     */
    const displayMembers = () => {
        let currentMembers = ''
        const isOwner = userId === roomOwner.user
        chatMembersRef.current.forEach(m => {
            if (currentMembers.length) {
                currentMembers += ','
            }
            currentMembers += m.handle
        })
        if (isOwner) {
            return (
                <div style={{ display: 'flex' }}>
                    <DeleteForeverTwoTone style={chatStyles.clearChat} onClick={() => setConfirmDelete(true)} />
                    {currentMembers.length ? <div style={chatStyles.memberLine}>{currentMembers}</div> : null}
                </div>
            )
        } else if (currentMembers.length) {
            return <div style={chatStyles.memberLine}>{currentMembers}</div>
        }
    }

    const displayChat = () => {
        return (
            <div style={{

                height: '30vh',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '0.5em'
            }}>
                {displayMembers()}
                <div ref={chatRef}
                    style={{
                        border: '1px solid black', overflowY: 'scroll',
                        height: '100%',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '96vw',
                        marginLeft: '2vw'
                    }}>
                    {chatMessages.map((message, ix) => displayChatLine(message, ix))}

                </div>
                {displayChatInput()}
                <ModalDialog
                    title='Confirm Delete'
                    message={`Are you sure you want to permanently delete this chat?`}
                    close={() => setConfirmDelete(false)}
                    open={confirmDelete}
                    noLabel='No'
                    no={() => setConfirmDelete(false)}
                    yesLabel='Yes'
                    yes={async () => {
                        setConfirmDelete(false)
                        safeEmit('chatclear')
                    }}
                />
            </div>
        )
    }

    const displayChatInput = () => {
        if (displayInput) {
            return (
                <div style={veryNarrow ? chatStyles.queryInputLineNarrow : chatStyles.queryInputLine}>
                    <div style={{
                        display: 'flex', alignItems: 'baseline', width: '96vw',
                        marginLeft: '2vw'
                    }}>
                        <TextField
                            value={inputMessage}
                            onChange={(event) => setInputMessage(event.target.value)}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    send()
                                }
                            }}
                            style={veryNarrow ? chatStyles.sendButtonNarrow : chatStyles.sendButton}
                            fullWidth
                            label={`Enter chat message`}
                            inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
                        />
                        <div title='Send' style={{ cursor: 'pointer', backgroundColor: 'lightblue' }} onClick={() => send()}>
                            <Send style={{ fontSize: '1.5em' }} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    const send = () => {
        if (socket && inputMessage.length) {
            const chatMessage = { message: inputMessage, handle, img, providerImgUrl, userId }
            console.log('send', chatMessage)
            safeEmit('chat', chatMessage)
            setInputMessage([])
            scrollToBottom()
        }
    }

    /** NEVER FORGET that this must be attached to a useEffect */
    const scrollToBottom = () => {
        //console.log(`scrollToBottom`, chatRef)
        if (chatRef.current) {
            const scroll = chatRef.current.scrollHeight -
                chatRef.current.clientHeight
            chatRef.current.scrollTo(0, scroll)
        }
    }

    const addChatLine = (message) => {
        chatMsgsRef.current.push(message)
        setChatMessages([...chatMsgsRef.current])
    }

    /**
     * In the playing object, messageId is a string. In the playlist, it is a number.
     */
    const addPlayingLine = () => {
        if (playing) {
            console.log('addPlayingLine', playing)
            const { messageId } = playing
            const entry = playList.find(pl => pl.messageId == messageId)
            if (entry) {
                addChatLine(playing)
            } else {
                console.error(`Did not find ${messageId} in playlist`, playList)
            }
        }
    }

    const addChatMember = (join) => {
        const { handle } = join
        console.log(`addChatMember ${handle} chatMembers`, chatMembersRef.current)
        const memberIx = chatMembersRef.current.findIndex(cm => cm.handle.toLowerCase() === handle.toLowerCase())
        if (memberIx === -1) {
            chatMembersRef.current.push(join)
            console.log('...members are now', chatMembersRef.current)
            setChatMembers([...chatMembersRef.current])
        }
    }

    const removeChatMember = (leave) => {
        const { handle } = leave
        console.log('removeChatMember', chatMembersRef.current)
        if (handle) {
            const memberIx = chatMembersRef.current.findIndex(cm => cm.handle.toLowerCase() === handle.toLowerCase())
            if (memberIx !== -1) {
                chatMembersRef.current.splice(memberIx, 1)
                setChatMembers([...chatMembersRef.current])
            }
        }
    }

    const receivedMembers = (members) => {
        //chatMembersRef.current.push(members)
        setChatMembers(members)
    }

    const checkAuthorized = () => {
        const { accessType } = currentRoom
        if (!chatAuthorized && accessType === ACCESS_TYPES.PUBLIC) {
            console.log(`Authorizing chat in PUBLIC room`)
            safeEmit('authorize', accessToken)
        } else {
            console.log(`Chat is authorized, send chatjoin`)
            joinChat()
        }
    }

    /** This should not ever be called without accessToken */
    const initChat = () => {

        console.log('Chat initChat')
        if (socket()) {
            socket().on('chat', async (chat) => {
                logWithTime(`received chat`, chat)
                addChatLine(chat)
            })
            socket().on('chatjoin', async (chatjoin) => {
                logWithTime(`received chatjoin`, chatjoin)
                //addChatLine({ ...chatjoin, joined: true })
                addChatMember(chatjoin)
            })
            socket().on('chatleave', async (chatleave) => {
                logWithTime(`received chatleave`, chatleave)
                removeChatMember(chatleave)
            })
            socket().on('chatclear', async () => {
                logWithTime(`received chatclear`)
                setChatMessages([])
            })
            socket().on('members', async (members) => {
                logWithTime(`received members`, members)
                receivedMembers(members)
            })
            socket().on('playing', async (playing) => {
                logWithTime(`received playing`, playing)
                setPlaying(playing)
            })
            socket().on('authorized', async () => {
                console.log(`Chat authorized on server`)
                setChatAuthorized(true)
            })
            socket().on('unauthorized', async () => {
                console.error(`Chat NOT authorized on server`)
                dispatch(setShowChat(false))
                setChatAuthorized(false)
                displayError({ message: 'Chat not authorized' }, setError)
            })

            checkAuthorized()
        }

    }

    const joinChat = () => {
        console.log(`Chat send chatjoin for ${handle}`, currentRoom)
        safeEmit('chatjoin', { userId, handle, firstName, lastName })
        const { _id } = currentRoom
        getChatLog(_id, accessToken).then((log) => populateChat(log)).catch((error) => console.error(error))
    }

    const populateChat = (chatLog) => {
        let messages = []
        chatLog.reverse().forEach(cl => {
            const { handle, message, userId } = cl
            messages.push({ handle, userId, message })
        })
        setChatMessages(messages)
        setDisplayInput(true)
    }

    const safeEmit = (type, message) => {
        if (socket()) {
            socket().emit(type, message)
        }
    }

    useEffect(() => {
        if (playing) {
            addPlayingLine()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playing])

    useEffect(() => {
        if (chatAuthorized) {
            joinChat()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatAuthorized])

    const initSession = async () => {
        try {
            //scrollToBottom()
            initChat()
        } catch (error) {
            displayError(error, setError)
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [chatMessages])

    useEffect(() => {
        initSession()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            //logWithTime('---> Vortex disconnecting due to visibility change')
            //disconnectExisting()
        } else {
            initSession()
        }
        setVisible(document.visibilityState)
    }

    useEffect(() => {
        window.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {displayChat()}
        </div>

    )
}
const imagesStyles = {
  vortexProfileImageContainer: {
    borderRadius: '50%',
    height: '50px',
    overflow: 'hidden',
    marginRight: '0.25em',
    cursor: 'pointer'
  },
  headerProfileImageContainer: {
    borderRadius: '50%',
    height: '50px',
    overflow: 'hidden',
    marginRight: '0.25em',
  },
  bidProfileImageContainer: {
    borderRadius: '50%',
    height: '2em',
    width: '2em',
    padding: '0.25em',
    overflow: 'hidden',
  },
  menuProfileImageContainer: {
    borderRadius: '50%',
    height: '100px',
    width: '100px',
    overflow: 'hidden',
  },
  postProfileImageContainer: {
    borderRadius: '50%',
    width: '3em',
    padding: '0.25em',
    overflow: 'hidden',
    marginRight: '0.25em'
  },
  profileImageContainer: {
    borderRadius: '50%',
    height: '10em',
    width: '10em',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: '2px 2px 2px 2px rgba(127,127,127,0.8)',
  },
  profileImage: {
    width: 'auto',
    height: '100%',
    margin: '0 auto',
  },
  imgFluid: {
    maxWidth: '100%',
    height: 'auto',
  },
  imgRounded: {
    borderRadius: '6px !important',
  },
  //When this has !important it actually defeated the borderRadius
  imgRoundedCircle: {
    borderRadius: '50%',
  },
  imgRaised: {
    boxShadow:
      '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  imgGallery: {
    width: '100%',
    marginBottom: '2.142rem',
  },
  imgCardTop: {
    width: '100%',
    borderTopLeftRadius: 'calc(.25rem - 1px)',
    borderTopRightRadius: 'calc(.25rem - 1px)',
  },
  imgCardBottom: {
    width: '100%',
    borderBottomLeftRadius: 'calc(.25rem - 1px)',
    borderBottomRightRadius: 'calc(.25rem - 1px)',
  },
  imgCard: {
    width: '100%',
    borderRadius: 'calc(.25rem - 1px)',
  },
  imgCardOverlay: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    padding: '1.25rem',
  },
}

export default imagesStyles

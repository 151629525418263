import React, { useState } from 'react'

import validator from 'validator'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'

import CardHeader from 'components/Card/CardHeader.js'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import RequiredTextField from './RequiredTextField'
import {
  sendContactEmail,
} from 'controllers/ContactController'
import { getBrandName } from 'util/brandUtils'
import { getDisplayType } from 'redux/actions/displayActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ContactForm({ initialMessage }) {
  const classes = useStyles()
  const { vortex } = getDisplayType()
  const [messageSent, setMessageSent] = useState()
  const [error, setError] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [emailTo, setEmailTo] = useState()

  const [sending, setSending] = useState(false)

  const accessToken = getStorageAccessToken()
  const hostType = getDisplayType()
  const [message, setMessage] = useState(initialMessage)

  const validateEmail = (email) => {
    if (!validator.isEmail(email)) {
      return 'Invalid email address'
    }
    return null
  }

  const validateAndSubmit = async () => {
    if (message && firstName && lastName && email) {
      try {
        setSending(true)
        const response = await sendContactEmail(firstName, lastName, email, message)
        console.log('send email response', response)

        const { status } = response
        if (status !== 200) {
          const responseBody = await response.json()
          setSending(false)
          setError(responseBody.message)
        } else {
          setMessageSent(true)
        }
      } catch (error) {
        console.error(`Error sending message ${error.message}`)
        setError(error.message)
      }
    } else {
      console.warn('We cannot create')
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>

        <div>
          {messageSent ? (
            <h4>Thank you, your message has been sent</h4>
          ) : initialMessage ? null : (
            <p>
              Any questions about {getBrandName(hostType)}? Send us a message!
            </p>
          )}
        </div>
        {!messageSent && (
          <form className={classes.form} noValidate>
            {error ? (
              <h3 style={{ color: 'red', textAlign: 'center' }}>{error}</h3>
            ) : (
              <div style={{ backgroundColor: 'white', padding: '1em' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <RequiredTextField
                      name='firstName'
                      helper='First name please'
                      label='First Name'
                      autoComplete='fname'
                      setter={setFirstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RequiredTextField
                      label='Last Name'
                      name='lastName'
                      helper='Last name please'
                      autoComplete='lname'
                      setter={setLastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RequiredTextField
                      label='Email Address'
                      name='email'
                      helper='Email address please'
                      autoComplete='email'
                      validator={validateEmail}
                      setter={setEmail}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <RequiredTextField
                      defaultValue={message}
                      label='Message'
                      name='message'
                      helper='Please enter your message'
                      setter={setMessage}
                      multiline={true}
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  disabled={messageSent || sending}
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={() => validateAndSubmit()}
                >
                  Send Message
                </Button>
              </div>
            )}
          </form>
        )}
      </div>
    </Container>
  )
}

import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  caption: undefined,
  clipEnd: 10,
  clipStart: 0,
  description: undefined,
  image: null,
  mediaIcons: [],
  messageId: undefined,
  roomId: undefined,
  shortSummary: undefined,
}

const shortReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.SHORT.CAPTION:
      return {
        ...state,
        caption: action.caption,
      }
    case REDUX_TYPES.SHORT.CLIP_END:
      return {
        ...state,
        clipEnd: action.clipEnd,
      }
    case REDUX_TYPES.SHORT.CLIP_START:
      return {
        ...state,
        clipStart: action.clipStart,
      }
    case REDUX_TYPES.SHORT.DESCRIPTION:
      return {
        ...state,
        description: action.description,
      }
    case REDUX_TYPES.SHORT.IMAGE:
      return {
        ...state,
        image: action.image,
      }
    case REDUX_TYPES.SHORT.MEDIA_ICONS:
      return {
        ...state,
        mediaIcons: action.mediaIcons,
      }
    case REDUX_TYPES.SHORT.MESSAGE_ID:
      return {
        ...state,
        messageId: action.messageId,
      }
    case REDUX_TYPES.SHORT.ROOM_ID:
      return {
        ...state,
        roomId: action.roomId,
      }
    case REDUX_TYPES.SHORT.SUMMARY:
      return {
        ...state,
        shortSummary: action.shortSummary,
      }
    case REDUX_TYPES.SHORT.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
export default shortReducer

/*eslint-disable*/
import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import { hexToRgb, useMediaQuery } from '@material-ui/core'
import { isVeryNarrow } from 'util/screenUtils'

export default function Footer({ position = 'fixed' }) {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const fontSize = veryNarrow ? '0.75em' : '1em'
  return (
    <footer>
      <div
        style={{
          textAlign: 'center',
          fontSize,
          color: 'white',
          position: `${position}`,
          paddingTop: '0.5em',
          paddingBottom: '0.5em',
          bottom: '0px',
          width: '100%',
          backgroundColor: hexToRgb('#0c26c3')
        }}
      >
        {process.env.REACT_APP_TEST_PAYMENTS === 'true' ? 'TEST PAYMENTS ' : null}
        Version {process.env.REACT_APP_VERSION} &middot; &copy;{' '}
        {1900 + new Date().getYear()} {process.env.REACT_APP_COMPANY_NAME}. All rights reserved.
      </div>
    </footer>
  )
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
}

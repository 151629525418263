import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { logWithTime } from 'util/screenUtils'
import { handleErrors, handleErrorsWithCode } from './AssetController'

export const ENGAGEMENT_TYPES = {
    AUDIO: 0,
    VIDEO: 1
}

/** Add the home, public and private rooms if they don't exist. In any case,
 * return all studios.
 * 
 * @param {*} accessToken 
 * @returns Array of all studios
 */
export const addArtistStudios = async (accessToken) => {
    logWithTime(`addArtistStudios`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/addArtistStudios`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((studios) => {
            return studios
        })
}

/**
 * 
 * @param {*} name 
 * @param {*} description 
 * @param {*} messageId         Id of initial Message added to Collection
 * @param {*} roomId            Id of Room to which this Collection belongs
 * @param {*} accessToken 
 * @param {*} accessType 
 * @returns 
 */
export const addCollection = async (name, description, messageId, roomId, accessToken, accessType = ACCESS_TYPES.PUBLIC) => {
    const collection = { name, description, messageId, roomId, accessType }
    logWithTime('addCollection', collection)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/add`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(collection),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

/**
 * 
 * @param {*} collectionId 
 * @param {*} messageId 
 * @param {*} accessToken 
 * @returns 
 */
export const addToCollection = async (collectionId, messageId, accessToken) => {
    logWithTime(`addToCollection ${collectionId}/${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/addMessage/${collectionId}/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

/**
 * Because public anonymous access is allowed, user is not required. Instead the
 * Api key is required. Note that this uses the Social Service because engagements
 * are attached to Messages.
 * @param {*} action    An ENGAGEMENT_TYPES value
 * @param {*} messageId Id of Message
 * @param {*} user      Id of User
 * @param {*} duration  Length of time in seconds at engagement
 * @returns 
 */
export const addEngagement = async (action, messageId, user, duration) => {
    const engagement = { action, messageId, user, duration }
    logWithTime('addEngagement', engagement)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/addEngagement`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(engagement),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

export const getChatLog = async (roomId, accessToken, start = 0, limit = 50) => {
    console.log(`getChatLog ${roomId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}chat/list/${roomId}/${start}/${limit}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((chatLog) => {
            return chatLog
        })

}

/**
 * Get the Collections for this Room (not the User)
 * @param {*} roomId 
 * @returns 
 */
export const getCollections = async (roomId) => {
    console.log(`getCollections ${roomId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/list/${roomId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((collections) => {
            return collections
        })

}

export const getCreditPricing = async () => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/pricing`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((pricing) => {
            console.log('Got procing', pricing)
            return pricing
        })

}

/**
 * Add a Reaction to the specified Message for the signed-in User
 * @param {*} reaction {messageId, unifiedEmoji, playTime}
 * @param {*} accessToken 
 * @returns 
 */
export const addReaction = async (reaction, accessToken) => {
    logWithTime('addReaction', reaction)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/addReaction`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reaction),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

export const getReactions = async (messageId, accessToken) => {
    console.log(`getReactions ${messageId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}reaction/list/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((reactions) => {
            return reactions
        })

}

/**
 * Hey you cannot start a function name with "use" or React thinks it's a hook.
 * @param {*} credit 
 * @param {*} accessToken 
 * @returns 
 */
export const applyCredit = async (credit, accessToken) => {
    logWithTime('applyCredit', credit)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/applyCredit`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credit),
    })
        .then(handleErrorsWithCode)
        .then(() => {
            return true
        })
}

/**
 * Requires Api key
 * 
 * Charge a credit to the owner of the identified Studio. 
 * 
 * *Note* that this is
 * a dangerous call because it is unauthenticated and could result in
 * the Studio owner's credits being lost.

 * @param {*} credit 
 * @param {*} roomId 
 * @returns {*} { _id, availableCredits } where _id is the created Credit id and availableCredits is the remaining amount
 */
export const chargeCreditToStudio = async (credit) => {
    logWithTime('chargeCreditToStudio', credit)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/chargeCreditToStudio`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credit),
    })
        .then(handleErrors)
        .then(res => res.json())
        .then((updatedCredit) => {
            return updatedCredit
        })
}

export const getAccruedCredits = async (accessToken) => {
    console.log(`getAccruedCredits`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/getAccruedCredits`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((accruedCredits) => {
            return accruedCredits
        })

}

export const getSongAccruedCredits = async (messageId, accessToken) => {
    console.log(`getSongAccruedCredits for ${messageId}`)
    const url = `${process.env.REACT_APP_CORE_SERVICE}harmonize/getSongAccruedCredits/${messageId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((accruedCredits) => {
            return accruedCredits
        })

}

/**
 * 
 * @param {*} username 
 * @param {*} name 
 * @returns 
 */
export const getArtistSong = async (username, name) => {
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/artist/${username}/${name}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((song) => {
            return song
        })
}

/**
 * Get a fully-populated Collection including its referenced and populated Messages
 * (not just the messageIds)
 * @param {*} collectionId 
 * @param {*} accessToken 
 * @returns 
 */
export const getCollection = async (collectionId, accessToken) => {
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}collection/get/${collectionId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken,
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((collection) => {
            return collection
        })
}

export const getSamplerSongs = async (start, count) => {
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}message/list/sampler/${start}/${count}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((songs) => {
            return songs
        })
}


/**
 * availableCredits is stored as a Mongoose decimal and so it must be converted
 * on the Core Service to an int before returning it here.
 * @param {*} accessToken 
 * @returns An integer
 */
export const getStudioCredits = async (roomId) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}studio/credits/available/${roomId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((availableCredits) => {
            console.log('Got available credits', availableCredits.availableCredits)
            return availableCredits.availableCredits
        })
}


/**
 * 
 * Create a video short using either the image specified by
 * - fileId (Backblaze id), 
 * 
 * or the fileData that will be uploaded as the image for the video.
 * 
 * - audioMedia {source,sourceName}
 * - [options] is {duration,timeStart,textOverlay}
 * 
 * @param {*} fileData  If defined, an array of File objects that are uploaded
 * @param {*} params { name, username, fileId, audioMedia, options }
 * @param {*} accessToken 
 * @returns Blob of video
 * @see https://stackoverflow.com/questions/35206589/how-to-download-fetch-response-in-react-as-file
 */
export const videoShort = async (fileData, params, accessToken) => {
    console.log(`videoShort params`, params)
    const formData = new FormData()
    if (fileData) {
        console.log('videoShort fileData', fileData)
        fileData.forEach((f, ix) => {
            formData.append(`media${ix}`, f)
        })
    }
    formData.append('params', JSON.stringify(params))
    const url = `${process.env.REACT_APP_CORE_SERVICE}video/short`
    return fetch(url,
        {
            method: 'POST',
            headers: {
                'x-access-token': accessToken,
            },
            body: formData,
        })
        .then(handleErrors)
        .then((res) => res.blob())
        .then((video) => {
            console.log('Created video short')
            return video
        })
}
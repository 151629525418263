import React from 'react'
import Button from 'components/CustomButtons/Button.js'
import { useState } from 'react'
import { TextField } from '@material-ui/core'
import { DeleteForeverTwoTone } from '@material-ui/icons'
import ErrorLine from 'components/ErrorLine.js'
import { useSelector } from 'react-redux'
import { getStorageAccessToken } from 'redux/actions/accountActions.js'

import { displayError } from 'util/screenUtils.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader.js'
import Footer from 'components/Footer/Footer.js'
import { useHistory, useParams } from 'react-router-dom'
import ModalDialog from 'components/ModalDialog'
import { addCollection } from 'controllers/HarmonizeController'

export default function CreateCollection() {
  const { collectionId, messageId } = useParams()
  const { currentRoom } = useSelector(state => state.messages)
  const isEditing = collectionId !== undefined
  const [error, setError] = useState()
  const [collectionName, setCollectionName] = useState('')
  const [description, setDescription] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)

  const accessToken = getStorageAccessToken()

  const history = useHistory()

  /** This only performs an add, not an update */
  const saveOrUpdate = async () => {
    try {
      const { _id: roomId } = currentRoom
      const playlist = await addCollection(collectionName, description, messageId, roomId, accessToken)
      closePost()
    } catch (error) {
      displayError(error, setError)
    }
  }

  const closePost = () => {
    setCollectionName('')
    setDescription('')
    setError('')
    history.goBack()
  }

  const isDisabled = () => {
    return collectionName.length === 0 && description.length === 0
  }

  const displayForm = () => {
    const saveLabel = isEditing ? 'Update' : 'Save'
    return (
      <>

        <TextField
          value={collectionName}
          onChange={(event) => {
            setCollectionName(event.target.value)
          }}
          fullWidth
          label={`Title (max ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH})`}
          inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH }}
        />

        <TextField
          value={description}
          onChange={(event) => {
            setDescription(event.target.value)
          }}

          fullWidth
          multiline
          label={`Description (max ${process.env.REACT_APP_MAX_HARMONIZE_SUMMARY_LENGTH} characters)`}
          inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_SUMMARY_LENGTH }}
        />

        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>

          <Button
            color="primary"
            title='Go back'
            style={{ marginRight: '2em', cursor: 'pointer' }}
            onClick={() => closePost()}>
            Close
          </Button>
          <Button disabled={isDisabled()}
            color="success"
            title={saveLabel}
            style={{ marginRight: '2em', cursor: 'pointer' }}
            onClick={async () => await saveOrUpdate()}>
            {saveLabel}
          </Button>

        </div>

        {isEditing ? <div title='Click to delete playlist'><DeleteForeverTwoTone
          color='error'
          style={{ fontSize: '2em', cursor: 'pointer' }}
          onClick={(evt) => {
            evt.stopPropagation()
            setConfirmDelete(!confirmDelete)
          }}
        /> </div> : null}

      </>

    )

  }

  const displayCreate = () => {
    return (
      <>
        <h4 style={{ textAlign: 'center' }}>{collectionId ? 'Edit' : 'Create'} Collection</h4>
        {displayForm()}
      </>
    )
  }
  const displayCreateCollection = () => {
    return (<div style={{ marginLeft: '2em', marginRight: '2em' }}>
      <ErrorLine error={error} />
      {displayCreate()}
      <ModalDialog
        title='Confirm Delete'
        message={`Are you sure you want to permanently delete this playlist?`}
        close={() => setConfirmDelete(false)}
        open={confirmDelete}
        noLabel='No'
        no={() => setConfirmDelete(false)}
        yesLabel='Yes'
        yes={async () => {
          setConfirmDelete(false)
          try {
            //await deleteMessage(collectionId, accessToken)
            history.goBack()
          } catch (error) {
            displayError(error, setError)
          }
        }}
      />

    </div>

    )
  }


  return (
    <div >
      <HarmonizeHeader />
      {displayCreateCollection()}
      <Footer />
    </div>
  )
}

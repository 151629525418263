import { TextField, useMediaQuery } from '@material-ui/core'


import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { isNarrow } from 'util/screenUtils'
import { getStorageAccount } from 'redux/actions/accountActions'
import ChooseFromSelect from 'components/utility/ChooseFromSelect'
import { setCurrentAsset } from 'redux/actions/assetActions'
import { useHistory } from 'react-router-dom'
import { TRANSACTION_TYPE } from 'util/transactionUtils'
import { displayError } from 'util/screenUtils'
import ErrorLine from 'components/ErrorLine'
import Explain from 'components/Explain'
import { HelpOutlineTwoTone } from '@material-ui/icons'
import StandardSwitch from 'components/standard/StandardSwitch'
import { addFreeRoom } from 'controllers/StripeController'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getVortexCategories } from 'util/vortexCategories'
import { checkReservedRoom } from 'util/vortexCategories'
export const ACCESS_TYPES = {
  PRIVATE: 0,
  PROTECTED: 1,
  PUBLIC: 2,
  ADMIN: 99
}
export default function CreateVortexRoom() {

  const brandName = process.env.REACT_APP_NAME
  const narrow = isNarrow(useMediaQuery)
  const { firstName, lastName, username, id: userId, handle } = getStorageAccount()
  const accessToken = getStorageAccessToken()

  const [roomName, setRoomName] = useState('')
  const [description, setDescription] = useState('')
  const [exclusive, setExclusive] = useState(false)
  const [ownerPaysThoughts, setOwnerPaysThoughts] = useState(false)
  const [accessType, setAccessType] = useState(ACCESS_TYPES.PRIVATE)
  const [helpTarget, setHelpTarget] = useState()
  const [explain, setExplain] = useState()
  const [category, setCategory] = useState('')
  const [tags, setTags] = useState('')
  const [error, setError] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()

  const explainCreateRoom = {
    title: `Create a ${brandName} room`,
    description: `<p>To create a ${brandName} room, input a Room Name and Description, then select a Category.</p>
      <p>You may also set the room content type and who pays for the Harmonize AI uses the switches.</p>
      <p>The room will be a part of your <b>${username}</b> channel.</p>`,
  }

  const explainExclusive = {
    title: `Exclusive versus Open Content`,
    description: `<p>If you create an Open room, users who purchase the Creator role will be able to post new content. Comments will also be allowed. This is the
      best setting for topic-oriented rooms. For example, a Classical music discussion room should be open to allow Creators to share music by using
      embeds.</p>
      <p>If you create an Exclusive room, only you will be able to post new content. Comments on your posts will be allowed. This
      kind of room is best for personal rooms in which you are highlighting your work to the exclusion of other posts.</p>
      `,
  }

  const explainOwnerPaysForThoughts = {
    title: `Owner versus User-paid Thoughts`,
    description: `<p>The Harmonize AI is available directly from any room you own.</p>
      <p>If you select 'I pay for room Thoughts', the Vortex Thoughts used by the AI <i>in this room</i> are paid by you. User AI interactions
      in your room decrease your Thought Balance. If that balance is 0, the Harmonize AI will not be available in your room.</p>
            <p>If you select 'Room users pay for Thoughts', the Vortex Thoughts used by the AI <i>in this room</i> are paid by the user.</p>
      <p>Vortex thoughts used outside this room -- for example, in the main Harmonize AI Chat room -- are not paid by you.</p>
      `,
  }

  const explainPublic = {
    title: `Public versus Standard`,
    description: `<p>A <i>Public</i> room is one in which anyone who accesses it can read the content. No Vortex account is required.
      No interaction with the content is allowed -- for example, likes, comments, or posting new content are not allowed.</p>
      <p>A <i>Standard</i> room requires a Vortex account for any kind of access. The actions that can be performed depend
      on the <i>room role</> for that account.</p>
      `,
  }

  const getWidth = () => {
    return narrow ? '80vw' : '50vw'
  }

  /**
   * 
   * @param {*} roomData {title, caption, artist, category, tags, userId, exclusive}
   */
  const createFreeRoom = async () => {
    try {
      const roomData = {
        userId,
        title: roomName, caption: description, artist: `${firstName} ${lastName}`, category,
        exclusive,
        ownerPaysThoughts,
        accessType
      }
      const freeRoomResponse = await addFreeRoom(roomData, accessToken)
      console.log('Received free room response', freeRoomResponse)

    } catch (error) {
      console.error(error)
      const { errorResponse, status } = error
      //if (status === 400) {
      //  history.push('/BuyVortexAccess')
      //} else {
      displayError(errorResponse, setError)
      //}
    }
  }

  /** All Rooms are now free */
  const saveVortexAsset = async () => {
    if (checkReservedRoom(roomName)) {
      setError(`The name ${roomName} is reserved`)

    } else {
      try {
        await createFreeRoom()
        dispatch(setCurrentAsset({
          username, transactionType: TRANSACTION_TYPE.ROOM,
          accessToBuy: TRANSACTION_TYPE.ROOM,
          title: roomName, caption: description, artist: `${firstName} ${lastName}`, category,
          exclusive,
          ownerPaysThoughts,
          accessType
        }))

      } catch (error) {
        displayError(error, setError)
      }
    }
  }

  /**
   * The tokenID is created by downshifting the room name, then ORing in the 8-bit value of each 
   * character into a BigNumber. The Solidity uint256 can hold 32*8 bits, which is why the room
   * name is limited to 32 characters. Yeah that is the historical reason why the name is limited
   * to 32 characters.
   * @param {*} name 
   */
  const createTokenIdAndRoomName = (name) => {
    const regex = /^[a-z0-9 -]+$/i
    if (name.length === 0 || regex.test(name)) {
      setError('')
      setRoomName(name)
    }
  }

  const isRoomReady = () => {
    return roomName.length && description.length && category
  }

  const displayCreateRoom = () => {
    return (
      <>

        <div>
          <ErrorLine error={error} />
          Room Friendly Name
          <TextField
            value={roomName}
            onChange={(event) => createTokenIdAndRoomName(event.target.value)}
            fullWidth
            label={`Maximum ${process.env.REACT_APP_MAX_HARMONIZE_STUDIO_NAME_LENGTH} characters`}
            inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_STUDIO_NAME_LENGTH }}
          />
          {roomName ? (<p style={{ fontStyle: 'italic', marginLeft: '0.5em' }}>Harmonize URL for this studio: {process.env.REACT_APP_HARMONIZE_ROOM_URL}{roomName.toLowerCase().replace(/[^a-z0-9]/gi, '')}</p>) : null}
          Room Description
          <TextField
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            fullWidth
            multiline
            label={`Maximum ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH} characters`}
            inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
          />

          <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <ChooseFromSelect
              choice={category}
              choiceList={getVortexCategories()}
              label='Category&nbsp;'
              singleLine
              onSelected={(value) => setCategory(value)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HelpOutlineTwoTone
              color='primary'
              style={{
                height: '100%',
                marginRight: '0.25em',
                verticalAlign: 'middle',
                cursor: 'pointer',
              }}
              onClick={(event) => {
                setHelpTarget(event.currentTarget)
                setExplain(explainExclusive)
              }}
            />
            {exclusive ? 'Exclusive Content' : 'Open Content'}
            <StandardSwitch
              isChecked={exclusive ? true : false}
              onChange={() => setExclusive(!exclusive)}
            />

          </div>
          {/*
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HelpOutlineTwoTone
              color='primary'
              style={{
                height: '100%',
                marginRight: '0.25em',
                verticalAlign: 'middle',
                cursor: 'pointer',
              }}
              onClick={(event) => {
                setHelpTarget(event.currentTarget)
                setExplain(explainPublic)
              }}
            />
            {accessType ? 'Public' : 'Standard'}
            <StandardSwitch
              isChecked={accessType ? true : false}
              onChange={() => setPublicAccess(!accessType)}
            />

          </div>
            */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HelpOutlineTwoTone
              color='primary'
              style={{
                height: '100%',
                marginRight: '0.25em',
                verticalAlign: 'middle',
                cursor: 'pointer',
              }}
              onClick={(event) => {
                setHelpTarget(event.currentTarget)
                setExplain(explainOwnerPaysForThoughts)
              }}
            />
            {ownerPaysThoughts ? 'I pay for room Thoughts' : 'Room users pay for Thoughts'}
            <StandardSwitch
              isChecked={ownerPaysThoughts ? true : false}
              onChange={() => setOwnerPaysThoughts(!ownerPaysThoughts)}
            />

          </div>
          {explain && (
            <Explain
              source={explain}
              target={helpTarget}
              close={() => setExplain(null)}
            />
          )}

          {/*          
          <TextField
            value={tags}
            onChange={(event) => setTags(event.target.value.toLocaleLowerCase())}
            fullWidth
            label={`Tags (comma separated, no #, maximum ${process.env.REACT_APP_MAX_HARMONIZE_TAGS_LENGTH} characters)`}
            inputProps={{ maxLength: 64 }}
          />
    */}
        </div>
      </>
    )
  }
  const displayMain = () => {
    return (
      <div>
        <div
          style={{
            width: getWidth(),
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '2em',
          }}
        >

          {displayCreateRoom()}
        </div>

      </div>
    )
  }

  return (
    <div
      style={{
        width: narrow ? '90vw' : '75vw',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      {displayMain()}
    </div>
  )
}

import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  accessToken: undefined,
  account: undefined,
  artist: undefined, //If defined this is the PublicUser for the current site subdomain
  id: undefined, //Signed in user id
  username: 'Anonymous'
}

const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.ACCOUNT.ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      }
    case REDUX_TYPES.ACCOUNT.ARTIST:
      return {
        ...state,
        artist: action.artist,
      }


    case REDUX_TYPES.ACCOUNT.SET:
      return {
        ...state,
        account: action.account,
      }

    default:
      return state
  }
}
export default accountReducer

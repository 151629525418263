import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  transactionType: undefined,
}

const purchaseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.PURCHASE.SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.transactionType,
      }

    default:
      return state
  }
}
export default purchaseReducer

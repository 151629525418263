import React, { useState } from 'react'

import { InputLabel, MenuItem, Select } from '@material-ui/core'

import { useEffect } from 'react'
const chooseStyles = {
  default: {
    marginTop: '0.5em'
  },
  singleLine: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.25em'
  }
}
/**
 * Create a Select component from an array of choices formatted as '[n] - [value]' or
 * comma delimited. The onSelected callback receives {id,name}
 * @param {*} param0 
 * @returns 
 */
export default function ChooseFromSelect({ choice, choiceList, label, onSelected, commaDelimited = false, singleLine = false }) {
  const unselected = { id: 0, name: '- No choice -' }

  const choices = choiceList.map((g) => {
    const entry = g.split(commaDelimited ? ',' : ' - ')
    let id = parseInt(entry[0]) + 1
    return { id, name: entry[1] }
  })
  choices.sort(function (a, b) {
    const textA = a.name.toUpperCase()
    const textB = b.name.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })

  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(0)
  const displayChoices = () => {
    const selectChoices = [unselected, ...choices]
    return (
      <div style={singleLine ? chooseStyles.singleLine : chooseStyles.default}>
        <InputLabel id='choice-select'>{label}</InputLabel>
        <Select
          value={selectedChoiceIndex}
          labelId='choice-select'
          onChange={(event) => {
            const ix = event.target.value
            setSelectedChoiceIndex(ix)
            onSelected(ix === 0 ? unselected : choices[ix - 1])
          }}
        >
          {selectChoices.map((r, ix) => (
            <MenuItem key={r.id} value={ix}>
              {r.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }
  useEffect(() => {

    const choiceIndex = choice
      ? choices.findIndex((g) => g.id === choice)
      : -1
    setSelectedChoiceIndex(choiceIndex === -1 ? 0 : choiceIndex + 1)
  }, [])
  return displayChoices()
}

export const ATTACHMENT_TYPE = { LYRICS: 0, PDF: 1, DOCX: 2 }

export const getAttachmentLabel = (attachmentType) => {
    switch (attachmentType) {
        default:
            return 'Attachment'
        case ATTACHMENT_TYPE.LYRICS:
            return 'Lyrics'
        case ATTACHMENT_TYPE.PDF:
            return 'PDF'
        case ATTACHMENT_TYPE.DOCX:
            return 'Word docx'
    }
}

export const createLyrics = (attachmentContent) => {
    return { attachmentType: ATTACHMENT_TYPE.LYRICS, attachmentContent }
}

export const createPdf = (attachmentContent) => {
    return { attachmentType: ATTACHMENT_TYPE.PDF, attachmentContent }
}

export const getCopyright = (attachmentType, author, createdDate) => {
    if (attachmentType === ATTACHMENT_TYPE.LYRICS) {
        const { firstName, lastName } = author
        return `Copyright 2024 ${firstName} ${lastName}`
    }

}
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-kit-react/views/loginPage.js'

import { setAccessToken } from 'redux/actions/accountActions'
import { setAccount } from 'redux/actions/accountActions'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { googleSignIn } from 'controllers/AccountController'
import { getAuth, signOut } from 'firebase/auth'
import { getAndConnectRoom } from 'controllers/VortexController'
import { displayError } from 'util/screenUtils'
import { updateUser } from 'controllers/AccountController'

const useStyles = makeStyles(styles)

/**
 * 
 * @param {*} success If provided this function is called on successful sign in  
 * @returns 
 */
export default function SignInComponent({ returnTo, returnPath, requestedRoom, caption, content, success }) {
  const [error, setError] = useState()
  const [showInfo, setShowInfo] = useState(true)

  const { currentRoom } = useSelector(state => state.messages)
  console.log(`SignIn requestedRoom ${requestedRoom}`)
  const dispatch = useDispatch()

  /* It seems that Firebase email sign in is effed up always shows First Name and Last Name fields unless you do this:
  https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection#disable

  because of this:

  https://github.com/firebase/firebaseui-web/issues/1040

  Always something...
  */
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['https://www.googleapis.com/auth/userinfo.email'],
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      },
      /* These require additional work getting API keys etc
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: ['public_profile', 'email'],
      },
      */
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (result) => signinSuccess(result),
    },

    tosUrl: '/TermsOfService',

    privacyPolicyUrl: '/Privacy',
  }

  const history = useHistory()

  const classes = useStyles()
  const harmonizeSignOut = (action) => {
    console.log('harmonizeSignOut')
    dispatch(setAccessToken(null))
    dispatch(setAccount(null))

    const auth = getAuth()
    signOut(auth)
      .then(() => {
        if (action) {
          action()
        }
      })
      .catch((error) => {
        console.error(`Unable to sign out`, error)
      })
  }

  /**
   * The signin callback tells us if the user email is verified or is new. It also gives us
   * the idtoken that we send to the Core Service to complete sign in with Harmonize. 
   * 
   * WRONG: Google has both an "id" that is the same for the account across all services and
   * a "uid" that is different for each client app. We need the "id" because we do not differentiate users by host platform. But that
   * only works for a "Google sign in" which is a server-side thing that we no longer use. We need
   * the Firebase user_id and that does not comeback with this call,
   * @param {*} result
   * @returns
   */
  const signinSuccess = (result) => {
    console.log('signinSuccess', result)
    const { additionalUserInfo, user } = result
    const { isNewUser, providerId } = additionalUserInfo
    const { emailVerified } = user
    console.log(
      `additionalUserInfo verified ${emailVerified}`,
      additionalUserInfo
    )
    if (
      additionalUserInfo &&
      providerId === 'password' &&
      (isNewUser || !emailVerified)
    ) {
      //If not verified, send email verification whether or not this is a new user
      //Note this uses a hideous email confirmed page until this is done:
      //https://firebase.google.com/docs/auth/custom-email-handler
      console.log('Sending email verification to', result.user)
      result.user.sendEmailVerification()
      harmonizeSignOut(() => history.replace('/ConfirmEmailAddress'))
    } else {
      console.log('Signed in existing user', user)
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idtoken) {
          await completeSignIn(idtoken, additionalUserInfo)
        })
        .catch(function (error) {
          console.error('signin FAILED', error)
          displayError(error, setError)
        })
    }
    return false
  }
  /**
*
   * When a Google profile photo changes, this sign in has the URL right away; however the server sign in does not.
   * So take what we get here and store it with the account.
   * @param {*} idtoken
   */
  const completeSignIn = async (idtoken, additionalUserInfo) => {
    try {
      //console.log(`completeSignIn ${idtoken}`)
      const response = await googleSignIn(
        idtoken
      )

      const { status } = response
      if (status !== 200) {
        const result = await response.json()
        console.error('auth response', result)
        displayError(result, setError)
      } else {
        const result = await response.json()
        //console.log('completeSignIn result', result)

        const { accessToken } = result
        dispatch(setAccessToken(accessToken))
        const { picture } = additionalUserInfo.profile
        const { providerImgUrl } = result
        //console.log(`completeSignIn got ${picture} the server sign in got ${providerImgUrl}`)
        if (providerImgUrl && providerImgUrl !== picture) {
          const updatedAccount = { ...result, providerImgUrl: picture }
          dispatch(setAccount(updatedAccount))
          await updateUser(updatedAccount, accessToken)
        } else {
          dispatch(setAccount(result))
        }

        console.log(`SignIn success requestedRoom ${requestedRoom} returnPath ${returnPath} returnTo ${returnTo} currentRoom`, currentRoom)
        if (success) {
          success()
        }
        if (requestedRoom) {
          getAndConnectRoom(requestedRoom, accessToken, history, dispatch, (exc) => displayError(exc, setError))
        } else if (returnPath) {
          history.replace(returnPath)
        } else if (returnTo) {
          console.log(`SignIn replace /room/${returnTo}`)
          history.replace(`/room/${returnTo}`)
        } else {
          history.replace(currentRoom ? 'room' : '/')
        }
      }
    } catch (error) {
      console.error('completeSignIn FAILED', error)
      displayError(error, setError)
    }
  }

  return (
    <div>

      <form className={classes.form}>
        {showInfo ? <div onClick={() => setShowInfo(false)}>
          {content ? (<div>{content()}</div>) :
            <div style={{ textAlign: 'center' }}>{caption}</div>}
        </div> : null}
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
          style={{ marginBottom: 0 }}
        />
        <p style={{ color: 'black', textAlign: 'center', fontSize: '0.75em', lineHeight: 'normal' }}>At Harmonize, we respect your privacy.<br />We never share your information, including email address, with any third party.</p>

        {error && (
          <h3 style={{ color: 'red', textAlign: 'center' }}>
            {error}
          </h3>
        )}
      </form>


    </div>

  )
}

import { FileCopySharp } from '@material-ui/icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
import { getBrandName } from 'util/brandUtils'
export default function ShareContent({ name, messageId, imageUrl, setCopied, collectionId, roomName }) {
    const displayStyles = {
        shareIcon: {
            padding: '0.25em'
        }
    }

    const shareContent = () => {
        const shareHref = collectionId ? `${process.env.REACT_APP_SHARE_COLLECTION}/${roomName}/${collectionId}` : `${process.env.REACT_APP_SHARE_PAGE}/${messageId}`
        console.log(`shareContent for ${shareHref}`)
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CopyToClipboard text={shareHref}
                    onCopy={() => setCopied(true)}>
                    <div title={`Click to copy link to ${name}`} style={{
                        cursor: 'pointer',
                        marginTop: '4px',
                        borderRadius: '50%', height: '42px', width: '42px',
                        backgroundColor: 'gold',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <FileCopySharp style={{ ...displayStyles.shareIcon, fontSize: '40px' }} />
                    </div>
                </CopyToClipboard>

                <EmailShareButton subject={`Listen to ${name} on ${getBrandName()}`} body={`Listen to ${name} on ${getBrandName()}`} url={shareHref} >
                    <EmailIcon style={displayStyles.shareIcon} size={48} round />
                </EmailShareButton>
                <FacebookShareButton hashtag={`#${getBrandName()}`} url={shareHref} >
                    <FacebookIcon style={displayStyles.shareIcon} size={48} round />
                </FacebookShareButton>
                {imageUrl ?
                    <PinterestShareButton url={shareHref} media={imageUrl}>
                        <PinterestIcon style={displayStyles.shareIcon} size={48} round />
                    </PinterestShareButton> : null}
                <LinkedinShareButton subject={`Listen to ${name} on ${getBrandName()}`} sourcce={getBrandName()} url={shareHref} >
                    <LinkedinIcon style={displayStyles.shareIcon} size={48} round />
                </LinkedinShareButton>
                <RedditShareButton title={`Listen to ${name} on ${getBrandName()}`} url={shareHref} >
                    <RedditIcon style={displayStyles.shareIcon} size={48} round />
                </RedditShareButton>
                <TwitterShareButton url={shareHref} >
                    <TwitterIcon style={displayStyles.shareIcon} size={48} round />
                </TwitterShareButton>
            </div>
        )
    }


    return shareContent()
}
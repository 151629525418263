import { getShareableContent } from 'controllers/ShareController'
import moment from 'moment'

export const MESSAGE_STATUS = {
    DRAFT: 0,
    PUBLISHED: 1
}
export const APPROVAL_STATUS = {
    NONE: 0,
    APPROVED: 1,
    PENDING: 2,
    DECLINED: 3,
}

export const APPROVAL_REASON = {
    NONE: 0,
    COPYRIGHT: 1,
    CONTENT: 2
}


export const COLLECTION_TYPE = {
    NONE: 0,
    LIKED: 1,
    STUDIO: 2
}

export const RESERVED_STUDIO_NAMES = [
    'home',
    'private',
    'public',
    'liked',
    'studio'
]

export const DENIAL_REASONS = [
    'None',
    'Potential copyright violation',
    'Content violates our terms of use'
]

export const LIKED_COLLECTION = {
    _id: 0,
    name: 'Liked',
    description: 'Songs I have liked',
    collectionType: COLLECTION_TYPE.LIKED
}

export const STUDIO_COLLECTION = {
    _id: 1,
    name: 'Studio',
    description: 'All songs in this studio',
    collectionType: COLLECTION_TYPE.STUDIO
}

export const MEDIA_TYPES = {
    AUDIO: ['audio/mpeg', 'audio/wav'],
    IMAGE: ['image/jpeg', 'image/png']
}

export const UPLOAD_MEDIA_TYPES = {
    AUDIO: ['audio/mpeg', 'audio/wav'],
    IMAGE: ['image/jpeg', 'image/png']
}

export const isSupport = (currentRoom) => {
    const { category } = currentRoom
    return category && category === 'Support'
}

export const isNewFormat = (song) => {
    const { createdAt, updatedAt } = song
    return moment(createdAt).isAfter('2024-07-03') || moment(updatedAt).isAfter('2024-11-03')
}

export const parseMessageForUrl = async (message, accessToken) => {
    const ix = message.indexOf('https://')
    if (ix !== -1) {
        const ix2 = message.indexOf(' ', ix)
        const ix3 = ix2 !== -1 ? ix2 : message.length
        const urlString = message.substring(ix, ix3)
        console.log(`urlString ${urlString}`)
        try {
            const shareable = await getShareableContent(urlString, accessToken)
            console.log('shareable', shareable)
            //siteName came back en-US for giants.com
            const { title, image, description } = shareable
            if (title && description && image) {
                const urlMessage = message.slice(0, ix)
                    + `<br /><b>${title}</b>`
                    + `<br /><a href='${urlString}' target='_blank' ><img src='${image}' style='width:80%' /></a>`
                    + `<br />${description}`
                    + message.slice(ix3 + 1)
                return urlMessage
            }
        } catch (error) {
            console.log('parseMessageForUrl error IGNORED', error)
        }
    }
    return message
}


export const replaceNewLine = (contents) => {
    if (contents) {
        return contents.replace(/\n/g, '<br />')
    } else {
        return contents
    }
}
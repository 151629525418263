import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSongGenre } from 'redux/actions/songActions'
import genres from '../../assets/csv/genres.csv'
import ChooseFromSelect from './ChooseFromSelect'
export default function Genres() {
    const [genreList, setGenreList] = useState()
    const { genre } = useSelector(state => state.song)
    const dispatch = useDispatch()

    const formatGenres = () => {
        let genreArray = []
        fetch(genres).then(row => row.text()).then(text => {
            genreArray = text.split('\n')
            setGenreList(genreArray)
        })
    }
    const displayGenres = () => {
        if (genreList) {
            return (
                <div style={{ paddingTop: '1em', paddingBottom: '1em', display: 'flex', justifyContent: 'center' }}>
                    <ChooseFromSelect
                        choice={genre}
                        choiceList={genreList}
                        label='Genre&nbsp;'
                        singleLine
                        commaDelimited={true}
                        onSelected={(value) => dispatch(setSongGenre(parseInt(value.id)))}
                    />
                </div>
            )
        }
    }

    useEffect(() => {
        formatGenres()
    }, [])
    return displayGenres()
}
import { handleErrors } from './AssetController'

/**
 * 
 * @param {*} fileId 
 * @param {*} fileType image, pdf, or mp3. Determines Content-Type returned by server
 * @returns 
 */
export const downloadCoreFile = async (fileId, fileType) => {
  //console.log(`downloadCoreFile ${fileId}`)
  const url = `${process.env.REACT_APP_CORE_SERVICE}backblaze/download/${fileId}/${fileType}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
      'Cache-Control': `max-age=${process.env.REACT_APP_CACHE_CONTROL_AGE}`
    }
  })
    .then(handleErrors)
    .then((res) => res.blob())
    .then((blob) => {
      return blob
    })
}

/**
 * Get a publicly-available file using the public Api Key.
 * @param {*} fileId 
 * @param {*} fileType image, pdf, or mp3. Determines Content-Type returned by server
 * @returns 
 */
export const getPublicFile = async (fileId, fileType) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}backblaze/download/${process.env.REACT_APP_PUBLIC_API_KEY}/${fileId}/${fileType}`
  return fetch(url, {
    method: 'GET',
  })
    .then(handleErrors)
    .then((res) => res.blob())
    .then((blob) => {
      return blob
    })
}


import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import styles from 'assets/jss/material-kit-react/views/profilePage'
import harmonySmall from '../images/harmony-small.jpg'
import { AddCircle, Cancel, Edit, FaceTwoTone, Save } from '@material-ui/icons'
import { primaryColor } from 'assets/jss/material-kit-react'
import { Button, useMediaQuery } from '@material-ui/core'
import { uploadProfileImage } from 'controllers/AccountController'
import { getProfileImage } from 'controllers/AccountController'
import { setAccount } from 'redux/actions/accountActions'
import { updateUser } from 'controllers/AccountController'
import EditableTextField from 'components/EditableTextField'
import LightTooltip from 'components/LightTooltip'
import { resizeForUpload } from 'util/imageUtils'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { Link, useHistory } from 'react-router-dom'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { isImageMimeType } from 'util/fileUtils'
import { useEffect } from 'react'
import { isGooglebot } from 'util/screenUtils'
import { setShowProfileHelp } from 'redux/actions/helpActions'
import BlurDialog from 'components/utility/BlurDialog'
import { isVeryNarrow } from 'util/screenUtils'
import { addArtistStudios } from 'controllers/HarmonizeController'
import { displayError } from 'util/screenUtils'

const useStyles = makeStyles(styles)

export default function MyProfile() {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const dispatch = useDispatch()
  const accessToken = getStorageAccessToken()
  const { showProfileHelp } = useSelector(state => state.help)
  const { account } = useSelector((state) => state.account)
  const {
    availableCredits,
    description,
    firstName,
    lastName,
    businessName,
    username,
    handle,
    img,
    providerImgUrl,
  } = account ? account : {
    availableCredits: 0,
    description: '',
    firstName: '',
    lastName: '',
    businessName: '',
    username: '',
    handle: '',
    img: '',
    providerImgUrl: ''
  }
  const [accountDescription, setAccountDescription] = useState(description)
  const [accountFirstName, setFirstName] = useState(firstName)
  const [accountLastName, setLastName] = useState(lastName)
  const [accountHandle, setAccountHandle] = useState(handle)
  const [accountBusinessName, setBusinessName] = useState(businessName)
  const [image, setImage] = useState()
  const [mimeType, setMimeType] = useState('image/jpeg')
  const [imageDisplay, setImageDisplay] = useState()
  const [imageChanged, setImageChanged] = useState()
  const [error, setError] = useState()

  const [editMode, setEditMode] = useState(false)
  const classes = useStyles()
  const history = useHistory()

  const displayIntro = () => {
    const fontSize = veryNarrow ? '0.9em' : 'inherit'
    return (
      <div onClick={() => dispatch(setShowProfileHelp(false))}>
        <p style={{ textAlign: 'center', fontSize }}>Update your profile.</p>
        <p style={{ textAlign: 'center', fontSize }}>Update your profile. If you are an artist, upload your first song</p>
        <hr />
        <p style={{ display: 'flex', alignItems: 'center', fontSize }}>
          <Edit style={{ fontSize: '2em' }} />&nbsp;Click this icon to change a field
        </p>
        <p style={{ display: 'flex', alignItems: 'center', fontSize }}>
          <Save style={{ fontSize: '2em' }} />&nbsp;Click this icon to save a field
        </p>

        <p style={{ display: 'flex', alignItems: 'center', fontSize }}>
          <AddCircle style={{ fontSize: '2em' }} />&nbsp;If you are an artist, click this icon to upload your song.
        </p>

      </div>
    )
  }

  const displayHelpScreen = () => {
    if (showProfileHelp && !isGooglebot(window.navigator.userAgent)) {
      return (<div onClick={() => dispatch(setShowProfileHelp(false))}>
        <BlurDialog content={displayIntro} image={harmonySmall} hideTitle hideHelp closeIcon close={() => dispatch(setShowProfileHelp(false))}
          top={'10vh'} />
      </div>)
    }
  }

  const displayInfo = () => {
    if (editMode) {
      return (
        <div>
          <Cancel
            onClick={() => setEditMode(false)}
            style={{
              verticalAlign: 'middle',
              marginRight: '1em',
              cursor: 'pointer',
            }}
            color='error'
          />

          <h4 className={classes.title}>
            <EditableTextField
              label='Business Name'
              multiline={false}
              fullWidth={false}
              getter={() => {
                return accountBusinessName
              }}
              setter={setBusinessName}
              updater={updateBusinessName}
            />
          </h4>
          <h4>
            <EditableTextField
              label='First Name'
              multiline={false}
              fullWidth={false}
              getter={() => {
                return accountFirstName
              }}
              setter={setFirstName}
              updater={updateFirstName}
            />
            <EditableTextField
              label='Last Name'
              multiline={false}
              fullWidth={false}
              getter={() => {
                return accountLastName
              }}
              setter={setLastName}
              updater={updateLastName}
            />
          </h4>
          <h4>
            <EditableTextField
              label='Handle'
              multiline={false}
              fullWidth={false}
              getter={() => {
                return accountHandle
              }}
              setter={validateAndSetHandle}
              updater={updateHandle}
            />
          </h4>
        </div>
      )
    } else {
      return (
        <div>
          <LightTooltip title='Click to change your business name, first or last name, or handle'>
            <h4>
              <Edit fontSize='small' onClick={() => setEditMode(true)} />
              Business name: {accountBusinessName}
            </h4>
          </LightTooltip>
          <h4>
            Name: {accountFirstName} {accountLastName}
            <br />
            <LightTooltip title={`Your handle is used to access your rooms. This is currently ${process.env.REACT_APP_HARMONIZE_HOST}/@${handle}`}>
              <Link to={`/@${handle}`} replace>
                <div>Handle: {handle}</div>
              </Link>
            </LightTooltip>
          </h4>
          <LightTooltip title='Click to buy more credits' style={{ cursor: 'pointer' }} onClick={() => history.push('/BuyCredits')}>
            <h4 >
              Credits: {availableCredits}
            </h4>
          </LightTooltip>
        </div>
      )
    }
  }

  const updateImage = (dataUrl) => {
    const ix = dataUrl.indexOf('base64,')
    if (ix === -1) {
      setError('Invalid image data in this file')
    } else {
      setImage(dataUrl.substring(ix + 7))
      setMimeType('image/jpeg')
      setImageDisplay(dataUrl)
      setImageChanged(true)
    }
  }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      console.log(file)
      const { type } = file
      if (!isImageMimeType(type)) {
        setError('Please select a JPEG or PNG file')
      } else {
        resizeForUpload(
          file,
          process.env.REACT_APP_PROFILE_IMAGE_SIZE,
          async function (resizedDataUrl) {
            updateImage(resizedDataUrl)
          },
          displayError
        )
      }
    }
  }
  const saveImage = async () => {
    try {
      const response = await uploadProfileImage(
        image,
        username,
        mimeType,
        accessToken
      )
      if (response.status === 200) {
        setImageChanged(false)
        setError()
        const imageResponse = await getProfileImage(username)
        const { img } = imageResponse
        const updatedAccount = { ...account, img }
        dispatch(setAccount(updatedAccount))
      } else {
        const error = await response.json()
        setError(error.message)
      }
    } catch (error) {
      console.error('saveImage failed', error)
      setError(error.message)
    }
  }
  const displayImage = () => {
    if ((img && img.data) || imageDisplay || providerImgUrl) {
      let url

      if (imageChanged) {
        url = imageDisplay
      } else if (img && img.data) {
        const { data, contentType } = img
        url = `data:${contentType};base64,${data}`
      } else if (!imageChanged && providerImgUrl) {
        url = providerImgUrl
        return (
          <div style={{ height: '5em' }}>
            <img
              src={url}
              style={{ borderRadius: '50%', height: '100%' }}
              alt={accountBusinessName}
              referrerPolicy='no-referrer'
            />
          </div>
        )
      }
      return (
        <div>
          <div
            className={classes.profileImageContainer}
            style={{ cursor: 'pointer' }}
          >
            <LightTooltip title='Click to change your profile image'>
              <img src={url} alt={accountBusinessName} />
            </LightTooltip>
          </div>
          {imageChanged && (
            <Button
              simple='true'
              variant='contained'
              color='primary'
              className={classes.submit}
              style={{ marginTop: '10px' }}
              onClick={() => saveImage()}
            >
              Save Image
            </Button>
          )}
        </div>
      )
    } else {
      return (
        <>
          <LightTooltip title='Click to select your profile image'>
            <div>
              <FaceTwoTone
                style={{
                  fontSize: '10em',
                  color: primaryColor,
                  cursor: 'pointer',
                }}
              />
              <p>
                You need a profile image to post content
              </p>
            </div>
          </LightTooltip>
        </>
      )
    }
  }

  /**
   *Update the user on the server. This can update anything except img, which is
   preserved.
   * @param {*} revisedUser
   */
  const userUpdate = async (revisedUser) => {
    try {
      const updatedUser = await updateUser(revisedUser, accessToken)
      console.log('Received updated user', updatedUser)
      const updatedAccount = { ...updatedUser, img: account.img }
      dispatch(setAccount(updatedAccount))
      setError()
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  }
  const updateBusinessName = () => {
    const updatedUser = {
      ...account,
      businessName: accountBusinessName,
    }
    userUpdate(updatedUser)
    setEditMode(false)
  }
  const updateDescription = () => {
    const updatedUser = {
      ...account,
      description: accountDescription,
    }
    userUpdate(updatedUser)
  }
  const updateFirstName = () => {
    const updatedUser = {
      ...account,
      firstName: accountFirstName,
    }
    userUpdate(updatedUser)
    setEditMode(false)
  }
  const updateLastName = () => {
    const updatedUser = {
      ...account,
      lastName: accountLastName,
    }
    userUpdate(updatedUser)
    setEditMode(false)
  }

  const validateAndSetHandle = (text) => {
    if (text.indexOf('.') !== -1) {
      setError(`Handle may not contain periods`)
    } else {
      setError()
      setAccountHandle(text)
    }
  }
  const updateHandle = () => {
    const updatedUser = {
      ...account,
      handle: accountHandle,
    }
    userUpdate(updatedUser)
    setEditMode(false)
  }
  useEffect(() => {
    document.title = 'My Profile'
  }, [])
  return (
    <div>
      <HarmonizeHeader />

      <div>
        <div className={classes.container}>
          <GridContainer style={{ justifyContent: 'center' }}>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  {error && (
                    <h4 style={{ color: 'red', textAlign: 'center' }}>
                      {error}
                    </h4>
                  )}
                  <label htmlFor='btn-upload'>
                    <input
                      id='btn-upload'
                      name='btn-upload'
                      style={{ display: 'none' }}
                      type='file'
                      accept='image/jpeg,image/jpg,image/png'
                      multiple={false}
                      onChange={(event) => {
                        onImageChange(event)
                      }}
                    />
                    {displayImage()}
                  </label>
                </div>
                {displayInfo()}
              </div>
            </GridItem>
          </GridContainer>
          <div className={classes.description}>
            <EditableTextField
              label='Description'
              multiline={true}
              getter={() => {
                return accountDescription
              }}
              setter={setAccountDescription}
              updater={updateDescription}
            />
            {
              <div
                title={`Click to add a song`}
                style={{ textAlign: 'center' }}
                onClick={async () => addArtistStudios(accessToken).then(() => history.push(`/@${handle}/private/true`)).catch(error => displayError(error, setError))}
              >
                <AddCircle
                  color='primary'
                  style={{
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    borderRadius: '4em',
                    fontSize: '4em',
                  }}
                />
              </div>
            }
          </div>
        </div>
        {displayHelpScreen()}

      </div>
      <Footer />
    </div>
  )
}

import { createStore } from 'redux'
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import rootReducer from '../redux/reducers/rootReducer'
const migrations = {}
const REDUX_STORE_VERSION = 2

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  version: REDUX_STORE_VERSION,
  migrate: createMigrate(migrations),
  blacklist: ['artist', 'hostType'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const storeCreator = () => {
  const store = createStore(persistedReducer)
  const persistor = persistStore(store)
  //The following handing line will erase the redux store and is somewhat the equivalent
  //of clearing storage for an Android app
  //persistor.purge()
  return { store, persistor }
}

export default storeCreator

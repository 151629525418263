import * as REDUX_TYPES from '../types'

export const setShortCaption = (caption) => {
    return {
        type: REDUX_TYPES.SHORT.CAPTION,
        caption,
    }
}

export const setShortClipEnd = (clipEnd) => {
    return {
        type: REDUX_TYPES.SHORT.CLIP_END,
        clipEnd,
    }
}

export const setShortClipStart = (clipStart) => {
    return {
        type: REDUX_TYPES.SHORT.CLIP_START,
        clipStart,
    }
}

export const setShortDescription = (description) => {
    return {
        type: REDUX_TYPES.SHORT.DESCRIPTION,
        description,
    }
}

export const setShortImage = (image) => {
    return {
        type: REDUX_TYPES.SHORT.IMAGE,
        image,
    }
}

export const setShortMediaIcons = (mediaIcons) => {
    return {
        type: REDUX_TYPES.SHORT.MEDIA_ICONS,
        mediaIcons,
    }
}

export const setShortMessageId = (messageId) => {
    return {
        type: REDUX_TYPES.SHORT.MESSAGE_ID,
        messageId,
    }
}

export const setShortRoomId = (roomId) => {
    return {
        type: REDUX_TYPES.SHORT.ROOM_ID,
        roomId,
    }
}

export const setShortSummary = (shortSummary) => {
    return {
        type: REDUX_TYPES.SHORT.SUMMARY,
        shortSummary,
    }
}

export const resetShort = () => {
    return {
        type: REDUX_TYPES.SHORT.RESET
    }
}
import { handleErrors } from './AssetController'
export const REQUEST_HEADERS = {
  'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
}
export const confirmEmail = async (confirmationCode) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}auth/confirm/${confirmationCode}`
  )
  return response
}

/**
 * availableCredits is stored as a Mongoose decimal and so it must be converted
 * on the Core Service to an int before returning it here.
 * @param {*} accessToken 
 * @returns An integer
 */
export const getAvailableCredits = async (accessToken) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}users/credits/available`
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((availableCredits) => {
      console.log('Got available credits', availableCredits.availableCredits)
      return availableCredits.availableCredits
    })
}

export const getBrandImage = async (username) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}users/brandimage/${username}`
  )
  const img = await response.json()
  return img
}

/**
 *
 * @param {*} userId
 * @param {*} accessToken
 * @returns
 */
export const getFavorites = async (userId, accessToken) => {
  const url = `${process.env.REACT_APP_CORE_SERVICE}users/favorites/${userId}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((favorites) => {
      return favorites
    })
}
/**
 * Get the image by the username. This is another reason you can't change username, only handle.
 * @param {*} username
 * @returns { providerImgUrl, img} where img is {data,contentType}
 */
export const getProfileImage = async (username) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}users/image/${username}`
  )
  if (response.status === 200) {
    const img = await response.json()
    return img
  } else {
    return null
  }
}

export const getProfileImageById = async (userId) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}users/imageById/${userId}`
  )
  if (response.status === 200) {
    const img = await response.json()
    return img
  } else {
    return null
  }
}

/**
 * Requires Api key
 * @param {*} username 
 * @returns 
 */
export const getPublicUser = async (username) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}users/${username}`,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      },
    }
  )
  const publicUser = await response.json()
  return { ...publicUser, status: response.status }
}

/**
 * Requires Api key
 * @param {*} handle 
 * @param {*} accessToken 
 * @returns 
 */
export const getPublicUserByHandle = async (handle) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}users/handle/${handle}`,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      },
    }
  )
  const publicUser = await response.json()
  return { ...publicUser, status: response.status }
}

/**
 * Requires Api key
 * @param {*} userId 
 * @returns 
 */
export const getPublicUserById = async (userId) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}users/id/${userId}`,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      },
    }
  )
  const publicUser = await response.json()
  return { ...publicUser, status: response.status }
}

/**
 *
 * @param {*} username
 * @returns
 */
export const getStorage = async (username, accessToken) => {
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}users/storage/${username}`,
    {
      headers: {
        'x-access-token': accessToken,
      },
    }
  )
  const storage = await response.json()
  return storage
}

/**
 * All signins depend on Firebase.
 * 
 * @param {*} idtoken
 * @returns
 */
export const googleSignIn = async (idtoken) => {
  let signin = { idtoken, firebase: true }
  const params = new URLSearchParams(
    Object.keys(signin).map((key) => [key, signin[key]])
  )
  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}auth/googlesignin`,
    {
      method: 'POST',
      headers: REQUEST_HEADERS,
      body: params,
    }
  )
  return response
}

/**
 * 
 * @param {*} roomId        _id of Room
 * @param {*} subscribe     0 to unsubscribe 1 to subscribe
 * @param {*} accessToken 
 * @returns updatedUser
 */
export const updateSubscribed = async (roomId, subscribe, accessToken) => {

  return fetch(`${process.env.REACT_APP_CORE_SERVICE}users/updateSubscribed`, {
    method: 'POST',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ roomId, subscribe }),
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((updatedUser) => {
      return updatedUser
    })
}

/**
 * Update anything in user except img, which this method deletes before sending user.
 * @param {*} user
 * @param {*} accessToken
 * @returns
 */
export const updateUser = async (user, accessToken) => {
  let safeUser = { ...user }
  delete safeUser.img
  delete safeUser.accessToken
  return fetch(`${process.env.REACT_APP_CORE_SERVICE}users/update`, {
    method: 'POST',
    headers: {
      'x-access-token': accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(safeUser),
  })
    .then(handleErrors)
    .then((res) => res.json())
    .then((updatedUser) => {
      return updatedUser
    })
}

/**
 * Upload a JPEG. No other type is accepted for covers.
 *
 * @param {*} fileData
 * @param {*} params
 * @param {*} accessToken
 * @returns
 */
export const uploadBrandImage = async (fileData, params, accessToken) => {
  const formData = new FormData()
  formData.append('brand', fileData)
  formData.append('params', JSON.stringify(params))

  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}upload/brand`,
    {
      method: 'POST',
      headers: {
        'x-access-token': accessToken,
      },
      body: formData,
    }
  )
  return response
}

/**
 * This upload sends base64 data to the server
 * @param {*} fileData          Base64 data string
 * @param {*} username      Username who owns the profile
 * @param {*} mimeType      Image mimeType
 * @param {*} accessToken
 * @returns
 */
export const uploadProfileImage = async (
  fileData,
  username,
  mimeType,
  accessToken
) => {
  const formData = new FormData()
  formData.append('fileData', fileData)
  formData.append('params', JSON.stringify({ username, mimeType }))

  const response = await fetch(
    `${process.env.REACT_APP_CORE_SERVICE}upload/profile`,
    {
      method: 'POST',
      headers: { 'x-access-token': accessToken },
      body: formData,
    }
  )
  return response
}

import { makeStyles, Switch } from '@material-ui/core'
import { useState } from 'react'

import styles from '../../assets/jss/material-kit-react/customCheckboxRadioSwitch.js'
const useStyles = makeStyles(styles)

/* NB. If you don't set a default value for isChecked, and then you leave it out when calling the function, when you
execute onChange you get the annoying "chaning uncontrolled to controlled" warning. */
export default function StandardSwitch({ isChecked = false, onChange }) {
  const [checked, setChecked] = useState(isChecked)
  const classes = useStyles()
  return (
    <Switch
      checked={checked}
      onChange={() => {
        onChange()
        setChecked(!checked)
      }}
      classes={{
        switchBase: classes.switchBase,
        checked: classes.primaryColor,
        thumb: classes.switchIcon,
        track: classes.switchBar,
      }}
    />
  )
}

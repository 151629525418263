import CookieConsent from 'react-cookie-consent'

export default function AnnoyingConsent() {
  return (
    <CookieConsent >
      This site uses "cookies" to provide personalization, support for purchases
      and usage analysis.
    </CookieConsent>
  )
}

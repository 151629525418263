import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  aiCollaborators: [],
  chatMessages: [],
  currentAvailableThoughts: { ownerPaysThoughts: false, availableThoughts: 0 },
  currentCollaborator: '0',
  chatSessionId: undefined,
  roomId: undefined,
  waitingForHost: false
}

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.CHAT.ADD:
      return {
        ...state,
        chatMessages: [...state.chatMessages, action.message],
      }

    case REDUX_TYPES.CHAT.SET:
      return {
        ...state,
        chatMessages: action.chatMessages,
      }
    case REDUX_TYPES.CHAT.SET_CURRENT_AVAILABLE_THOUGHTS:
      return {
        ...state,
        currentAvailableThoughts: action.currentAvailableThoughts,
      }
    case REDUX_TYPES.CHAT.SET_CURRENT_COLLABORATOR:
      return {
        ...state,
        currentCollaborator: action.currentCollaborator,
      }
    case REDUX_TYPES.CHAT.SET_ROOM_ID:
      return {
        ...state,
        roomId: action.roomId,
      }
    case REDUX_TYPES.CHAT.SET_SESSION_ID:
      return {
        ...state,
        chatSessionId: action.chatSessionId,
      }
    case REDUX_TYPES.CHAT.SET_WAITING_FOR_HOST:
      return {
        ...state,
        waitingForHost: action.waitingForHost,
      }
    default:
      return state
  }
}
export default chatReducer

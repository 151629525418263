import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
    messages: [], //Array of Message each of which aggregates Comment
    currentChannel: undefined,
    currentRoom: undefined,
    currentSocket: undefined
}

/**
 * Look for the parent Message in messages, and if found push the newComment into its
 * comments array. If the message is not found the comment is ignored.
 * @param {*} newComment 
 * @param {*} messages 
 * @returns 
 */
const addComment = (newComment, messages) => {
    const { comment, messageId } = newComment
    const message = messages.find(m => m._id === messageId)
    if (message) {
        if (message.comments) {
            message.comments.push(newComment)
        } else {
            message.comments = [newComment]
        }
        console.log(`Added comment ${comment} to messageId ${messageId}`)
    }
    return [...messages]

}

const deleteComment = (deletedComment, messages) => {
    const { _id: commentId, messageId } = deletedComment
    const message = messages.find(m => m._id === messageId)
    if (message && message.comments) {
        message.comments = message.comments.filter(c => c._id !== commentId)
        console.log(`Deleted comment ${commentId} from messageId ${messageId}`)
    }
    return [...messages]
}

const messagesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REDUX_TYPES.MESSAGES.ADD:
            return {
                ...state,
                messages: [action.message, ...state.messages],
            }
        case REDUX_TYPES.MESSAGES.ADD_COMMENT:
            return {
                ...state,
                messages: addComment(action.comment, state.messages),
            }
        case REDUX_TYPES.MESSAGES.DELETE_COMMENT:
            return {
                ...state,
                messages: deleteComment(action.comment, state.messages),
            }
        case REDUX_TYPES.MESSAGES.DELETE:

            return {
                ...state,
                messages: state.messages.filter(item => action.message._id !== item._id),
            }
        case REDUX_TYPES.MESSAGES.UPDATE:

            return {
                ...state,
                messages: state.messages.toSpliced(state.messages.findIndex(m => m._id === action.message._id), 1, action.message),
            }
        case REDUX_TYPES.MESSAGES.SET:
            return {
                ...state,
                messages: action.messages,
            }
        case REDUX_TYPES.MESSAGES.SET_CHANNEL:
            return {
                ...state,
                currentChannel: action.currentChannel,
            }
        case REDUX_TYPES.MESSAGES.SET_ROOM:
            return {
                ...state,
                currentRoom: action.currentRoom,
            }
        case REDUX_TYPES.MESSAGES.SET_SOCKET:
            return {
                ...state,
                currentSocket: action.currentSocket,
            }

        default:
            return state
    }
}
export default messagesReducer
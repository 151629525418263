import React, { useState } from 'react'
import { Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'html-react-parser'
import styles from 'assets/jss/material-kit-react/popoverStyles.js'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(styles)
export default function Explain({ source, target, close }) {
  const narrow = useMediaQuery('(max-width:900px)')
  const classes = useStyles()
  const [anchor, setAnchor] = useState(target)
  const [closed, setClosed] = useState()
  const { title, description, contentFunction } = source

  return (
    <Popover
      classes={{
        paper: narrow ? classes.popoverWide : classes.popover,
      }}
      open={!closed}
      anchorEl={anchor}
      onClose={() => {
        close()
        setAnchor(null)
        setClosed(true)
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <h4>{title}</h4>
      <div onClick={() => close ? close() : {}}>{contentFunction ? contentFunction() : parse(description)}</div>
    </Popover>
  )
}

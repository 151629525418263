import * as REDUX_TYPES from '../types'

export const SETTINGS_MAP = 'storageMap'

export const INITIAL_STATE = {
  currency: 'USD',
  mapCenter: { lat: 39.828175, lng: -98.5795 }, //The center of the lower 48_ID
  mapTypeId: 'roadmap',
  mapZoom: 4,
  mainMap: {
    center: { lat: 37.5786667757512, lng: -112.2080116243034 },
    typeId: 'roadmap',
    zoom: 8,
  },
  truePlaceMap: {
    center: { lat: 31.5732641180714, lng: -109.32587467480857 },
    typeId: 'roadmap',
    zoom: 3,
  }
}

/* These settings are persisted for every user, signed-in or not */
const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.SETTINGS.CURRENCY:
      return {
        ...state,
        currency: action.currency,
      }

    case REDUX_TYPES.SETTINGS.MAP_CENTER:
      return {
        ...state,
        mapCenter: action.mapCenter,
      }
    case REDUX_TYPES.SETTINGS.MAP_TYPE_ID:
      return {
        ...state,
        mapTypeId: action.mapTypeId,
      }
    case REDUX_TYPES.SETTINGS.MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.mapZoom,
      }
    case REDUX_TYPES.SETTINGS.SET_MAINMAP:
      return {
        ...state,
        mainMap: action.mainMap,
      }
    case REDUX_TYPES.SETTINGS.MAINMAP.CENTER:
      return {
        ...state,
        mainMap: { ...state.mainMap, center: action.mapCenter },
      }
    case REDUX_TYPES.SETTINGS.MAINMAP.TYPE_ID:
      return {
        ...state,
        mainMap: { ...state.mainMap, typeId: action.mapTypeId },
      }
    case REDUX_TYPES.SETTINGS.MAINMAP.ZOOM:
      return {
        ...state,
        mainMap: { ...state.mainMap, zoom: action.mapZoom },
      }

    case REDUX_TYPES.SETTINGS.SET:
      return { ...action.settings }
    default:
      return state
  }
}
export default settingsReducer

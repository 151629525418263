import { useMediaQuery } from '@material-ui/core'
import Card from 'components/Card/Card'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getMediaThumbnail } from 'util/imageUtils'
import { isVeryNarrow } from 'util/screenUtils'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import { getMessage } from 'controllers/VortexController'
import { displayError } from 'util/screenUtils'
import ErrorLine from 'components/ErrorLine'
export default function PlaylistCard({ collection }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)

    const styles = {
        imageCard: {
            position: 'relative',
            borderRadius: veryNarrow ? '1em' : '2em',
            color: 'white',
            width: veryNarrow ? '40vw' : '30vw',
            height: veryNarrow ? '40vw' : '30vw'
        },
        nameCard: {
            position: 'relative',
            borderRadius: veryNarrow ? '1em' : '2em',
            color: 'white',
            height: veryNarrow ? '20vw' : '10vw'
        },
        hovered: {
            margin: 'auto',
            transform: 'scale3d(1.05, 1.05, 1)',
        },
        notHovered: {
            margin: 'auto',
        },
    }
    const [error, setError] = useState()
    const [hovered, setHovered] = useState(false)
    const { name, _id: collectionId, description, roomId } = collection
    const [imageSrc, setImageSrc] = useState()

    const displayImageCard = () => {
        return (

            <Card
                className='plain'
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
                style={{
                    ...imageSrc ? styles.imageCard : styles.nameCard, ...hovered ? styles.hovered : styles.notHovered, ...imageSrc ? {
                        backgroundImage: 'url(' + imageSrc + ')',
                        backgroundSize: 'cover',
                        opacity: 1
                    } : { backgroundColor: 'gold' }
                }}

            >
                <h4 style={{
                    textAlign: 'center', fontWeight: 'bolder', color: 'white',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 1)'
                }}>{name}</h4>
                <ErrorLine error={error} />
                <span
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        fontSize: veryNarrow ? '0.75em' : '1em',
                        lineHeight: 'normal',
                        fontWeight: hovered ? 'bold' : 'normal',
                        color: hovered ? 'gold' : 'white',
                        backgroundColor: 'grey'
                    }}
                >
                    {description}

                </span>
            </Card>


        )
    }


    /** Retrieval errors will occur if the collection references non-existing messages. */
    const getMedia = async () => {
        if (collection.messages && collection.messages.length) {
            console.log('&& getMedia collection', collection)
            const { messageId } = collection.messages[0]
            console.log(`&& getMedia ${name} ${messageId}`)
            try {
                const message = await getMessage(messageId)
                if (message) {
                    const { media } = message
                    const imageMedia = getMediaThumbnail(media)
                    console.log(`PlaylistCard ${messageId} imageMedia`, imageMedia)
                    const { source } = imageMedia
                    const data = await downloadCoreFile(source, 'image')
                    const src = URL.createObjectURL(data)
                    setImageSrc(src)
                }
            } catch (error) {
                displayError(error, setError)
            }
        }
    }

    useEffect(() => {

        getMedia()
    }, [])

    return (

        displayImageCard()
    )
}
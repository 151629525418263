import React, { useEffect } from 'react'
import Footer from 'components/Footer/Footer.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { Container } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default function Approvals() {

  const displayApprovals = () => {
    return (
      <div>
        The Harmonize approvals process helps provide a safe and honest environment for artists and listeners. All content is governed by our <Link to='/TermsOfService'>Terms of Service</Link>
        <h4>Content</h4>
        The text content you include with your song upload -- the title, message, and summary -- is first scanned by our AI content moderation system.
        If it is rejected you will receive an error and your post will not be saved.
        <h4>
          Published Content
        </h4>
        When you publish your song, a quick analysis is performed to determine:
        <ol>
          <li>If the song is clearly a copyright violation -- for example, you upload "Flowers" and you are not Miley Cyrus.</li>
          <li>The song itself contains lyrics that violate our terms of use</li>
        </ol>
        If either of these conditions is met, approval is declined. The reason is listed at the top of your song message. Once you correct
        the error, you may Republish the content for further review.
        <h5>Approved Content</h5>
        Once your content is approved, you will receive an email notification. Your song will immediately be live in your home studio.
        <h5>Copyright</h5>
        Just because a message is not flagged for a copyright violation does <b>not</b> mean we have validated its copyright status. If you upload content, it is your
        responsibility to ensure your own all rights to it. If Harmonize is notified of a violation by way of a DMCA takedown or similar action,
        we will unpublish the message until the issue is resolved.

      </div>
    )
  }
  useEffect(() => {
    document.title = 'Approvals'
  })
  return (
    <div>
      <HarmonizeHeader />

      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>

          <h4 style={{ color: 'black', textAlign: 'center', borderBottom: '1px solid black' }}>About</h4>

          {displayApprovals()}

        </Container>

      </div>
      <Footer />
    </div>

  )
}

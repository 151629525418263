import { downloadCoreFile } from 'controllers/BackblazeController'

const FILE_SUFFIXES = [
    '.mp3',
    '.jpg'
]

const ONE_GB = 1073741824

export const downloadPdf = async (id, fileName, window, document) => {
    const a = document.createElement("a")
    a.style.display = "none"
    document.body.appendChild(a)
    const blobFile = await downloadCoreFile(id, 'pdf')
    const url = window.URL.createObjectURL(blobFile)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
}

/**
 * 
 * @param {*} mt 
 * @returns 
 */
export const isImageMimeType = (mt) => {
    return mt === 'image/jpeg' || mt === 'image/png'
}

export const removeSuffix = (value) => {
    let fixed = value
    FILE_SUFFIXES.forEach(s => fixed = fixed.replace(s, ''))
    return fixed
}

/**
 * Handle friendly display of megabytes and gigabytes
 * @param {*} fileSize Size in bytes
 * @returns 
 */
export const getFileSizeDisplay = (fileSize) => {
    if (fileSize >= ONE_GB) {
        const size = fileSize / (1024 * 1024 * 1024)
        return `${size.toFixed(1)} ${size > 1 ? 'gigabytes' : 'gigabyte'}`
    } else {
        const size = fileSize / (1024 * 1024)
        return `${size.toFixed(1)} ${size > 1 ? 'megabytes' : 'megabyte'}`
    }
}
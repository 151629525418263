import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { getPublicUser } from '../controllers/AccountController'
import Footer from 'components/Footer/Footer.js'
import styles from 'assets/jss/material-kit-react/views/profilePage'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import ErrorLine from 'components/ErrorLine'
import { displayError } from 'util/screenUtils'
import RoomList from 'components/vortex/RoomList'

const useStyles = makeStyles(styles)

export default function UserProfile({ profileUsername }) {
  const { username } = useParams()
  const requestUsername = profileUsername ? profileUsername : username
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const classes = useStyles()

  const displayInfo = () => {
    if (user) {
      const {
        firstName,
        lastName,
        businessName,
        handle,
      } = user
      const fullName = `${firstName} ${lastName}`
      return (
        <div>
          {businessName !== fullName ? <div>
            {businessName}
          </div> : null}
          <div>
            {firstName} {lastName}
            <br /><a href={`/@${handle}`} target='_blank'>@{handle}</a>
            <br />{user ? user.description : null}
          </div>
        </div>
      )
    }

  }

  const displayImage = () => {
    if (user) {
      const {
        businessName,
        img,
        providerImgUrl
      } = user
      if ((img && img.data) || providerImgUrl) {
        let url
        if (img && img.data) {
          const { data, contentType } = img
          url = `data:${contentType};base64,${data}`
        } else if (providerImgUrl) {
          url = providerImgUrl
        }
        if (url) {
          return (
            <div style={{ height: '5em' }}>
              <img
                src={url}
                style={{ borderRadius: '50%', height: '100%' }}
                alt={businessName}
                referrerPolicy='no-referrer'
              />
            </div>
          )
        }
      }
    }
  }
  const displayStudios = () => {
    if (user) {
      const {
        handle
      } = user
      return (
        <div>
          <div style={{
            border: '1px solid black', overflowY: 'scroll', paddingLeft: '1em',
            paddingRight: '1em'
          }}>
            <RoomList ownerUsername={requestUsername} handle={handle} category='Music' />
          </div>
        </div>
      )
    }
  }
  const getUser = async () => {
    try {
      const user = await getPublicUser(requestUsername)
      if (user) {
        setUser(user)
      }
    } catch (error) {
      displayError(error, setError)
    }
  }
  useEffect(() => {
    getUser()
  }, [])

  return (
    <div>
      <HarmonizeHeader />
      <div style={{ margin: '1em' }}>
        <div className={classes.profile}>
          <ErrorLine error={error} />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
            {displayImage()}
            {displayInfo()}
          </div>
          {displayStudios()}
        </div>

      </div>
      <Footer />
    </div>
  )
}

import { useEffect, useState } from 'react'
import { RingLoader } from 'react-spinners'
import { primaryColor } from 'assets/jss/material-kit-react'
import momentDurationFormatSetup from 'moment-duration-format'
import moment from 'moment'
export default function TimerProgress({ label, height = 10 }) {
  const adjustedHeight = label ? height * 1.5 : height
  const totalHeight = `${adjustedHeight}vh`
  const loaderHeight = `${adjustedHeight / 2}vh`
  const [seconds, setSeconds] = useState(0)
  momentDurationFormatSetup(moment)
  const displayTime = () => {
    if (seconds) {
      const duration = moment.duration(seconds, 'seconds')
      return duration.format(seconds > 60 ? 'm:ss' : 's')
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds])
  return (
    <div
      style={{
        justifyContent: 'center',
        height: totalHeight,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      {label ? <p style={{ textAlign: 'center' }}>{label}</p> : null}
      <p style={{ textAlign: 'center' }}>{displayTime()}</p>
      <p
        style={{
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
        }}
      >
        <RingLoader color={primaryColor} size={loaderHeight} />
      </p>
    </div>
  )
}

import { handleErrors, handleErrorsWithCode } from './AssetController'

/**
 * 
 * @param {*} roomData 
 * @param {*} accessToken 
 * @returns If error {errorResponse,status}
 */
export const addFreeRoom = async (roomData, accessToken) => {
    console.log('addFreeRoom', roomData)
    const url = `${process.env.REACT_APP_CORE_SERVICE}stripe/addFreeRoom`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(roomData),
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((room) => {
            console.log('addFreeRoom received', room)
            return room
        })
}

/**
 * Get the Stripe {clientSecret} for the payment by userId on assetId for transactionType. The {paymentData} sent depends
 * on the TRANSACTION_TYPE. When it is room, multiple fields must be sent to create the Room Asset. Otherwise, just
 * the assetId and userId are required to update the database Transaction created by the server-side payment result.

 * @param {*} paymentData           
 * @param {*} transactionType 
 * @param {*} accessToken 
 * @returns 
 */
export const getPaymentIntent = async (paymentData, accessToken) => {
    const url = `${process.env.REACT_APP_CORE_SERVICE}stripe/getPaymentIntent`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((paymentIntent) => {
            console.log('getPaymentIntent received', paymentIntent)
            return paymentIntent
        })
}
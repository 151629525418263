import React from 'react'
import Footer from 'components/Footer/Footer.js'
import harmony from '../../images/harmony.jpg'

import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import AnnoyingConsent from 'components/Header/AnnoyingConsent'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { isVeryNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import { AddCircle, HighlightOffTwoTone } from '@material-ui/icons'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import BlurDialog from 'components/utility/BlurDialog.js'
import harmonySmall from '../../images/harmony-small.jpg'
import { useState } from 'react'
import SignInComponent from 'components/SignInComponent'
import HomeGrid from 'components/harmonize/HomeGrid'
import AIChat from 'vortex/AIChat'
import { setShowAi } from 'redux/actions/harmonizeActions'
import { setShowAiSignIn } from 'redux/actions/helpActions'

export default function HarmonizeHome() {

  const backgroundStyles = {
    normal: {
      backgroundSize: 'contain'
    },
    narrow: {
      backgroundSize: 'cover',
      height: '100%'
    }
  }
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const { showAiSignIn } = useSelector((state) => state.help)
  const accessToken = getStorageAccessToken()
  const account = getStorageAccount()
  const { showAi } = useSelector(state => state.harmonize)
  const { chatSessionId } = useSelector(state => state.chat)
  const [showSignIn, setShowSignIn] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const displayAddSong = () => {
    return (
      <div style={{
        display: 'flex', position: 'fixed', bottom: veryNarrow ? '8vh' : '6vh',
        right: veryNarrow ? '0.25em' : '0.5em'
      }}>

        <div
          title={`Click to add a song`}
          onClick={() => accessToken && account ? history.push(`/@${account.handle}/private/true`) : setShowSignIn(true)}
        >
          <AddCircle
            color='primary'
            style={{
              zIndex: 200,
              cursor: 'pointer',
              backgroundColor: 'rgba(255,255,255,0.5)',
              borderRadius: '4em',
              fontSize: '4em',
            }}
          />
        </div>
      </div>
    )
  }

  const displayHomeGridOrAi = () => {
    if (showAi) {
      return (

        <div style={{
          position: 'absolute', top: '5em', width: '100%', left: 0,
          backgroundColor: 'white', paddingLeft: '0.5em', paddingRight: '0.5em'
        }}>
          <AIChat category='Music' ownerOnly={true} chatHeight={veryNarrow ? '68vh' : '80vh'} useSessionId={chatSessionId} /> </div>
      )

    } else {
      return (
        <div style={{ position: 'absolute', top: '5em', backgroundColor: 'transparent', paddingBottom: '5vh' }}>
          <HomeGrid setShowSignIn={setShowSignIn} />
          {/*displayAddSong()*/}
        </div>
      )
    }

  }

  const signInContent = () => {
    return (
      <div style={{ fontSize: veryNarrow ? '.75em' : '1em', lineHeight: 'normal' }}>
        Create your free account or sign in to use Harmonize AI
        <ul style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
          <li>No subscription fees </li>
          <li>No ads</li>
        </ul>
        To learn more about our unique pricing <a href='/Pricing' alt='Pricing' target='_blank' rel='noreferrer'>click here</a>
      </div>
    )
  }

  const displayAiSignIn = () => {
    if (showAiSignIn) {
      return (<div onClick={() => dispatch(setShowAiSignIn(false))}>
        <BlurDialog
          content={() =>
            <SignInComponent returnPath={'/'} content={signInContent} success={() => {
              dispatch(setShowAiSignIn(false))
              dispatch(setShowAi(true))
            }} />
          }
          image={harmonySmall} hideHelp hideTitle close={() => dispatch(setShowAiSignIn(false))} closeIcon top={'10vh'} />
      </div>)
    }
  }

  const displaySignIn = () => {
    if (showSignIn) {
      return (<div onClick={() => setShowSignIn(false)}>
        <BlurDialog
          content={() => <SignInComponent returnPath={`/MyProfile`} caption={`Please sign in to add a song to Harmonize`} />}
          image={harmonySmall} hideHelp hideTitle close={() => history.replace('/')} closeIcon top={'10vh'} />
      </div>)
    }
  }

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME
  }, [])
  return (

    <div>
      <HarmonizeHeader />
      <div>
        {!showAi ? <div style={{
          ...backgroundStyles.normal, ...{
            backgroundImage: `url(${harmony})`,
            backgroundPosition: "center",
            backgroundRepeat: 'repeat',
            height: '350vh',
            opacity: '0.7'
          }
        }} /> : null}
        {displayHomeGridOrAi()}
        {displaySignIn()}
        {displayAiSignIn()}
        <AnnoyingConsent />
      </div>

      <Footer />
    </div>

  )
}

import * as REDUX_TYPES from '../types'
import { setCurrentArtist } from './artistActions'
import factory from '../../redux/store'
const { store } = factory()

/**
 * Always returns false.
 * 
 * See if an artist name is specified, in the form:
 *
 * [artist].localhost e.g. cjvilla.localhost (defaulting to truepicture)
 * [artist].[hostType].localhost e.g. cjvilla.truetrail.travel
 * [artist].[domain] e.g. cjvilla.truemusic.band
 *
 * setCurrentArtist is called to save either the artist name or null in artistReducer.
 *
 * @param {*} parts
 * @returns True if artist name specified
 */
export const getCurrentArtist = (parts) => {
  store.dispatch(setCurrentArtist(null))
  return false
  /*
  let foundArtist = false
  if (parts.length > 2 && parts[0] !== 'www') {
    store.dispatch(setCurrentArtist({ username: parts[0] }))
    foundArtist = true
  } else if (parts[parts.length - 1] === 'localhost') {
    if (
      !(
        parts.length === 1 ||
        (parts.length === 2 &&
          (parts[0] === 'truemusic' ||
            parts[0] === 'truetrail' ||
            parts[0] === 'trueplace' ||
            parts[0] === 'vortex'))
      )
    ) {
      store.dispatch(setCurrentArtist({ username: parts[0] }))
      foundArtist = true
    }
  } else {
    store.dispatch(setCurrentArtist(null))
  }
  return foundArtist
  */
}

export const getDisplayType = () => {
  const parts = window.location.hostname.toLowerCase().split('.')

  const local = parts[parts.length - 1] === 'localhost'
  return { harmonize: true, vortex: false, local }
  //return { vortex: true, local }

}

export const inVortexRoom = () => {
  const parts = window.location.pathname.toLowerCase().split('/')

  const inRoom = parts.length > 1 && parts[1] === 'room'
  //console.log(`inVortexRoom ${inRoom}`, parts)
  return inRoom

}

export const setFullscreenPlayer = (fullscreenPlayer) => {
  return {
    type: REDUX_TYPES.DISPLAY.FULLSCREEN_PLAYER,
    fullscreenPlayer,
  }
}

export const setShowHeader = (showHeader) => {
  return {
    type: REDUX_TYPES.DISPLAY.SHOW_HEADER,
    showHeader,
  }
}
export const setTransactionInProgress = (transactionInProgress) => {
  return {
    type: REDUX_TYPES.DISPLAY.TRANSACTION_IN_PROGRESS,
    transactionInProgress,
  }
}
export const setTrueMusic = (truemusic) => {
  return {
    type: REDUX_TYPES.DISPLAY.TRUEMUSIC,
    truemusic,
  }
}
export const setTruePlace = (trueplace) => {
  return {
    type: REDUX_TYPES.DISPLAY.TRUEPLACE,
    trueplace,
  }
}

export const setTrueTrail = (truetrail) => {
  return {
    type: REDUX_TYPES.DISPLAY.TRUETRAIL,
    truetrail,
  }
}

export const setVortex = (vortex) => {
  return {
    type: REDUX_TYPES.DISPLAY.VORTEX,
    vortex,
  }
}

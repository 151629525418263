import React from 'react'
import Footer from 'components/Footer/Footer.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { Container } from '@material-ui/core'
import { useEffect } from 'react'
import MessageJsx from 'components/harmonize/MessageJsx'

export default function About() {

  useEffect(() => {
    document.title = 'About Harmonize'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>
          <MessageJsx messageId='66faff8af81fa131e2b25639' />
        </Container>
      </div>
      <Footer />
    </div>

  )
}

import { handleErrors, handleErrorsWithCode } from './AssetController'

export const AI_TYPE = {
    CATEGORY: 1,
    STUDIO: 2,
    MESSAGE: 3
}

/**
 * Create an AI Session for the signed-in User. Either roomId or category must be supplied.
 * @param {*} aiIdentifier 
 * @param {*} aiType 
 * @param {*} accessType 
 * @param {*} accessToken 
 * @returns 
 */
export const addSession = async (aiIdentifier, aiType, accessType, accessToken) => {
    console.log(`addSession ${aiIdentifier} type ${aiType}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/addSession`
    return fetch(url, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ aiIdentifier, aiType, accessType }),
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((session) => {
            return session
        })
}


export const deleteSession = async (sessionId, accessToken) => {
    console.log(`deleteSession ${sessionId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/deleteSession/${sessionId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then(() => {
            return true
        })
}


/**
 * Flag the specified Thought as deleted.
 * @param {*} thoughtId 
 * @param {*} accessToken 
 * @returns 
 */
export const deleteSessionThought = async (thoughtId, accessToken) => {
    console.log(`deleteSessionThought ${thoughtId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/deleteSessionThought/${thoughtId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then(() => {
            return true
        })
}


/**
 * 
 * @param {*} sessionId 
 * @param {*} accessToken 
 * @returns 
 */
export const getSession = async (sessionId, accessToken) => {
    console.log(`getSession ${sessionId} `)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/getSession/${sessionId}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrors)
        .then((res) => res.json())
        .then((session) => {
            return session
        })
}


/**
 * 
 * @param {*} roomId 
 * @param {*} start 
 * @param {*} count 
 * @param {*} accessToken 
 * @returns 
 */
export const getSessionsForRoom = async (roomId, start, count, accessToken) => {
    console.log(`getSessions for ${roomId}`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/getSessionsForRoom/${roomId}/${start}/${count}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((sessions) => {
            return sessions
        })
}

/**
 * 
 * @param {*} start 
 * @param {*} count 
 * @param {*} accessToken 
 * @returns 
 */
export const getSessionsForUser = async (start, count, accessToken) => {
    console.log(`getSessionsForUser`)
    const url = `${process.env.REACT_APP_SOCIAL_SERVICE}harmonize/getSessionsForUser/${start}/${count}`
    return fetch(url, {
        method: 'GET',
        headers: {
            'x-access-token': accessToken
        },
    })
        .then(handleErrorsWithCode)
        .then((res) => res.json())
        .then((sessions) => {
            return sessions
        })
}
import React from 'react'

import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Footer from 'components/Footer/Footer.js'
import styles from 'styles/pricingPage'

import HarmonizeHeader from 'components/Header/HarmonizeHeader'

const useStyles = makeStyles(styles)

export default function Credits() {
  const brandName = process.env.REACT_APP_NAME
  const classes = useStyles()
  const narrow = useMediaQuery('(max-width:400px)')
  const containerClasses = narrow
    ? classNames(classes.main)
    : classNames(classes.main, classes.mainRaised)
  const vortexCredits = () => {
    return (
      <>
        <span>
          <a href="https://www.freepik.com/ai/image-generator" referrerPolicy='no-referrer' target="_blank">Harmony image created by Freepik AI</a>
          <br />
          <a href="https://www.flaticon.com/free-icons/vortex" referrerPolicy='no-referrer' target="_blank">Harmonize icon created by Freepik - Flaticon</a>
          <br />
          <a href="https://www.pexels.com/photo/man-listening-to-music-with-headphones-3756766/" referrerPolicy='no-referrer' target="_blank">Listener photo by Bryan Catota</a>
          <br />
          <a href="https://www.pexels.com/photo/funky-man-with-headphones-making-hearing-gesture-16247291/" referrerPolicy='no-referrer' target="_blank">Headphone boy photo by Jacob Sierra</a>
          <br />
          <a href="https://www.pexels.com/photo/pensive-woman-with-hot-beverage-at-home-6001459/" referrerPolicy='no-referrer' target="_blank">Never Forget image by Sam Lion</a>
        </span>


      </>
    )
  }


  return (
    <div>
      <HarmonizeHeader />

      <div className={containerClasses}>
        <div className={classes.container}>

          <h4>Credits</h4>
          <p>
            {brandName} would not be possible without the hard work and
            generosity of many people and organizations.
          </p>
          {vortexCredits()}

        </div>
      </div>
      <Footer />
    </div>
  )
}

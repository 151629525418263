import ErrorLine from 'components/ErrorLine'
import { useState } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { useDispatch } from 'react-redux'
import { setSongMediaIcons } from 'redux/actions/songActions'
import MediaIcon from './MediaIcon'
export default function MediaIcons({ mediaIcons, imageClicked }) {
    const [error, setError] = useState()

    const dispatch = useDispatch()

    const updateIcons = (ix) => {
        mediaIcons().splice(ix, 1)
        dispatch(setSongMediaIcons([...mediaIcons()]))
    }

    const displayIcons = () => {
        console.log('MediaIcons icons', mediaIcons())
        return mediaIcons().map((f, ix) => <MediaIcon icon={f} ix={ix} imageClicked={imageClicked} updateIcons={updateIcons} />)
    }

    if (mediaIcons().length) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ErrorLine error={error} />
                <ScrollMenu style={{ display: 'flex', alignItems: 'center' }}>{displayIcons()}</ScrollMenu>
            </div>
        )
    } else { return null }
}
import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { getStorage } from 'controllers/AccountController'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getStorageAccount } from 'redux/actions/accountActions'

export default function DisplayStorage() {
  const accessToken = getStorageAccessToken()
  const account = getStorageAccount()
  const { storage = 0, storageLimit, username } = account
  const [fileStorage, setStorage] = useState({ storage, storageLimit })

  const [remaining, setRemaining] = useState(storageLimit - storage)
  const [percentRemaining, setPercentRemaining] = useState(
    ((storageLimit - storage) / storageLimit) * 100
  )

  const getIt = async () => {
    const st = await getStorage(username, accessToken)
    console.log('Got storage', st)
    setStorage(st)
    const rem = st.storageLimit - st.storage
    setRemaining(rem)
    const percent = (rem / st.storageLimit) * 100
    setPercentRemaining(percent)
  }
  useEffect(() => {
    getIt()
  }, [])

  return (
    <div style={{ color: 'black' }}>
      <h3>Storage Remaining</h3>
      <p>
        {remaining.toFixed(2)} of {fileStorage.storageLimit} gigabytes
      </p>
      <CircularProgress
        size={'20vh'}
        value={percentRemaining}
        thickness={22}
        variant='determinate'
        color='primary'
      />
    </div>
  )
}

import { TextField, useMediaQuery } from '@material-ui/core'
import { Person, Send } from '@material-ui/icons'
import UserImage from 'components/image/UserImage'
import { getPublicUserById } from 'controllers/AccountController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getStorageAccount } from 'redux/actions/accountActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { isVeryNarrow } from 'util/screenUtils'

const aiChatStyles = {
  label: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontStyle: 'italic',
    fontSize: '0.75em',
  },
  answerLine: {
    borderBottom: '0.5px solid black',
  },
  queryLine: {
    borderBottom: '0.5px dashed gray',
    backgroundColor: 'azure',
  },

  queryInputLine: {

    backgroundColor: 'white',
    padding: '0.25em',
    border: '0.5px solid gray',
    display: 'flex',
    alignItems: 'center'
  },
  queryInputLineNarrow: {
    backgroundColor: 'white',
    border: '0.5px solid gray',
    display: 'flex',
    alignItems: 'center'
  },
  sendButton: { width: '90vw' },
  sendButtonNarrow: { width: '80vw' },
  bottom: { position: 'fixed', left: 0, bottom: '0', width: '100%' },
  top: { width: '100%' },
  topQueryInputLine: {
    width: '100%',
    backgroundColor: 'white',
    border: '0.5px solid gray',
    display: 'flex',
    justifyContent: 'center',
  },
}
export default function InputAI({ send, top = true, roomName, getQuery, updateQuery, sendCollaborator }) {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const accessToken = getStorageAccessToken()
  const account = getStorageAccount()
  const { id: userId, username } = account
  const { currentCollaborator } = useSelector(
    (state) => state.chat
  )
  const [collaboratorUser, setCollaboratorUser] = useState({ username: undefined })
  const history = useHistory()

  const getCollaborator = async () => {
    console.log(`getCollaborator ${currentCollaborator}`)
    if (currentCollaborator !== '0') {
      try {
        const publicUser = await getPublicUserById(currentCollaborator)
        setCollaboratorUser(publicUser)
      } catch (error) {
        console.error('Unable to get collaborator', error)
      }
    } else {
      console.log('set collaborator username to null')
      setCollaboratorUser({ username: null })
    }
  }

  /** If the current User is the controlling collaborator, display the account image. Clicking this then sets
   * the controlling collaborator to undefined so that any other user can take it.
   * 
   * Otherwise if there is a collaborator User that has been retrieved from the currentCollaborator id, display it.
   * This is not clickable.
   * 
   * Otherwise there is an undefined currentCollaborator meaning that clicking this will change the controlling
   * collaborator to the current User. This can be a race.
   */
  const displayCurrentCollaborator = () => {
    if (!top) {
      //console.log(`displayCurrentCollaborator user ${userId} ${currentCollaborator}`, collaboratorUser)
      if (currentCollaborator === '0') {
        return (
          <div style={{ cursor: 'pointer' }} title='Click to take control' onClick={(evt) => {
            evt.stopPropagation()
            updateCollaborator()
          }}>
            <Person style={{ width: 40 }} color='primary' size='large' />
          </div>
        )
      } else if (currentCollaborator === userId) {
        return (
          <div style={{ cursor: 'pointer' }} title='Click to pass control' onClick={(evt) => {
            evt.stopPropagation()
            updateCollaborator()
          }}>
            <UserImage image={account} style={{ width: 40 }} />
          </div>
        )
      } else if (collaboratorUser && collaboratorUser.username) {
        const { handle } = collaboratorUser
        return (
          <div style={{ cursor: 'pointer' }} title={`${handle} has control`} >
            <UserImage image={collaboratorUser} style={{ width: 40 }} />
          </div>
        )
      } else {
        return (
          <div style={{ cursor: 'pointer' }} title='Click to take control' onClick={(evt) => {
            evt.stopPropagation()
            updateCollaborator()
          }}>
            <Person style={{ width: 40 }} color='primary' size='large' />
          </div>
        )
      }
    }
  }

  const updateCollaborator = () => {

    const newCollaborator = currentCollaborator === userId ? '0' : userId
    sendCollaborator(newCollaborator)
  }

  const displayNoCollaborator = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '90%',
          alignItems: 'center'
        }}
      >
        <TextField
          value={getQuery()}
          onChange={(event) => updateQuery(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              send(getQuery())
            }
          }}
          onKeyDown={e => { e.nativeEvent.stopImmediatePropagation() }}
          multiline={true}
          autoFocus={!(top || veryNarrow)}
          fullWidth
          label={`Type a question for Harmonize AI`}
          inputProps={{
            maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH,
          }}
        />
        <Send
          onClick={(evt) => {
            evt.stopPropagation()
            accessToken ? send(getQuery()) : history.push(`/SignIn/${roomName}`)
          }
          }
        />
      </div>)
  }
  const displayControlled = () => {
    console.log(`displayControlled collaboratorUser ${username} userId ${userId}`, collaboratorUser)
    if (top || (currentCollaborator === userId) || (currentCollaborator === '0' && collaboratorUser.username === username)) {
      return displayNoCollaborator()

    } else if (collaboratorUser.username && collaboratorUser.username !== username) {
      const { handle } = collaboratorUser
      return (<p style={{ fontStyle: 'italic' }}>{handle} has control</p>)
    } else {
      return (
        <div style={{ cursor: 'pointer' }} onClick={(evt) => {
          evt.stopPropagation()
          updateCollaborator()
        }}>
          <p style={{ fontWeight: 'bold' }}>Click to take control of query</p></div>)
    }
  }
  const displayInput = () => {
    return (
      <div style={top ? aiChatStyles.top : aiChatStyles.bottom}>
        <div
          style={
            top
              ? aiChatStyles.topQueryInputLine
              : veryNarrow
                ? aiChatStyles.queryInputLineNarrow
                : aiChatStyles.queryInputLine
          }
        >

          {/*currentCollaborator ? displayCurrentCollaborator() : null */}
          {displayNoCollaborator()}

        </div>
      </div>
    )
  }

  return displayInput()
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import { ACCESS_TYPES } from './CreateVortexRoom'
import { AddAlert, Block, Edit, Lock, LockOpen, Public, Star } from '@material-ui/icons'
import { getStorageAccount } from 'redux/actions/accountActions'
import { setCurrentRoom } from 'redux/actions/messagesActions'
import { setCurrentAsset } from 'redux/actions/assetActions'
import { useDispatch } from 'react-redux'
import { setWaitingForHost } from 'redux/actions/chatActions'
import { setContinuousPlay } from 'redux/actions/harmonizeActions'

const iconStyle = { fontSize: '1.5em' }

export default function RoomLine({ asset, userHandle }) {
  const account = getStorageAccount()
  const { id: userId, defaultRoomName, handle } = account ? account : { userId: undefined, defaultRoomName: '', handle: userHandle }
  const history = useHistory()
  const dispatch = useDispatch()
  const displayAccessType = (accessType) => {

    switch (accessType) {
      default:
      case ACCESS_TYPES.PRIVATE:
        return <div title='Private'><Lock /></div>
      case ACCESS_TYPES.PROTECTED:
        return <div title='Protected'><LockOpen /></div>
      case ACCESS_TYPES.PUBLIC:
        return <div title='Public'><Public /></div>
    }

  }

  const displayDefault = (name) => {
    if (name) {
      if (name === defaultRoomName) {
        return (
          <div title='Default room' >
            <Star />
          </div>
        )
      }
    }
  }


  const displayExclusive = (exclusive) => {
    if (exclusive) {
      return (
        <div title='Exclusive content' >
          <Block />
        </div>
      )
    }
  }

  const displayOwnerActions = () => {
    const { originalTitle, room } = asset
    const { user, accessType } = room
    if (user === userId) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ cursor: 'pointer' }} title={`Edit ${originalTitle}`} >
            <Edit color='primary'
              style={iconStyle}
              onClick={(evt) => {
                evt.stopPropagation()
                gotoEditRoom(asset)
              }} />
          </div>
          {accessType !== ACCESS_TYPES.PRIVATE ?
            <div style={{ cursor: 'pointer' }} title={`Enable notification ${originalTitle}`} >
              <AddAlert color='primary'
                style={iconStyle}
                onClick={(evt) => {
                  evt.stopPropagation()
                  gotoEditRoom(asset)
                }} /></div> : null}
        </div>
      )
    }
  }
  const displayRoom = () => {
    const { _id, originalTitle, caption, room } = asset
    const { accessType, name } = room
    return (
      <div>
        <div
          key={_id}
          className='row' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%', justifyContent: 'space-between' }}
          title={`Click to enter ${originalTitle}`}
          onClick={() => {
            dispatch(setWaitingForHost(false))
            dispatch(setContinuousPlay(false))
            history.push(`/@${handle}/${name}`)
          }}
        >
          <div
            className='column'
            style={{
              marginLeft: 5,
              verticalAlign: 'top',
              flex: '80%',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>{originalTitle}</p>
            <p>{caption}</p>
          </div>
          {displayOwnerActions()}

          {displayAccessType(accessType)}
          {/*
          {displayExclusive(exclusive)}
          {displayDefault(name)}
          */}
        </div>
      </div>
    )
  }


  const gotoEditRoom = (asset) => {
    console.log('gotoEditRoom', asset)
    const txRoom = `/EditRoom`
    const { room } = asset
    dispatch(setCurrentRoom(room))
    dispatch(setCurrentAsset(asset))
    history.push(txRoom)

  }

  return displayRoom()
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import Button from 'components/CustomButtons/Button.js'
import parse from 'html-react-parser'

import modalStyle from '../assets/jss/material-kit-react/modalStyle.js'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function ModalDialog({
  title,
  message,
  open,
  close,
  yesLabel = 'OK',
  noLabel = 'Cancel',
  yesFocus = true, yes,
  no,
  content
}) {
  const useStyles = makeStyles(modalStyle)
  const classes = useStyles()
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        {title ? <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={close}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>{title}</h4>
        </DialogTitle> : null}
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {content ? parse(content()) :
            <h5>{message}</h5>}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + ' ' + classes.modalFooterCenter}
        >
          {noLabel.length ? <Button autoFocus={!yesFocus} onClick={no}>
            {noLabel}
          </Button> : null}
          <Button autoFocus={yesFocus} onClick={yes} color="success">
            {yesLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

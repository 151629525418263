import { Add } from '@material-ui/icons'
import BlurDialog from 'components/utility/BlurDialog'
import { addToCollection } from 'controllers/HarmonizeController'
import { getCollections } from 'controllers/HarmonizeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getStorageAccessToken } from 'redux/actions/accountActions'

export default function Collections({ userId, messageId, close }) {
    const [collections, setCollections] = useState([])
    const history = useHistory()
    const accessToken = getStorageAccessToken()

    const listCollections = () => {
        return (
            <div>
                <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} title='Click to create a collection'
                    onClick={(evt) => {
                        evt.stopPropagation()
                        close()
                        history.push(`/CreateCollection/${messageId}`)
                    }}
                ><Add />Create Collection</div>
                <hr />
                <div style={{ fontStyle: 'italic' }}>Click name to add to collection</div>
                {collections.map(c =>
                    <div key={c.name} style={{ cursor: 'pointer' }} onClick={async () => {
                        await addToCollection(c._id, messageId, accessToken)
                    }}>{c.name}</div>
                )}
            </div>
        )
    }

    const displayCollections = () => {

        return (<div onClick={(evt) => {
            evt.stopPropagation()
            close()
        }}>
            <BlurDialog content={listCollections} hideHelp title='Playlists' closeIcon animationTime={200} />
        </div>)
    }

    const fetch = async () => {
        try {
            const coll = await getCollections(userId)
            setCollections(coll)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    return displayCollections()
}

import React from 'react'
import Footer from 'components/Footer/Footer.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import RoomList from 'components/vortex/RoomList'
import { getStorageAccount } from 'redux/actions/accountActions'

export default function Studios() {
  const account = getStorageAccount()
  const { handle, username } = account

  const displayStudios = () => {
    return (
      <div>
        <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>Studios</h3>
        <div style={{
          border: '1px solid black', paddingLeft: '1em',
          paddingRight: '1em'
        }}>
          <RoomList ownerUsername={username} handle={handle} isOwner={true} category='Music' />
        </div>
      </div>
    )

  }

  return (
    <div>
      <HarmonizeHeader />
      <div style={{ margin: '1em' }}>
        {displayStudios()}
      </div>
      <Footer />
    </div>
  )
}

import { INITIAL_STATE } from 'redux/reducers/assetReducer'
import * as REDUX_TYPES from '../types'

export const getCurrentAsset = () => {
  const { currentAsset } = getStorageAsset()
  return currentAsset
}

/**
 * Get the localStorage asset
 * @returns The asset or INITIAL_STATE if it does not exist
 */
export const getStorageAsset = () => {
  const asset = localStorage.getItem(REDUX_TYPES.ASSET.SET)
  if (asset) {
    return JSON.parse(asset)
  }
  return INITIAL_STATE
}

/**
 * Get the localStorage asset and return it as a {type,asset} dispatch action
 * @returns
 */
export const reloadReduxAsset = () => {
  const asset = getStorageAsset()
  return {
    type: REDUX_TYPES.ASSET.SET,
    asset,
  }
}

const storeAsset = (asset) => {
  localStorage.setItem(REDUX_TYPES.ASSET.SET, JSON.stringify(asset))
}
export const setCurrentAlbum = (currentAlbum) => {
  storeAsset({ ...getStorageAsset(), currentAlbum })
  return {
    type: REDUX_TYPES.ASSET.CURRENT_ALBUM,
    currentAlbum,
  }
}
export const setCurrentAsset = (currentAsset) => {
  storeAsset({ ...getStorageAsset(), currentAsset })
  return {
    type: REDUX_TYPES.ASSET.CURRENT_ASSET,
    currentAsset,
  }
}

export const setCurrentAssets = (currentAssets) => {
  storeAsset({ ...getStorageAsset(), currentAssets })
  return {
    type: REDUX_TYPES.ASSET.CURRENT_ASSETS,
    currentAssets,
  }
}

export const setCurrentIssue = (currentIssue) => {
  storeAsset({ ...getStorageAsset(), currentIssue })
  return {
    type: REDUX_TYPES.ASSET.CURRENT_ISSUE,
    currentIssue,
  }
}


import React, { useState } from 'react'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'
import parse from 'html-react-parser'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles.js'

import { cardTitle } from 'assets/jss/material-kit-react.js'
import { isNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import { isVeryNarrow } from 'util/screenUtils'
import { useEffect } from 'react'
import { getMessage } from 'controllers/VortexController'
import { replaceNewLine } from 'util/postUtils'
import { displayError } from 'util/screenUtils'
import ErrorLine from './ErrorLine'

const styles = {
  ...imagesStyles,
  cardTitle,
  card: {
    borderRadius: '1em',
    backgroundColor: 'rgba(255,255,255,0.9)',
    boxShadow: '1px 3px 1px #9E9E9E',
    display: 'flex',
    justifyContent: 'center',

  },
  hovered: {
    margin: 'auto',
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  notHovered: {
    margin: 'auto',
  },
}

export default function HomeCard({ title, description, summary, action, icon, link, content, image, messageId,
  width = '90vw' }) {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [error, setError] = useState()
  const [requestedMessage, setRequestedMessage] = useState()
  const [hovered, setHovered] = useState(false)
  const history = useHistory()

  /** If the outer div has display:flex, then content will be split into columns and it does not wrap around
   * the image.
   */
  const displayWithImage = (cardDescription, cardSummary, cardTitle) => {
    return (
      <div style={{ lineHeight: '1.5em', alignItems: 'center', textAlign: 'left' }}>
        <img src={image} alt={cardTitle} style={{ width: veryNarrow ? '25%' : '15%', float: 'left', marginRight: '2em' }} />
        {cardDescription}{cardSummary}
        <p style={{ textAlign: 'right', fontStyle: 'italic' }}>
          Click here for more...
        </p>
      </div>

    )
  }

  const displayTextOnly = (cardDescription, cardSummary) => {
    return (
      <div style={{ textAlign: 'left' }}>
        {cardDescription} {cardSummary}
      </div>
    )
  }
  const displayRequestedMessage = () => {
    const { name, message, summary } = requestedMessage
    const jsx = parse(replaceNewLine(message))
    const jsxSummary = parse(replaceNewLine(summary))
    return (
      <div>
        <p style={{ fontWeight: 'bold', fontSize: veryNarrow ? '1em' : '1.5em', borderBottom: '1px solid gray' }}>{name}</p>
        {image ? displayWithImage(jsx, jsxSummary, name)
          : displayTextOnly(jsx, jsxSummary)}
      </div>
    )
  }
  const displayTextCard = () => {
    return (
      <Card
        className='plain'
        title={title}
        style={{ ...styles.card, width }}

      >
        <ErrorLine error={error} />
        {icon ? <div style={{ flex: '25%' }}>{icon}</div> : null}

        <CardBody
          style={{

            textAlign: 'center',

            alignItems: "center",
            justifyContent: 'center',
            padding: '10px'
          }}
        >
          {content ? content() :
            requestedMessage ? displayRequestedMessage() :
              <div>
                <p style={{ fontWeight: 'bold', fontSize: veryNarrow ? '1em' : '1.5em', borderBottom: '1px solid white' }}>{title}</p>
                {
                  image ? displayWithImage(description, summary, title)
                    : displayTextOnly(description, summary)}
              </div>
          }
        </CardBody>
      </Card>

    )
  }

  useEffect(() => {
    const getRequestedMessage = async (mid) => {
      try {
        const msg = await getMessage(mid)
        setRequestedMessage(msg)
      } catch (error) {
        displayError(error, setError)
      }
    }
    if (messageId) {
      getRequestedMessage(messageId)
    }
  }, [])
  if (action) {
    return (<div style={{ cursor: 'pointer' }} onClick={action}>
      {displayTextCard()}
    </div>)
  } else {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => {
        history.push(link)
        ReactGA.event({ category: 'click', action: 'home_card', label: title })
      }}>
        {displayTextCard()}
      </div>)

  }
}

import { getProfileImage, getProfileImageById } from 'controllers/AccountController'
import React, { useEffect, useState } from 'react'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
import UserImage from 'components/image/UserImage'
export default function SmallProfileImage({ username, userId }) {
  const [image, setImage] = useState(null)

  const displayImage = () => {
    if (image) {
      const { handle } = image
      return (
        <UserImage
          title={username ? username : handle}
          image={image}
          style={imagesStyles.bidProfileImageContainer}
        />
      )
    }
    return null
  }
  useEffect(() => {
    (userId ? getProfileImageById(userId) : getProfileImage(username)).then((img) => { setImage(img) }).catch((error) =>
      console.error(`Error getting profile image for ${username ? username : userId}`, error)
    )
  }, [])
  return displayImage()
}
